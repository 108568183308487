import React, { useState, useEffect } from 'react';
import axios from './api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from './hooks/useAuth';

const LOGOUT_URL = '/Auth/Logout';

const IdleTimeoutLogout = ({ setMinifiedNav, idleTimeoutInSeconds, children }) => {

  const [idleTime, setIdleTime] = useState(0);

  const { auth } = useAuth();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";

  const [errMsg, setErrMsg] = useState('');

  // Function to reset idle time
  const resetIdleTime = () => {
    setIdleTime(0);
  };

  const LogoutFunction = async () => {
 
    try { 
        const response = await axios.post(LOGOUT_URL, 
            JSON.stringify({'token': auth?.accessToken, 'refreshToken': auth?.refreshToken}), 
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                 },
            }
        );
            localStorage.removeItem('auth');
            setAuth( {} );
            //setTimeout(function(){
                navigate(from, { replace: true });
            //}, 500);
            setMinifiedNav('');
            return response;
        } catch(err) {

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg('400');
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else {
                setErrMsg('Logout failed');
            }

            console.log(errMsg);

        }

    }


  useEffect(() => {

    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        // Perform logout action here
        if(location.pathname !== '/aedu') {
            console.log("User has been idle for too long. Logging out...");
            LogoutFunction();
        }
        // Implement logout logic here, for example, redirect to login page
      }, idleTimeoutInSeconds * 1000);
    };

    // Reset timer on user activity
    const onActivity = () => {
        resetIdleTime();
        resetTimer();
        
    };

    // Set up event listeners for user activity
    const setUpListeners = () => {
        ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach(event => {
            window.addEventListener(event, onActivity);
        });
    };

    setUpListeners();

    // Start timer for idle timeout
    resetTimer();

    // Axios interceptor to reset timer on HTTP requests
    const axiosInterceptor = axios.interceptors.request.use(config => {
      resetTimer();
      return config;
    });

    // Clean up
    return () => {
      clearTimeout(timer);
      axios.interceptors.request.eject(axiosInterceptor);
      ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach(event => {
        window.removeEventListener(event, onActivity);
      });
    };
  }, [idleTimeoutInSeconds]);
  

  return <>{children}</>;
};

export default IdleTimeoutLogout;