
//import { useRef, useState, useEffect, useContext } from 'react';

const Missing = () => {

    return (
        <section className="peili-content-wrapper">
            <h1>Missing</h1>
        </section>
    )
}

export default Missing