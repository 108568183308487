

const Unauthorized = () => {

    return (
        <section className="peili-content-wrapper">
            <h1>Unauthorized, you have no access to this page</h1>
        </section>
    )
}

export default Unauthorized