import React, { useState, useRef, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const ZipUploader = (props) => {

    const EDIT_ORGANISATION_URL = `/Organisations/${props.currentOrganisation.id}`;

    const [selectedZip, setSelectedZip] = useState(null);
    const [isDragging, setIsDragging] = useState('');
    const axiosPrivate = useAxiosPrivate();
    const [ ajaxLoad, setAjaxLoad ] = useState('');

    const nameRef = useRef();
    const errRef = useRef(); 

    const [name, setName] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        setName(props.currentOrganisation.name);
    }, [props.currentOrganisation]); 

    useEffect(() => {
        setErrMsg('');
    }, [name]);

    const handleFileSelect = (event) => {
  
        const file = event.target.files[0];

        if (file && file.type === "application/zip") {
            setSelectedZip(file);
        } else {
            setSelectedZip(null);
            alert("Please select a valid ZIP file.");
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging('dragging');
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setIsDragging('');
    };

    const handleDrop = (event) => {

        event.preventDefault();
        
        setIsDragging(''); 

        const file = event.dataTransfer.files[0];

        if (file && file.type === "application/zip") {
            setSelectedZip(file);
        } else {
            setSelectedZip(null);
            alert("Please select a valid ZIP file.");
        }
    };

    const handleUpload = async () => {
        if (!selectedZip) {
            // Don't do anything if no file is selected
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedZip);

        try {
            const response = await axiosPrivate.post("https://example.com/api/upload", formData);
            // Handle success response from server
            alert("File uploaded successfully!");
        } catch (error) {
            // Handle error
            alert("Error uploading file. Please try again later.");
        }
    };

    function validateField(e){ 
        if(e.value.length === 0) {
            e.classList.add('invalitaded');
            return false;
        } else {
            e.classList.remove('invalitaded');
            return true;
        }
    }

    const saveOrganisation = async () => {

        if(!validateField(nameRef.current)){
            setErrMsg("Name can not be left empty");
            return false;
        };

        setAjaxLoad('loading');

        let organisationsArray = [];

        props.organisations.map((org) => {
            organisationsArray.push(org.name.toLowerCase());
        });

        const excludedWord = [props.currentOrganisation.name];

        if(organisationsArray.includes(name.toLowerCase()) && !excludedWord.includes(name)){
            setErrMsg('Duplicate name, organisation already exists');
            errRef.current.focus();
            setAjaxLoad('');
            return false;
        } else {
            setErrMsg('');
            errRef.current.focus();
        }

        try { 
            const response = await axiosPrivate.put(EDIT_ORGANISATION_URL, 
                JSON.stringify({'name': name})
            );

            setName('');
            setErrMsg('');
            props.setInitState(true);
            setTimeout(() => {
                setAjaxLoad('');
                setErrMsg('');
                props.goBack();
            }, 500);

        } catch(err) {

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg(err.response?.data);
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else if (err.response?.status === 500) {
                setErrMsg('500 error');
            } else {
                setErrMsg('Request failed');
            }

            setAjaxLoad('');

        }

    }

    const deleteOrganisation = async () => {

        var answer = window.confirm("Are you sure you want to delete this organisation?");

        if (!answer) {
            return false;
        } 

        setAjaxLoad('loading');

        try {
            const response = await axiosPrivate.delete(EDIT_ORGANISATION_URL, {
                //signal: controller.signal
            });
            //console.log(response.data);
            //console.log('organisation ' + props.currentOrganisation.name + ' deleted');

            props.setInitState(true);
            setTimeout(() => {
                setAjaxLoad('');
                props.goBack();
            }, 500);

        } catch(err){

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg(err.response?.data);
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else if (err.response?.status === 500) {
                setErrMsg('Organisation linked to user, cant delete.');
            } else {
                setErrMsg('Request failed');
            }

            setAjaxLoad('');
        }
    }

    const handleClear = () => {
        setSelectedZip(null);
    }; 
    
 
    return (
        <div className={"peili-zip-container " + ajaxLoad}>
            <div className="input-fields">
                <div className='item full-width'>
                    <label htmlFor="name">Name</label>
                    <input 
                        type="text" 
                        id="name" 
                        ref={nameRef} 
                        autoComplete="off"  
                        onChange={(e) => { setName(e.target.value); validateField(e.target)}}
                        value={name}
                    />
                </div>
            </div>

            <div className={"container " + isDragging}> 

                <label htmlFor="zip-input">{selectedZip ? selectedZip.name : 'Select or drag and drop a ZIP file:'}</label>

                <input
                    type="file"
                    id="zip-input"
                    accept=".zip"
                    onChange={handleFileSelect}
                    onDragLeave={handleDragLeave} 
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                />
            </div>
            {selectedZip && ( 
                <div className="buttons-wrapper">
                    { /* <p>Selected file: {selectedZip.name}</p> */ } 
                    <button onClick={handleUpload}>Upload</button>
                    {selectedZip && <button className="secondary" onClick={handleClear}>Clear</button>}
                </div>
            )}
            <div className='btns-wrapper'>
                <button className='button save-class' onClick={saveOrganisation}>SAVE</button> 
                <button type="button" className="delete-btn" onClick={() => {deleteOrganisation()}}>DELETE ORGANISATION</button>
            </div>
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        </div>
        ); 
    };

export default ZipUploader;
