import { Navigate } from 'react-router-dom';
import useAuth from './hooks/useAuth';
import Login from './Login';
import { useEffect, useState } from 'react';

export const LoginOrRedirect = (props) => {
  const { auth } = useAuth();

  if (auth.accessToken && auth.refreshToken) {
    return <Navigate to="/" />;
  }
  return <Login setMinifiedNav={props.setMinifiedNav} />;

};


export default LoginOrRedirect