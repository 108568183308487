
import { useRef, useState, useEffect } from 'react';
import useAuth from './hooks/useAuth';
import axios from './api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LOGIN_URL = '/Auth/Login';
const LOGOUT_URL = '/Auth/Logout';

const Login = (props) => {

    const { t } = useTranslation();

    const { auth } = useAuth();
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [init, setInit] = useState(false);
    
    const userRef = useRef();
    const pwdRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () =>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

    useEffect(() => {
        props.setMinifiedNav('');
        userRef.current.focus();
    }, []);

    useEffect(() => {
        localStorage.removeItem('auth');
        LogoutFunction();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);

    function validateField(e){
        if(e.value.length === 0) {
            e.classList.add('invalitaded');
        } else {
            e.classList.remove('invalitaded');
        }
    }

    const LogoutFunction = async () => {
 
        try { 
            const response = await axios.post(LOGOUT_URL, 
                JSON.stringify({'token': auth?.accessToken, 'refreshToken': auth?.refreshToken}), 
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                     },
                }
            );
            localStorage.removeItem('auth');
            setAuth( {} );
            
            //navigate(from, { replace: true });
            return response;
        } catch(err) {

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg('400');
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else {
                setErrMsg('Logout failed');
            }

            console.log(errMsg);

        }

    }
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        validateField(userRef.current);
        validateField(pwdRef.current);
        //console.log(  JSON.stringify({'username': user, 'password': pwd}));
        try { 
            const response = await axios.post(LOGIN_URL, 
                JSON.stringify({'username': user, 'password': pwd}), 
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                     },
                }
            );
            //console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            const id = response?.data?.therapistId;
            const accessToken = response?.data?.token;
            const refreshToken = response?.data?.refreshToken; 
            const roles = response?.data?.additionalRoles;
            const language = response?.data?.languageCode;
            const organisation = response?.data?.organisation?.name;
            const organisationId = response?.data?.organisation?.id;

            // Save user's data in local storage
            localStorage.setItem('auth', JSON.stringify({ id, user, pwd, roles, accessToken, refreshToken, language, organisation, organisationId }));

            setAuth( { id, user, pwd, roles, accessToken, refreshToken, language, organisation, organisationId } );
            setUser(''); 
            setPwd('');  
            props.setMinifiedNav('minified');
            navigate(from, { replace: true }); 
        } catch(err) {

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg('400 Missing username or password');
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else {
                setErrMsg('Login failed');
            }

            errRef.current.focus();
        }

    }

    return (
        <div className="peili-content-wrapper login-wrapper">
            <div className='headings'>
                
                <h1>{t('KIRJAUDU SISÄÄN')}</h1>
            </div>
 
            <form className="peili-login-form" onSubmit={handleSubmit} autoComplete="off">
                <div className='item'>
                    <label htmlFor="username">{t('Käyttäjä')}</label>
                    <input 
                        type="text" 
                        id="username"  
                        ref={userRef} 
                        autoComplete="off" 
                        onChange={(e) => { setUser (e.target.value) ; validateField(e.target)}}
                        value={user}
                        //required
                        //placeholder='User'
                    />
                </div>
                <div className='item'>
                    <label htmlFor="password">{t('Salasana')}</label>
                    <input 
                        type={passwordType}
                        id="password" 
                        ref={pwdRef}
                        onChange={(e) => { setPwd(e.target.value); validateField(e.target)}}
                        value={pwd} 
                        //required 
                        //placeholder='password'
                    />
                    <div className="show-password" onClick={togglePassword}><span className={passwordType === 'password' ? 'icon' : 'icon active' }></span></div>
                </div>
                <button>{t('KIRJAUDU')}</button> 
            </form> 

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        </div>
    )
}

export default Login