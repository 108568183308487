
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import AddStudentPopup from '../grades/AddStudentPopup';
import EditStudentPopup from '../grades/EditStudentPopup';
import { MessageContext } from '../../context/MessageContext';

const AeduStudentsTable = (props) => {

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const { t, i18n } = useTranslation();
    const [ currentLanguage, setCurrentLanguage ] = useState(i18n.language);

    const [studentList, setStudentList] = useState([]);

    const errRef = useRef();
    const [rows, setRows] = useState([]); 

    const [ popup, setPopup ] = useState('');
    const [init, setInit] = useState(false);

    const [currentStudent, setCurrentStudent] = useState({});

    const [errMsg, setErrMsg] = useState('');

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    useEffect(() => { 
        setErrMsg('');
        getGrades();
    }, [props.currentGrade]);

    useEffect(() => { 
       
        props.setUpdateStudentsTable(false);
        //setRows(rows);
        //getGrades();
    }, [props.updateStudentsTable]);

    useEffect(() => { 
        if(props.continueSession === true){
            const prevStudents = JSON.parse(localStorage.getItem('students'));
            setRows(prevStudents);
            props.getStudents(prevStudents);
            //props.setContinueSession(false);

        }
    }, [props.continueSession]);

    useEffect(() => {
        setRows([]);
        //remove all items in player grid dom
        const items = document.querySelectorAll('.cloned');
        //console.log(items);
        if(items.length > 0){
            items.forEach((item) => {
                if (item.parentNode) {
                    item.remove();
                }
            });
        }

        getGrades();
    }, [props.removeAllRows]); 

    useEffect(() => { 
        setErrMsg('');
        setInit(false);
        getGrades();
        
    }, [init]);

    const getGrades = async () => {
        setAjaxLoad('loading');
        try {
            //Change to /Therapists/auth.id if PROBLEMS
            const response = await axiosPrivate.get('/Organisations/' + auth.organisationId + '/Patients',  {
                //signal: controller.signal
            });
            //console.log(response.data); 

            let studentsArray = [];
            response.data.forEach((row) => {
                let rowData = JSON.parse(row.patientReadOnlyData) !== null ? JSON.parse(row.patientReadOnlyData) : [];
               
                for(let i = 0; i < props.currentGrade.length; i++ ){
                    if ( rowData?.class === props.currentGrade[i]?.name ) {
                        studentsArray.push( {'realName': rowData?.realName, 'class': rowData?.class, 'age': rowData?.age, 'gender': rowData?.gender, 'handedness': rowData?.handedness, 'language': rowData?.language, 'isS2Student': rowData?.isS2Student, 'id': row.id, 'createdDate': row.createdDate, 'newlyCreated': rowData?.newlyCreated });
                    }
                }
            });
            
            //studentsArray.sort((a, b) => a.realName.localeCompare(b.realName));
            //console.log(studentsArray);
            studentsArray.sort((a, b) => {
                // If a is newly created, it should come after b
                if (a.newlyCreated) {
                    return 1;
                }
            
                // If b is newly created, it should come after a
                if (b.newlyCreated) {
                    return -1;
                }
            
                // If neither a nor b is newly created, sort them alphabetically by realName
                return a.realName.localeCompare(b.realName);
            });
            //studentsArray.sort((a, b) => a.realName.localeCompare(b.realName));

            if(studentsArray.length > 0) {
                localStorage.setItem('students', JSON.stringify(studentsArray));
            }

            setRows(studentsArray);
            
            props.getStudents(studentsArray);
            
            setAjaxLoad('');
            //isMounted && 
            //setGrades(classesArray);
            //setFilteredData(classesArray);

        } catch(err){
            setAjaxLoad('');
            //console.error(err);
        }
    }

    const openAddStudentForm = () => {
        setPopup('add-student-form'); 
    }

    const openEditStudentForm = (student) => {
        setCurrentStudent(student);
        setPopup('edit-student-form');
    }

    // Group rows by class
    const groupedRows = rows.reduce((groups, row) => {
        const key = row.class;
        if (!groups[key]) {
        groups[key] = [];
        }
        groups[key].push(row);
        
        return groups;
    }, {});

    function sortDivItemsAlphabetically(divClassName) {
        // Get the div
        const div = document.querySelector(`.${divClassName}`);
    
        // Get the items in the div
        const items = Array.from(div.children);
    
        // Sort the items alphabetically by their data-name attribute
        const sortedItems = items.sort((a, b) => a.dataset.name.localeCompare(b.dataset.name));
    
        // Remove all items from the div
        while (div.firstChild) {
            div.firstChild.remove();
        }
    
        // Append the sorted items to the div
        sortedItems.forEach(item => div.appendChild(item));
    }

    return (
        <>
        {
            ( popup === 'add-student-form' ) ?
            <AddStudentPopup currentGrade={props.currentGrade} rows={rows} generatedId={props.generatedId} setPopupState={setPopup} setInitState={setInit}></AddStudentPopup>
            : ( popup === 'edit-student-form' ) ?
            <EditStudentPopup currentStudent={currentStudent} setPopupState={setPopup} setInitState={setInit}></EditStudentPopup>
            :
            <></>
        }
        <section className={"aedu-players-container " + ajaxLoad}>
            <p className='heading'>{t('Luokan oppilaat')} ({rows.length})</p>
            <div className="players-grid">
                {Object.entries(groupedRows).map(([className, students]) => (
                <div className="players-grid-by-classname" key={className}>
                    <h2>{className}</h2>
                    {students.map((student, index) => (
                    <div
                        data-id={student.id}
                        data-received="true"
                        data-name={student.realName}
                        data-class={student.class}
                        className="item student"
                        key={"player-" + index}
                        onClick={() => openEditStudentForm(student)}
                    >
                        <div className="img-wrapper">
                        <span className="peili-icon person"></span>
                        </div>
                        <p>{student.realName}</p>
                        <p className="statusmessage"></p>
                    </div>
                    ))}
                </div>
                ))}

            </div>
            <div className="bottom-content">
                <button onClick={() => props.startTest(rows)} className={`peili-btn green choose-classes active`}>{t('ALOITA TESTI')}</button>
                <div onClick={() => openAddStudentForm()} id="add-new-student" className="peili-btn secondary purple item add-new-student" key={"add-new-player-00"}>

                    <p>{t('LISÄÄ UUSI OPPILAS')}</p>
                </div>
            </div>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
        </>
    )
}

export default AeduStudentsTable