import React, {  } from 'react';

//MODELS

export const createStudentViewModel = (id, className, name, hasAlreadyPlayed, isActive, createdDate, languageCode) => {
    return {
        id: id,
        className: className,
        name: name,
        hasAlreadyPlayed: hasAlreadyPlayed,
        isActive: isActive,
        createdDate: createdDate,
        languageCode: languageCode
    };
    
};

export const createSchoolTestSessionClientData = (
    clientId, isConnectedToObserver, devicePairId, studentsByClass, 
    selectedStudent, exerciseSessionData, lastPingTime, status, 
    statusMessage, progress, waitingForTestStart, numPendingSessionUploadsInLocalStorage, 
    savingLocalSessions, hasCompletedTest
) => {
    return {
        clientId: clientId,
        isConnectedToObserver: isConnectedToObserver,
        devicePairId: devicePairId,
        studentsByClass: studentsByClass,
        selectedStudent: selectedStudent,
        exerciseSessionData: exerciseSessionData,
        lastPingTime: lastPingTime,
        status: status,
        statusMessage: statusMessage,
        progress: progress,
        waitingForTestStart: waitingForTestStart,
        numPendingSessionUploadsInLocalStorage: numPendingSessionUploadsInLocalStorage,
        savingLocalSessions: savingLocalSessions,
        hasCompletedTest: hasCompletedTest
    };
};

//FUNCTIONS FOR EVENTS

export const studentListRequestEvent = (className, studentList, guid) => {

    return {
        "Response": {
            "Students": studentList
        },
        "IsResponse": 'true',
        "ResponseToGuid": guid,
        "Guid": generateGuid(),
        "EventTypeName": "Peili.Events.RequestEvent`1[[Peili.Aedu.StudentListResponseEvent, Assembly-CSharp, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null]]",
        "FromPatient": 'false',
        "RepeatUntilConfirmation":'false',
        "TimeStamp": generateTimestamp(),
        "Group": "AEduWebTestSession"
    };
}

export const patientCredentialsResponseEvent = (username, pass, guid ) => {
    return {
        "Response": {
            "Username":username,
            "Password":pass,
            "Guid": generateGuid(),
            "EventTypeName":"Peili.Aedu.PatientCredentialsResponseEvent",
            "FromPatient":false,
            "RepeatUntilConfirmation":false,
            "TimeStamp":generateTimestamp(),
            "Group": null 
        },
        "IsResponse": true,
        "ResponseToGuid": guid,
        "Guid": generateGuid(),
        "EventTypeName": "Peili.Events.RequestEvent`1[[Peili.Aedu.PatientCredentialsResponseEvent, Assembly-CSharp, Version=0.0.0.0, Culture=neutral, PublicKeyToken=null]]",
        "FromPatient": false,
        "RepeatUntilConfirmation": true,
        "TimeStamp": generateTimestamp(),
        "Group": "AEduWebTestSession"
    }
}

export const confirmableEventReceived = (guid) => {  
    return {
        "Guid": guid,
        "EventTypeName":"Peili.Events.ConfirmableEventReceived",
        "FromPatient":false,
        "RepeatUntilConfirmation":false,
        "TimeStamp": generateTimestamp(),
        "Group": null
    }
}

export const startTestSessionEvent = () => {
    return {
        "Guid": generateGuid(),
        "EventTypeName":"Peili.Aedu.StartTestSessionEvent",
        "FromPatient":false,
        "RepeatUntilConfirmation":true,
        "TimeStamp": generateTimestamp(),
        "Group":"AEduWebTestSession"
    }
}

export const sendStudentListUpdate = (studentList) => {
    return {
        "Students": studentList,
        "Guid": generateGuid(),
        "EventTypeName":"Peili.Aedu.StudentListUpdate",
        "FromPatient":false,
        "RepeatUntilConfirmation":false,
        "TimeStamp": generateTimestamp(),
        "Group":"AEduWebTestSession"
        
    }
}

export const finishTestSessionEvent = () => {
    return {
        "Guid":generateGuid(),
        "EventTypeName":"Peili.Aedu.FinishTestSessionEvent",
        "FromPatient":false,
        "RepeatUntilConfirmation":true,
        "TimeStamp":generateTimestamp(),
        "Group":"AEduWebTestSession"
    }
}

export const cancelTestSessionEvent = () => {
    //console.log('cancelTestSessionEvent');
    return {
        "Guid":generateGuid(),
        "EventTypeName":"Peili.Aedu.CancelTestSessionEvent",
        "FromPatient":false,
        "RepeatUntilConfirmation":true,
        "TimeStamp": generateTimestamp(),
        "Group": "AEduWebTestSession"
    }
    
}


//HELPER FUNCTIONS

function generateTimestamp() {
    var currentDate = new Date();
    var milliseconds = currentDate.getMilliseconds();
    var seconds = currentDate.getSeconds();
    
    // Ensure two digits for seconds
    seconds = seconds < 10 ? '0' + seconds : seconds;
  
    var timestamp = seconds + '.' + milliseconds;
  
    return timestamp;
}

function generateGuid() { 
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return (
        s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
    );
}
