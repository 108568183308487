
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import SignalRComponent, { SignalRLib } from '../../api/signalRComponent';
import { finishTestSessionEvent } from '../../api/signalRClasses';

const AeduTest = (props) => {

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const { t, i18n } = useTranslation();
    const [ currentLanguage, setCurrentLanguage ] = useState(i18n.language);

    const errRef = useRef();
    
    const [rows, setRows] = useState([]);
    const [progress, setProgress] = useState([]);
    const [completedTests, setCompletedTests] = useState(0);
    const completedTestsRef = useRef([]);

    const [ testFinished, setTestFinished ]= useState(false);

    const [ finishTestActiveClass, setFinishTestActiveClass ] = useState('');

    const [ activePlayers, setActivePlayers ] = useState(0);


    /*useEffect(() => {
        if (rows) {
            setProgress(Array(rows.length).fill(0));
            completedTestsRef.current = Array(rows.length).fill(false);
            setCompletedTests(0);
        }
        
    }, [rows]);*/

    useEffect(() => { 
        if(props.continueSession === true){
            const prevStudents = JSON.parse(localStorage.getItem('students'));
            setRows(prevStudents);
            //props.getStudents(prevStudents);
            //props.setContinueSession(false);

        } else {
            getGrades();
        }
    }, [props.continueSession]);
    
    useEffect(() => {
        countVisibleItems();
    }, [props.activePlayers]);

    useEffect(() => {
        getGrades();
    }, [props.studentList]);

    function countVisibleItems() {
        const container = document.querySelector('.view-4 .aedu-players-container');
        const grids = container.querySelectorAll('.players-grid-by-classname');
        let visibleItemsCount = 0;
    
        grids.forEach(grid => {
            const visibleItems = Array.from(grid.children).filter(item => {
                const style = getComputedStyle(item);
                return item.classList.contains('item') && style.display !== 'none' && style.visibility !== 'hidden';
            });
            visibleItemsCount += visibleItems.length;
    
            // If there are no visible items in this grid, hide it
            if (visibleItems.length === 0) {
                grid.style.display = 'none';
            } else {
                grid.style.display = '';
            }
        });
    
        //console.log(visibleItemsCount); 
        if(completedTests >= visibleItemsCount) {
            setFinishTestActiveClass('active');
        } else {
            setFinishTestActiveClass('');
        }
    
        setActivePlayers(visibleItemsCount);
    }

    useEffect(() => {
        const progress = props.sendProgressToPlayerResult?.progress;
        const clientId = props.sendProgressToPlayerResult?.clientId;
        const studentId = props.sendProgressToPlayerResult?.studentId;
        //console.log(clientId);
        const statusMessage = props.sendProgressToPlayerResult?.message ? props.sendProgressToPlayerResult?.message : 'Intro';
        const currentStatus = props.sendProgressToPlayerResult?.status;
        let msg = '';
        //console.log(statusMessage);
        switch (statusMessage) {
            case 'Intro': 
                msg = 'Intro';
            break;
            case 'CompletedIntro': 
                msg = t('Vaihe') + ' 1';
            break;
            case 'CompletedBlock0': 
                msg = t('Vaihe') + ' 2';
            break;
            case 'CompletedBlock1': 
                msg = t('Vaihe') + ' 3';
            break;
            case 'CompletedBlock2': 
                msg = t('Vaihe') + ' 4';
            break;
            case 'CompletedBlock3': 
                msg = t('Vaihe') + ' 5';
            break;
            case 'CompletedBlock4': 
                msg = t('Vaihe') + ' 6';
            break;
            case 'CompletedBlock5': 
                msg = t('Vaihe') + ' 7';
            break;
            case 'CompletedBlock6': 
                msg = t('Vaihe') + ' 8';
            break;
            case 'CompletedBlock7': 
                msg = t('Vaihe') + ' 9';
            break;
            case 'CompletedBlock8': 
                msg = t('Vaihe') + ' 10';
            break;
            case 'CompletedBlock9': 
                msg = t('Valmis/Kultamunajahti');
            break;
            default:
                msg = 'default';
        break;
        }

        /*if(progress === 100) {
            msg = 'Valmis/Kultamunajahti';
        }*/
        /*const blockNumber = parseInt(statusMessage.replace('CompletedBlock', ''));

        if (blockNumber >= 0 && blockNumber < 9) {
            msg = `Vaihe ${blockNumber + 1}`;
        } else if (blockNumber === 9) {
            msg = 'Testi valmis'
        }*/
        //console.log(currentStatus);
        if ( currentStatus === 'TestPaused') {
            msg = t('TestPaused');
        }

        /*const cloneElements = document.querySelectorAll('.cloned');
        const newRows = [...rows];

        cloneElements.forEach((element) => {
            const id = element.getAttribute('data-id');
            const name = element.getAttribute('data-name');
            const className = element.getAttribute('data-class');
            const clientId = element.getAttribute('data-client-id');
            
            const existingRow = newRows.find((row) => row.id === id && row.clientId === clientId);
            
            if (!existingRow) {
                newRows.push({ id, realName: name, class: className, clientId, test: 'testi' });
            }
        });

        console.log(newRows);
        setTimeout(() => {
            setRows(newRows);
        },500);
        setTimeout(() => {
            console.log(rows);
        },800); */
        

        // Assuming rows is your state variable for the data
        setRows(rows.map(row => 
            row.id === studentId && !row.clientId ? { ...row, clientId } : row
        ));

        addPercents(clientId, progress, msg);

        //console.log(rows);
    
        countVisibleItems();

    }, [props.sendProgressToPlayerResult]);


    const [errMsg, setErrMsg] = useState('');

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    useEffect(() => { 
        setErrMsg('');
        getGrades();
        setTestFinished(false);
    }, [props.currentGrade]);

    useEffect(() => {
        setRows([]);
        setTestFinished(false);
        setProgress([]);
        completedTestsRef.current = [];
        getGrades();
    }, [props.removeAllRows]);

    const updateProgress = (index, newProgress) => {
        setProgress(prevProgress => {
          const updatedProgress = [...prevProgress];
          updatedProgress[index] = newProgress;
          return updatedProgress;
        });
      };

    const getGrades = async () => {
        setAjaxLoad('loading');
       
        try {
            const response = await axiosPrivate.get('/Organisations/' + auth.organisationId + '/Patients',  {
                //signal: controller.signal
            });
            //console.log(response.data); 

            let studentsArray = [];
            response.data.forEach((row) => {
                let rowData = JSON.parse(row.patientReadOnlyData) !== null ? JSON.parse(row.patientReadOnlyData) : [];
                for(let i = 0; i < props.currentGrade.length; i++ ){
                    if ( rowData.class === props.currentGrade[i]?.name ) {
                        studentsArray.push( {'realName': rowData?.realName, 'class': rowData?.class, 'age': rowData?.age, 'gender': rowData?.gender, 'handedness': rowData?.handedness, 'language': rowData?.language, 'isS2Student': rowData?.isS2Student, 'id': row.id, 'createdDate': row.createdDate });
                    }
                }
            });
            //console.log(studentsArray);
            studentsArray.sort((a, b) => a.realName.localeCompare(b.realName));
            setRows(studentsArray);
            setAjaxLoad('');
            //isMounted && 
            //setGrades(classesArray);
            //setFilteredData(classesArray);

        } catch(err){
            setAjaxLoad('');
            //console.error(err);
        }
    }


    const finishTest = () => {
        let confirmMessage = t("Oletko varma että haluat lopettaa pelin?");
        const confirmFinish = window.confirm(confirmMessage);

        if (confirmFinish) {
            let finishTestSession = finishTestSessionEvent();
            SignalRLib.InvokeJs('SendCommand', props.generatedId, JSON.stringify(finishTestSession));
           
            setTestFinished(true);
            setCompletedTests(0);
            setActivePlayers(0);
            //setProgress([]);
            if (rows) {
                setProgress(Array(rows.length).fill(0));
                completedTestsRef.current = Array(rows.length).fill(false);

            }

            props.finishView();
            
        }
    }

    const removeStudentFromList = (id) => {
        let confirmMessage = t("Haluatko varmasti poistaa oppilaan listasta?");
        const confirmRemove = window.confirm(confirmMessage);
        if (confirmRemove) {
            /*setRows(prevRows => {
                const updatedRows = prevRows.map(row => {
                    if (row.id === id) {
                        return { ...row, removed: true };
                    }
                    return row;
                });
                return updatedRows;
            }); */

            // Add 'removed' class to the div item under players grid
            const item = document.querySelector(`.view-4 .players-grid .item[data-client-id="${id}"]`);
            if (item) {
                item.classList.add('removed');
            }
        }
    }

    const addPercents = (itemId, progress, message) => {

        const gameItem = document.querySelector(`.view-4 .item[data-client-id="${itemId}"]`);

        if(message === 'TestPaused' && gameItem !== null) {
            gameItem.classList.add('paused');
        } else if (gameItem !== null) {
            gameItem.classList.remove('paused');
        }

        setProgress(prevProgress => {
            const newProgress = [...prevProgress];
            //console.log(rows[1].clientId);
            //console.log(itemId);
            const index = rows.findIndex(row => row.clientId === itemId);
            //console.log(testFinished);
            /*if(testFinished) {
                newProgress[index] = 0;
            }*/


            if(newProgress[index] !== 100) {
                if (newProgress[index] === undefined) {
                    newProgress[index] = progress;
                } else {
                    
                    newProgress[index] = progress;
                }
    
            }



            // Select the .progress-bar-bg element for this item
            const progressBarBg = document.querySelector(`.item[data-client-id="${itemId}"] .progress-bar-bg`);
            const progressBarPercents = document.querySelector(`.item[data-client-id="${itemId}"] .progress-bar`);
            const statusMessage = document.querySelector(`.view-4 .item[data-client-id="${itemId}"] .statusmessage`);
            //const statusMessageText = statusMessage ? statusMessage.innerHTML : '';
            if (progressBarBg) {
                // Set the width to the new progress value
                progressBarBg.style.width = `${newProgress[index]}%`;
                // Add 'completed' class if progress reaches 100


                if (newProgress[index] === 100 && !completedTestsRef.current[index]) {
                    progressBarBg.classList.add('completed');
                    
                    completedTestsRef.current[index] = true;
                    if(!testFinished) {
                        setCompletedTests(prev => prev + 1);
                    }
                    
                }
            }
            if(progressBarPercents){
                //console.log(newProgress);
                progressBarPercents.innerHTML = `${newProgress[index]}%`;

                switch (newProgress[index]) {
                    case 0: 
                        progressBarPercents.innerHTML = '0/11';
                    break;
                    case 10: 
                    case 5: 
                        progressBarPercents.innerHTML = '1/11';
                    break;
                    case 20:
                    case 19: 
                        progressBarPercents.innerHTML = '2/11';
                    break;
                    case 30:
                    case 28:
                        progressBarPercents.innerHTML = '3/11';
                    break;
                    case 40:
                    case 37:
                        progressBarPercents.innerHTML = '4/11';
                    break;
                    case 50:
                    case 46: 
                        progressBarPercents.innerHTML = '5/11';
                    break;
                    case 60:
                    case 55: 
                        progressBarPercents.innerHTML = '6/11';
                    break;
                    case 70:
                    case 64:
                        progressBarPercents.innerHTML = '7/11';
                    break;
                    case 80:
                    case 73:
                        progressBarPercents.innerHTML = '8/11';
                    break;
                    case 90:
                    case 82:
                        progressBarPercents.innerHTML = '9/11';
                    break;
                    case 91:
                        progressBarPercents.innerHTML = '10/11';
                    break;
                    case 100:
                        progressBarPercents.innerHTML = '11/11';
                    break;
                    default:
                        progressBarPercents.innerHTML = '';
                break;
                }
            }
            if(newProgress[index] !== 100) {
                if (statusMessage) {
                    // Add the message to the className of the statusmessage element
                    //console.log(message);
                    statusMessage.innerHTML = message;
                }
            } else {
                if(statusMessage) {
                    statusMessage.innerHTML = 'Valmis/Kultamunajahti';
                }
                
            }



            return newProgress;
        });
    };

    // Group rows by class
    const groupedRows = rows.reduce((groups, row) => {
        const key = row.class;
        if (!groups[key]) {
        groups[key] = [];
        }
        groups[key].push(row);
        return groups;
    }, {});

    return (
        <section className={"aedu-players-container " + ajaxLoad}> 
            <p className='heading'>{t('Pelin tilanne')} ({completedTests}/{activePlayers} {t('valmiina')})</p>
            <div className="players-grid">
                {Object.entries(groupedRows).map(([className, students]) => (
                    <div className="players-grid-by-classname" key={className}>
                        <h2>{className}</h2>
                        {students.map((student, index) => (
                        <div
                        data-id={student.id} 
                        data-name={student.realName}
                        data-class={student.class}
                        data-received="true" 
                        className="item" 
                        key={"player-" + index} 
                        data-progress={progress[index]} 
                        data-online="false">
                            <div className="img-wrapper">
                                <span className="progress-bar-bg"></span>
                                <span className="peili-icon person"></span>
                                <div className="progress-bar">0%</div>
                                <div className="remove-student" onClick={() => removeStudentFromList(student.clientId)}></div>
                            </div>
                            <p className="player-name">{student.realName}</p>
                            <p className="statusmessage"></p>
                        </div>
                        ))}
                    </div>
                ))}

            </div>
            <div className="bottom-content">
                <button onClick={() => finishTest()} className={`peili-btn choose-classes ` + finishTestActiveClass}>{t('LOPETA TESTI')}</button>
            </div>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
}

export default AeduTest