
import { useRef, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import AeduGradesTable from '../components/aedu/AeduGradesTable';
import AeduStudentsTable from '../components/aedu/AeduStudentsTable';
import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@microsoft/signalr';
import SignalRComponent, { SignalRLib } from '../api/signalRComponent';
import AeduTest from '../components/aedu/AeduTest';
import { createStudentViewModel, studentListRequestEvent, patientCredentialsResponseEvent, confirmableEventReceived, startTestSessionEvent, sendStudentListUpdate, finishTestSessionEvent, cancelTestSessionEvent } from '../api/signalRClasses';
import { MessageContext } from '../context/MessageContext';
import AeduTestFinished from '../components/aedu/AeduTestFinished';
import { RefreshContext } from '../context/RefreshContext';
import { dir, use } from 'i18next';
import CryptoJS from 'crypto-js';

import DirtyWords from '../files/DirtyWords.json';

const Aedu = (props) => {

    let dev = false;

    //const signalR = SignalRLib;

    const { t, i18n } = useTranslation();
    const [ currentLanguage, setCurrentLanguage ] = useState(i18n.language);

    const dirtyWords = DirtyWords;

    const { messages, removeMessage, clearMessages, markMessageConfirmed } = useContext(MessageContext);
    const { triggerRefresh } = useContext(RefreshContext);

    const [ updateStudentsTable, setUpdateStudentsTable ] = useState(false);

    const [ grades, setGrades ] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [ currentGrade, setCurrentGrade ] = useState([]);
    const [ filteredData, setFilteredData ] = useState(grades);

    const [studentList, setStudentList] = useState([]);
    const [ continueSession, setContinueSession ] = useState(false);

    const [ continueActive, setContinueActive ] = useState('');

    const [client, setClient] = useState({});

    const [ help, setHelp ] = useState('Pääset kohta pelaamaan tietokonepeliä. Ennen pelin käynnistymistä teemme muutaman vaiheen yhdessä. Edetään kaikki yhtä matkaa, joten kuuntele ohjeitani ja pysähdy odottamaan, kun pyydän. Jotta pääsemme peliin, tulee meidän kirjautua peliin tunnuksella. Klikkaa ensin näytölläsi olevaa Syötä tunnus -lokeroa. Luettelen nyt tunnuksen teille kirjain tai numero kerrallaan ja kirjoitan sen samalla taululle. Kirjoita sinä tunnus yhtä aikaa näytölläsi olevaan Syötä tunnus -lokeroon. Kirjainkoolla ei ole merkitystä.');

    const [ helpSecondary, setHelpSecondary ] = useState('Kun olet saanut kirjoitettua tunnuksen, klikkaa “Kirjaudu” -painiketta ja pysähdy odottamaan seuraavaa ohjetta.');

    const [ helpActive, setHelpActive ] = useState('');
    const [helpIndicator, setHelpIndicator] = useState('');

    const [ gameState, setGameState ] = useState('');

    const viewFirst = useRef('active');
    const viewSecondary = useRef('');
    const viewThird = useRef('');
    const viewFourth = useRef('');
    const viewFift = useRef('');

    const [init, setInit] = useState(false);
    const [tableEmpty, setTableEmpty] = useState(false);
    const [removeAllRows, setRemoveAllRows] = useState(false);

    const [ generatedId, setGeneratedId ]= useState('');
    const [ validationResult, setValidationResult ] = useState('');

    const [ testStudentLogin, setTestStudentLogin ] = useState(false);

    const [ activePlayers, setActivePlayers ] = useState(false);

    const [ testStatus, setTestStatus ] = useState('');

    const [sendProgressToPlayerResult, setSendProgressToPlayerResult] = useState(null);

    const [receivedStates, setReceivedStates] = useState([]);
    const [counter, setCounter] = useState(0);

    const [ring, setRing] = useState(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>);


    //SECRET TESTI 
    
    const secretKey = CryptoJS.enc.Utf8.parse('2rnNLtkGN6NE7GWOf7FS2yLvH7bpzLcW');  // Adjusted for exact 32 byte key
    const iv = CryptoJS.enc.Utf8.parse('1234567890123456'); // 16-byte IV (example)
    
    function encrypt(text) {
        //TÄMÄ RETURN POIS TUOTANTOON
        if(dev){
            return text;
        }
        
        let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), secretKey, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
      }
      
      function decrypt(ciphertext) {
        let decodedCiphertext = CryptoJS.enc.Base64.parse(ciphertext); // Ensure Base64 is correctly parsed
        let decrypted = CryptoJS.AES.decrypt({ciphertext: decodedCiphertext}, secretKey, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
      }
    
    // Example usage:
    /*const myMessage = 'jklasdfjklasdfWERWERQasdf234789&&/==asdfjkl';
    const encrypted = encrypt(myMessage);
    const decrypted = decrypt(encrypted);*/
        
    //console.log('Encrypted:', encrypted);
    //console.log('Decrypted:', decrypted);

     //SECRET TESTI 

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);

    //CHECK IF DISCONNECTED

    useEffect(() => {
        if(!generatedId){
            return;
        }
      
        //console.log(SignalRLib.$vars.connection.state);
        const checkConnection = () => {
            if (SignalRLib.$vars.connection.state === 'Connected') {
                
                if (generatedId) {
                    SignalRLib.InvokeJs("JoinTherapy", encrypt(generatedId));
                }
            }
        };
        checkConnection();
    

    }, [generatedId, SignalRLib.$vars.connection?.state]); 
        

    //MESSAGES USEEFFECT

    useEffect(() => {
            //console.log(messages);
            //const intervalId = setInterval(() => {
            
            if(!messages) return;
            

            let className =  studentList.length > 0 ? studentList[0].className : '';

            //console.log(studentList);
 
            //Peili.Aedu.StudentListRequestEvent

            if (messages['Peili.Aedu.StudentListRequestEvent']) {

                //if(testStatus !== 'active') {
                    //console.log('LIST SENT');
                    Object.keys(messages['Peili.Aedu.StudentListRequestEvent']).forEach((key) => {
                        let className = studentList.length > 0 ? studentList[0].className : '';

                        const studentListByClassName = studentList.reduce((result, student) => {
                            const className = student.className;
                            if (!result[className]) {
                                result[className] = [];
                            }
                            result[className].push(student);

                            return result;
                        }, {});
                        
                        let studentListRequest = studentListRequestEvent(
                            className,
                            studentListByClassName,
                            messages['Peili.Aedu.StudentListRequestEvent'][key].Guid   
                        );
                        SignalRLib.InvokeJs('SendCommand', encrypt(generatedId), JSON.stringify(studentListRequest));
                        confirmableEventReceived(messages['Peili.Aedu.StudentListRequestEvent'][key].Guid);
                        removeMessage('Peili.Aedu.StudentListRequestEvent', key);
                    });
                //}
            }
 
            //PATIENT CREDENTIALS REQUEST EVENT

            if(messages['Peili.Aedu.PatientCredentialsRequestEvent']) {
                
                //if(continueSession === false || testStatus !== 'active') {
                //if( testStatus !== 'active') {
                    Object.keys(messages['Peili.Aedu.PatientCredentialsRequestEvent']).forEach((key) => {
                        let studentId = messages['Peili.Aedu.PatientCredentialsRequestEvent'][key].Student.id;
                        let studentGuid = messages['Peili.Aedu.PatientCredentialsRequestEvent'][key].Guid;
                        
                        let msgKey = key;

                        Object.keys(studentList).forEach(async (key) => {
                           
                            if(studentList[key].id === studentId && studentList[key].cloned === true){
                                
                                // Check if there is a cloned item in the studentList
                                const clonedItem = studentList.find(item => item.id === studentList[key].id && item.name === studentList[key].name && item.cloned === true);
                                if (clonedItem) {
                                    // Find the original item in the studentList
                                    const originalItem = studentList.find(item => item.name === studentList[key].name && item.cloned !== true);
                                    if (originalItem) {
                                        // If the original item is found, assign its username and password to the cloned item
                                        clonedItem.username = originalItem.username;
                                        clonedItem.password = originalItem.password;
                                        
                                    }

                                    clonedItem.loggedIn = true;
                                    let patientCredentialsResponse = patientCredentialsResponseEvent(encrypt(clonedItem.username), encrypt(clonedItem.password), studentGuid, key);

                                    /*let patientCredentialsResponse = patientCredentialsResponseEvent(encrypt(studentList[key].username), encrypt(studentList[key].password), studentGuid, key);*/
                                    SignalRLib.InvokeJs("SendCommand", encrypt(generatedId), JSON.stringify(patientCredentialsResponse));
                                    removeMessage('Peili.Aedu.PatientCredentialsRequestEvent', msgKey);
                                    confirmableEventReceived(studentGuid);
                                }
                            }
                            
                            if ( studentList[key].id === studentId && studentList[key]['loggedIn'] === false) {
                                //console.log(studentList[key]);
                                studentList[key]['loggedIn'] = true;

                                let clientData = await getSingleCredentials(studentList[key].id);
                                studentList[key].username = clientData.username;
                                studentList[key].password = clientData.password;

                                if(typeof clientData !== 'undefined') {
                                    let patientCredentialsResponse = patientCredentialsResponseEvent(encrypt(clientData.username), encrypt(clientData.password), studentGuid, key);

                                    /*let patientCredentialsResponse = patientCredentialsResponseEvent(encrypt(studentList[key].username), encrypt(studentList[key].password), studentGuid, key);*/
                                    SignalRLib.InvokeJs("SendCommand", encrypt(generatedId), JSON.stringify(patientCredentialsResponse));
                                    removeMessage('Peili.Aedu.PatientCredentialsRequestEvent', msgKey);
                                    confirmableEventReceived(studentGuid);
                                }

                            } 
                        });

                    });
                //}
            }
            
            /*if(messages['Peili.Aedu.PatientCredentialsRequestEvent']) {

                Object.keys(messages['Peili.Aedu.PatientCredentialsRequestEvent']).forEach((key) => {
                    let studentId = messages['Peili.Aedu.PatientCredentialsRequestEvent'][key].Student.id;
                    let studentGuid = messages['Peili.Aedu.PatientCredentialsRequestEvent'][key].Guid;

                    getPatientCredentials(studentId, studentGuid);
                    //removeMessage('Peili.Aedu.PatientCredentialsRequestEvent', key);
                });
                
                /*const studentIds = [];
                const duplicateStudentIds = [];

                Object.keys(messages['Peili.Aedu.PatientCredentialsRequestEvent']).forEach((key) => {
                    const studentId = messages['Peili.Aedu.PatientCredentialsRequestEvent'][key].Student.id;
                    
                    if (studentIds.includes(studentId)) {
                        duplicateStudentIds.push(studentId);
                    } else {
                        studentIds.push(studentId);
                    }

                });

                if (duplicateStudentIds.length >= 1) {
                    console.log('There are duplicate Student.ids');
                    let playersGrid = document.querySelector('.view-3 .players-grid');
                    let item = playersGrid.querySelector(`[data-id="${duplicateStudentIds[0]}"]`);
                    
                    if (item) {
                        const numItems = playersGrid.querySelectorAll(`[data-id="${duplicateStudentIds[0]}"]`).length;
                        const numMessages = Object.keys(messages['Peili.Aedu.PatientCredentialsRequestEvent']).length;
                        console.log(numItems); 
                        
                        // Only create a new item if the number of items is less than the number of messages
                        if (numItems < numMessages) {
                            let newItem = item.cloneNode(true);
                            newItem.dataset.id = duplicateStudentIds[0];
                            //console.log(newItem);
                            playersGrid.appendChild(newItem);

                            const newPlayer = {
                                id: newItem.dataset.id,
                                className: newItem.dataset.class,
                                name: newItem.dataset.name,
                                hasAlreadyPlayed: newItem.dataset.hasAlreadyPlayed,
                                isActive: newItem.dataset.isActive,
                                createdDate: newItem.dataset.createdDate,
                                languageCode: newItem.dataset.languageCode,
                                loggedIn: newItem.dataset.loggedIn, 
                            }

                            setStudentList(prevList => [...prevList, newPlayer]);
                            console.log(studentList);
  
                            Object.keys(messages['Peili.Aedu.PatientCredentialsRequestEvent']).forEach((key) => {
                                let studentId = messages['Peili.Aedu.PatientCredentialsRequestEvent'][key].Student.id;
                                let studentGuid = messages['Peili.Aedu.PatientCredentialsRequestEvent'][key].Guid;
            
                                getPatientCredentials(studentId, studentGuid);
                                //removeMessage('Peili.Aedu.PatientCredentialsRequestEvent', key);
                            });

                            //removeMessage('Peili.Aedu.PatientCredentialsRequestEvent', key);
                        }
                    }
                    //removeMessage('Peili.Aedu.PatientCredentialsRequestEvent', key);
                } else {
                    console.log('There are no duplicate Student.ids');
                } */

            //}

            //FINISHED TEST

            if (messages['Peili.Aedu.FinishTestSessionEventReveiced']) {
                Object.keys(messages['Peili.Aedu.FinishTestSessionEventReveiced']).forEach((key) => {
                    const clientId = messages['Peili.Aedu.FinishTestSessionEventReveiced'][key].ClientId;
                    const playersGrid = document.querySelector('.view-3 .players-grid');
                    const item = playersGrid.querySelector(`[data-client-id="${clientId}"]`);

                    const gamePlayersGrid = document.querySelector('.view-4 .players-grid');
                    const gameitem = playersGrid.querySelector(`[data-client-id="${clientId}"]`);

                    /*if (item) { 
                        item.removeAttribute('data-client-id');
                        item.dataset.hasAlreadyPlayed = 'true';
                        
                    }

                    if (gameitem) { 
                        gameitem.removeAttribute('data-client-id');
                        gameitem.dataset.hasAlreadyPlayed = 'true';
                      
                    }*/
                    
                    confirmableEventReceived(messages['Peili.Aedu.FinishTestSessionEventReveiced'][key].Guid);
                    removeMessage('Peili.Aedu.FinishTestSessionEventReveiced', key);
                });
            } 

            if (messages['Peili.Events.ConfirmableEventReceived']) {
                Object.keys(messages['Peili.Events.ConfirmableEventReceived']).forEach((key) => {
                    //console.log('CONFIRMED ' + messages['Peili.Events.ConfirmableEventReceived'][key].Guid);
                    confirmableEventReceived(messages['Peili.Events.ConfirmableEventReceived'][key].Guid);
                    removeMessage('Peili.Events.ConfirmableEventReceived', key);
                });
            }

            if (messages['Peili.Aedu.StatusUpdateEvent']) {

                const studentIds = [];
                const duplicateStudentIds = [];

                Object.keys(messages['Peili.Aedu.StatusUpdateEvent']).forEach((key) => {

                    //console.log(messages['Peili.Aedu.StatusUpdateEvent'][key]);

                    if (messages['Peili.Aedu.StatusUpdateEvent'][key].Data.selectedStudent) {
                        
                        const selectedStudentId = messages['Peili.Aedu.StatusUpdateEvent'][key].Data.selectedStudent.id;
                        const selectedStudentName = messages['Peili.Aedu.StatusUpdateEvent'][key].Data.selectedStudent.name;
                        const playersGrid = document.querySelector('.view-3 .players-grid');
                        const item = playersGrid.querySelector(`[data-id="${selectedStudentId}"]`);

                        const gamePlayersGrid = document.querySelector('.view-4 .aedu-players-container .players-grid');
                        const gameItem = gamePlayersGrid.querySelector(`[data-id="${selectedStudentId}"]`);

                        const clientId = messages['Peili.Aedu.StatusUpdateEvent'][key].Data.clientId;
                        const data = messages['Peili.Aedu.StatusUpdateEvent'][key].Data;

                        /*if(item && item.dataset.hasAlreadyPlayed) {

                            item.removeAttribute('data-client-id');
                            item.removeAttribute('data-status');
                            if(item.querySelector('.statusmessage')){
                                item.querySelector('.statusmessage').innerHTML = '';
                            }

                        }*/

                        //about duplicate start
                       
                        if (studentIds.includes(selectedStudentId)) {
                            //if(item && !item.dataset.hasAlreadyPlayed){
                                duplicateStudentIds.push(selectedStudentId);
                            //}
                            
                        } else {
                            studentIds.push(selectedStudentId);
                        }

                        //about duplicate end

                        /*console.log(selectedStudentId);
                        console.log(selectedStudentName);
                        console.log(item);*/
                        if (item && !item.dataset.clientId) { // Check if item doesn't already have client-id
                            item.dataset.clientId = clientId;

                            setUpdateStudentsTable(true);
                            //studentList.sort((a, b) => a.localeCompare(b));
                            
                        }

                        if (gameItem && !gameItem.dataset.clientId) { // Check if item doesn't already have client-id
                            gameItem.dataset.clientId = clientId;
                        }
 
                        

                        const clientItem = playersGrid.querySelector(`[data-client-id="${clientId}"]`);
                        const gameClientItem = gamePlayersGrid.querySelector(`[data-client-id="${clientId}"]`);

                        //DUPLICATE START 
                    

                        if (!clientItem) {

                            if (duplicateStudentIds.length >= 1) {

                                let foundItem = playersGrid.querySelector(`[data-id="${duplicateStudentIds[0]}"]`);
                                let foundGameItem = gamePlayersGrid.querySelector(`[data-id="${duplicateStudentIds[0]}"]`);
                                
                                if (foundItem) {
                                    //console.log(foundItem.dataset.id);
                                    let newItem = foundItem.cloneNode(true);
                                    newItem.classList.add('cloned');
                                    newItem.dataset.id = duplicateStudentIds[0];
                                    newItem.dataset.clientId = clientId;
                                    //playersGrid.appendChild(newItem);
        
                                    const newPlayer = {
                                        id: newItem.dataset.id,
                                        className: newItem.dataset.class,
                                        name: newItem.dataset.name,
                                        cloned: true
                                    }
                                    
                                    setStudentList(prevList => [...prevList, newPlayer]);

                                    // Find the .players-grid-by-classname container that has an h2 tag with the same class name as foundItem
                                    const className = foundItem.dataset.class;
                                    const grid = Array.from(document.querySelectorAll('.view-3 .players-grid .players-grid-by-classname')).find(grid => {
                                        const h2 = grid.querySelector('h2');
                                        return h2 && h2.textContent === className;
                                    });

                                    // If the grid was found, append the new item to it
                                    if (grid) {
                                        grid.appendChild(newItem);
                                    } else {
                                        // If the grid was not found, append the new item to playersGrid
                                        playersGrid.appendChild(newItem);
                                    }


                                }
                                if (foundGameItem) {
       
                                    let newGameItem = foundGameItem.cloneNode(true);
                                    newGameItem.classList.add('cloned');
                                    newGameItem.dataset.id = duplicateStudentIds[0];
                                    newGameItem.dataset.clientId = clientId;
                                    //gamePlayersGrid.appendChild(newGameItem);
        
                                    const newPlayer = {
                                        id: newGameItem.dataset.id,
                                        className: newGameItem.dataset.class,
                                        name: newGameItem.dataset.name,

                                    }

                                    // Find the .players-grid-by-classname container that has an h2 tag with the same class name as foundGameItem
                                    const gameClassName = foundGameItem.dataset.class;
                                    const gameGrid = Array.from(document.querySelectorAll('.view-4 .players-grid .players-grid-by-classname')).find(gameGrid => {
                                        const h2 = gameGrid.querySelector('h2');
                                        return h2 && h2.textContent === gameClassName;
                                    });

                                    // If the grid was found, append the new item to it
                                    if (gameGrid) {
                                        gameGrid.appendChild(newGameItem);
                                    } else {
                                        // If the grid was not found, append the new item to gamePlayersGrid
                                        gamePlayersGrid.appendChild(newGameItem);
                                    }

                                }

                            } else {

                            }
                        }

                        //DUPLICATE END

                        if( clientItem ) {
                            clientItem.dataset.status = data.status;
                            const statusMessageElement = clientItem.querySelector('.statusmessage');
                            
                            /*switch (statusMessage) {
                                case 'WaitingForTestStart': 
                                    msg = 'Intro';
                                break;
                                case 'CompletedIntro': 
                                    msg = 'Vaihe 1';
                                break;
                                break;
                                default:
                                    msg = 'default';
                            break;
                            }*/

                            if (statusMessageElement) {
                                statusMessageElement.textContent =  t(clientItem.dataset.status);
                            }
                            if( testStatus === ('') && data.status === 'ReadingInstructions') {
                                setGameState('settingsView');
                            }
                            clientItem.dataset.received = 'true';
                            clientItem.classList.remove('hidden');
                            clientItem.classList.remove('ready-to-drop');
                        }

                        if( gameClientItem ) {
                            gameClientItem.dataset.status = data.status;
                            gameClientItem.dataset.statusMessage = data.statusMessage;
                            
                            let progress = data.progress;
                            let message = data.statusMessage;
                            let currentStatus = data.status;
                            
                            gameClientItem.dataset.progress = Math.ceil(progress * 100);//Math.ceil(progress) * 10;
                            gameClientItem.dataset.online = "true";
                            gameClientItem.dataset.received = 'true';
                            gameClientItem.classList.remove('hidden');
                            gameClientItem.classList.remove('ready-to-drop');
                            //clientId => selectedStudentId
                            sendProgressToPlayer(clientId, selectedStudentId, Math.ceil(progress * 100), message, currentStatus);
                        } 

                        

                        // Check if clientId doesn't exist in client or if the data associated with clientId has changed
                        //if (!client[clientId]) {
                            
                            
                            setClient(prevClient => {
                                return { 
                                    ...prevClient, 
                                    [clientId]: data
                                };
                            });
                            //console.log(client);
                        //}

                        removeMessage('Peili.Aedu.StatusUpdateEvent', key);

                        //console.log(client);
                        //console.log(messages['Peili.Aedu.StatusUpdateEvent'][key].Data.status);
                        confirmableEventReceived(messages['Peili.Aedu.StatusUpdateEvent'][key].Guid);
                        
                    }

                    if (messages['Peili.Aedu.StatusUpdateEvent'][key].Data.status === 'WaitingForTestStart' && testStatus === 'active') {
                        
                        SignalRLib.InvokeJs('SendCommand', encrypt(generatedId), JSON.stringify(startTestSessionEvent()));
                        removeMessage('Peili.Aedu.StatusUpdateEvent', key);
                    }
                });
            }


            //PINGEVENTS
            

            if(messages['Peili.Aedu.PingEvent']) {
                //console.log(messages);
                let pingArray = [];
                if (messages['Peili.Aedu.PingEvent']) {
                    Object.keys(messages['Peili.Aedu.PingEvent']).forEach((key) => {
                        const clientId = messages['Peili.Aedu.PingEvent'][key].ClientId;

                        /*const playersGrid = document.querySelector('.view-4 .aedu-players-container .players-grid');
                        const item = playersGrid.querySelector(`[data-client-id="${clientId}"]`);

                        const gamePlayersGrid = document.querySelector('.view-3 .aedu-players-container .players-grid');
                        const gameItem = gamePlayersGrid.querySelector(`[data-client-id="${clientId}"]`);

                        if (gameItem && gameItem.dataset.clientId === clientId) {
                            gameItem.dataset.received = 'true';
                            gameItem.classList.remove('hidden');
                            gameItem.classList.remove('ready-to-drop');
                        }

                        if (item && item.dataset.clientId === clientId) {
                            item.dataset.received = 'true';
                            item.classList.remove('hidden');
                            item.classList.remove('ready-to-drop');
                        } */

                       /* const data = messages['Peili.Aedu.PingEvent'][key];
   
                        const allItems = document.querySelectorAll('.players-grid .item'); */

                        setReceivedStates(prevStates => ({
                          ...prevStates,
                          [clientId]: Date.now(), // Store the current time
                        }));

                        removeMessage('Peili.Aedu.PingEvent', key);
                    });
                }
            }

            /*const intervalId = setInterval(() => {
                const allItems = document.querySelectorAll('.players-grid .item');
                allItems.forEach((item) => {
                  const clientId = item.dataset.clientId;
                  if (Date.now() - (receivedStates[clientId] || 0) > 10000) {
                    // Step 4: If a clientId has not received a PingEvent in the last 10 seconds, set its data-received attribute to false
                    item.dataset.received = 'false';
                  } else {
                    item.dataset.received = 'true';
                  }
                });
            }, 1000);*/
            

            const intervalId = setInterval(() => {

                setCounter(prevCounter => (prevCounter + 1) % 31);

                const allItems = document.querySelectorAll('.players-grid .item');
                allItems.forEach((item) => {
                  const clientId = item.dataset.clientId;
                  //console.log(clientId);
                  //console.log(receivedStates);
                    if (Date.now() - (receivedStates[clientId] || 0) > 10000) {
                    // If a clientId has not received a PingEvent in the last 10 seconds, set its data-received attribute to false
                    item.dataset.received = 'false';
                    } else {
                    item.dataset.received = 'true';
                    }

                    if (Date.now() - (receivedStates[clientId] || 0) > 30000) {
                        // If a clientId has not received a PingEvent in the last 30 seconds, remove it from the student list
                        
                        

                        if(!item.classList.contains('add-new-student') && testStatus === 'active') {
                            //item.remove();
                            item.classList.add('hidden');
                            item.classList.add('ready-to-drop');
                            setActivePlayers(true);
                        }
                        //item.dataset.testi = 'RED ALERT';
                        
                        //console.log(clientId);
                    } else {
                        setActivePlayers(true);
                        item.dataset.received = 'true';
                        item.classList.remove('hidden');
                        item.classList.remove('ready-to-drop');
                    }
                });

              }, 1000);

            return () => clearInterval(intervalId);

            /*if(messages['Peili.Aedu.FinishTestSessionEventReceived']) {
                if (messages['Peili.Aedu.FinishTestSessionEventReceived']) {
                    Object.keys(messages['Peili.Aedu.FinishTestSessionEventReceived']).forEach((key) => {
                        let finishTestSession = finishTestSessionEvent();
                        SignalRLib.InvokeJs('SendCommand', generatedId, JSON.stringify(finishTestSession));
                        removeMessage('Peili.Aedu.FinishTestSessionEventReceived', key);
                    });
                }
            }*/


    }, [messages]);

    /*useEffect(() => {
        //console.log(counter);
    }, [counter]); */

     useEffect(() => {
        setTimeout(() => {
            if(grades.length === 0 || grades !== undefined){
                //setRing(<p>{t('Ei luokkia näytettävänä')}</p>);
            }
        },1000);

    }, [grades]);



    useEffect(() => {
        setHelpIndicator('notification');
    },[help]);

    const sendProgressToPlayer = (clientId, studentId, progress, message, status) => {
        const result = { clientId, studentId, progress, message, status };
        setSendProgressToPlayerResult(result);
    }

    const addCredentialsToPatient = async () => {
        //console.log(studentList);
        Object.keys(studentList).forEach(async (key) => {
            try {

                if(studentList[key]['username'] === undefined && studentList[key]['password'] === undefined) {
                    //console.log(studentList[key]);
                    const response = await axiosPrivate.get('/patients/' + studentList[key].id + '/PatientCredentials',  {
                        //signal: controller.signal
                    });
                    const clientData = response.data;
                    studentList[key]['username'] = dev ? btoa(clientData.username) : clientData.username;
                    studentList[key]['password'] = dev ?  btoa(clientData.password) : clientData.password;
                }

                updateStudentList(studentList);

            }  catch(err) {
                console.log('Error');
                return;
            }

        });
    }



    const getSingleCredentials = async (patientId) => {
        try {
            const response = await axiosPrivate.get('/patients/' + patientId + '/PatientCredentials',  {
                //signal: controller.signal
            });
            const clientData = response.data;
            return clientData;

        }  catch(err) {
            console.log('Error');
            //console.log(err);
            return;
        }
    }

    const getPatientCredentials = async (patientId, guid) => {
       
        Object.keys(studentList).forEach(async (key) => {
            
            if ( studentList[key].id === patientId) {
                
                //if( studentList[key].loggedIn === false) {
                    //studentList[key].loggedIn = true;
                    try {
                        const response = await axiosPrivate.get('/patients/' + patientId + '/PatientCredentials',  {
                            //signal: controller.signal
                        });
                        const clientData = response.data;
                        
                        let patientCredentialsResponse = patientCredentialsResponseEvent(encrypt(clientData.username), encrypt(clientData.password), guid);
                        //console.log(JSON.stringify(patientCredentialsResponse));
                        SignalRLib.InvokeJs("SendCommand", encrypt(generatedId), JSON.stringify(patientCredentialsResponse));
                        //confirmableEventReceived(guid);
                        //return response.data;
            
                    }  catch(err) {
                        console.log('Error');
                        //console.log(err);
                        return;
                    }
                } 
            //}
        });
    }

    function generateTimestamp() {
        var currentDate = new Date();
        var milliseconds = currentDate.getMilliseconds();
        var seconds = currentDate.getSeconds();
        
        // Ensure two digits for seconds
        seconds = seconds < 10 ? '0' + seconds : seconds;
      
        var timestamp = seconds + '.' + milliseconds;
      
        return timestamp;
    }


    //Join hub when generatedId is set
    useEffect(() => {
        if (generatedId) {
            joinHub(generatedId);

        }
    }, [generatedId]);

    useEffect(() => {
        if(activePlayers === true) {
            setActivePlayers(false);
        }
    }, [activePlayers]);

    useEffect(() => {

        getGrades();
        setInit(false);
        setTableEmpty(false);
   
        //POISTA EHKÄ


        //clearMessages();
        //addEventListeners();

        if(localStorage.getItem('generatedId')) {
            setContinueActive('active');
            /*if(viewFirst.current === 'active'){
                continuePreviousSession();
            }*/
            //
        }
        console.log('init');
    }, [init]);



    useEffect(() => {
        setRemoveAllRows(false); 
    }, [removeAllRows]);

    useEffect(() => {
        switch(gameState) {
            case 'initialView':
                setHelp('Pääset kohta pelaamaan tietokonepeliä. Ennen pelin käynnistymistä teemme muutaman vaiheen yhdessä. Edetään kaikki yhtä matkaa, joten kuuntele ohjeitani ja pysähdy odottamaan, kun pyydän. Jotta pääsemme peliin, tulee meidän kirjautua peliin tunnuksella. Klikkaa ensin näytölläsi olevaa Syötä tunnus -lokeroa. Luettelen nyt tunnuksen teille kirjain tai numero kerrallaan ja kirjoitan sen samalla taululle. Kirjoita sinä tunnus yhtä aikaa näytölläsi olevaan Syötä tunnus -lokeroon. Kirjainkoolla ei ole merkitystä.');
                setHelpSecondary('Kun olet saanut kirjoitettua tunnuksen, klikkaa “Kirjaudu” -painiketta ja pysähdy odottamaan seuraavaa ohjetta.');
                break;
            case 'idView':
                setHelp('Luettelen nyt tunnuksen teille kirjain tai numero kerrallaan ja kirjoitan sen samalla taululle. Kirjoita sinä tunnus yhtä aikaa näytölläsi olevaan Syötä tunnus -lokeroon. Kirjainkoolla ei ole merkitystä.');
                setHelpSecondary('Kun olet saanut kirjoitettua tunnuksen, klikkaa “Kirjaudu” -painiketta ja pysähdy odottamaan seuraavaa ohjetta.');
                
                break;
            case 'studentView':
                setHelp('Näytöllesi ilmestyi nimilista. Voit nyt valita listasta oman nimesi. Nimilaatikko muuttuu oranssiksi, joten varmista vielä, että otit varmasti oman nimesi. Tämän jälkeen voit klikata “Kirjaudu” -painiketta ja pysähtyä odottamaan seuraavaa ohjetta.');
                setHelpSecondary('');
                break;
            case 'settingsView':
                setHelp('Nyt näytöllesi ilmestyi “Tervetuloa!” -näkymä. Seuraavaksi sinun tulee säätää äänenvoimakkuus sopivalle tasolle. Voit säätää äänenvoimakkuutta liikuttamalla näytöllä näkyvää mustaa palkkia. Laita nyt kuulokkeet korville ja säädä äänenvoimakkuus sopivaksi.');
                setHelpSecondary('Kun olet säätänyt äänenvoimakkuuden sopivalle tasolle, voit klikata “OK” -painiketta. Tämän jälkeen siirryt odotusnäkymään. Peli ei vielä käynnisty, joten odottele rauhassa. Voit odotellessa katsella, miltä huoneessa näyttää.');
                break;
            case 'testView':
                setHelp('Pääset pelaamaan tietokonepeliä, jossa sinua opastaa kiva ja kiltti Laura Lohikäärme -hahmo. Ensimmäisenä suoritat harjoitusosuuden, jossa Laura Lohikäärme neuvoo, miten pelissä liikutaan ja toimitaan. Pelissä sinun tehtävänäsi on kuunnella tarkasti Laura Lohikäärmeen antamat ohjeet alusta loppuun ja toteuttaa ne sitten parhaasi mukaan. Pelissä katsotaan, kuinka hyvin pystyt toimimaan ohjeiden mukaan, joten keskity ja tee parhaasi.');
                setHelpSecondary('Peli on itsenäinen suoritus, joten annetaan jokaiselle työrauha. Voit käyttää pelissä joko tietokoneen vieressä olevaa erillistä hiirtä tai tietokoneen kosketuslevyä. Voit valita sen kumpi sinulle tuntuu luonte-vammalta. Nyt aloitetaan, onnea matkaan.');
                break;
            default:
                setHelp('Pääset kohta pelaamaan tietokonepeliä. Ennen pelin käynnistymistä teemme muutaman vaiheen yhdessä. Edetään kaikki yhtä matkaa, joten kuuntele ohjeitani ja pysähdy odottamaan, kun pyydän. Jotta pääsemme peliin, tulee meidän kirjautua peliin tunnuksella. Klikkaa ensin näytölläsi olevaa Syötä tunnus -lokeroa. Luettelen nyt tunnuksen teille kirjain tai numero kerrallaan ja kirjoitan sen samalla taululle. Kirjoita sinä tunnus yhtä aikaa näytölläsi olevaan Syötä tunnus -lokeroon. Kirjainkoolla ei ole merkitystä.');
                setHelpSecondary('Kun olet saanut kirjoitettua tunnuksen, klikkaa “Kirjaudu” -painiketta ja pysähdy odottamaan seuraavaa ohjetta.');

        }
    }, [gameState]);


    const joinHub = (generatedId) => {

        //SignalRLib.InitJs('https://peilivisionsignalrserverdev.azurewebsites.net/MainHub');

        //SignalRLib.ConnectJs();


        SignalRLib.InvokeJs("JoinTherapy", encrypt(generatedId));

       
    }

    const chooseClasses = (grades) => {

        window.scrollTo(0, 0);
        setInit(true);

        setGameState('studentView');

        /*const items = document.querySelectorAll('.players-grid .item');
        items.forEach(item => {
          
            const statusMessage = item.querySelector('.statusmessage');
            if (statusMessage) {
                statusMessage.innerHTML = '';
            }
            
            item.removeAttribute('data-client-id');
            item.removeAttribute('data-status'); 
        }); */

        viewFirst.current = '';
        viewSecondary.current = '';
        viewThird.current = 'active';
        viewFift.current = '';
    }
    
    const idView = (grades) => {

        if(continueActive === 'active') {
            const confirmMessage = t("Sinulla on edellinen sessio vielä käynnissä, haluatko varmasti aloittaa uuden session?");
            if (!window.confirm(confirmMessage)) {
                return;
            } else {
                setContinueSession(false);
            }
        }


        window.scrollTo(0, 0);
        setInit(true);
        setCurrentGrade(grades);
        localStorage.setItem('testStatus', '');
        localStorage.setItem('currentGrade', JSON.stringify(grades));
        let newId = generateIDWithChecksum(6);
        while (validateID(newId) === 'Invalid ID') {
            console.log('INVALID');
            newId = generateIDWithChecksum(6);
        }
        setGeneratedId(newId);
        localStorage.setItem('generatedId', newId);
        
        setGameState('idView');

        viewFirst.current = '';
        viewSecondary.current = 'active';
        viewThird.current = '';
        viewFourth.current = '';
        viewFift.current = '';
    }

    useEffect(() => { 
        //if(continueSession === false ) {
        //if(testStatus !== 'active') {
            //SignalRLib.InvokeJs('SendCommand', props.generatedId, JSON.stringify(studentList));
            
            //addCredentialsToPatient();
            if (SignalRLib.$vars.connection !== null && SignalRLib.$vars.connection.state === 'Connected') {
                updateStudentList(studentList);
            }
            
            localStorage.setItem('students', JSON.stringify(studentList));
        //}
 
    }, [studentList]);

    const updateStudentList = (studentsList) => {
        
         const studentListByClassName = studentsList.reduce((result, student, index) => {
             const className = student.className;
             if (!result[className]) {
                 result[className] = [];
             }
 
             result[className].push(student);
             
             return result;
         }, {});
 
         //console.log(studentListByClassName);
         let studentListUpdate = sendStudentListUpdate(
             studentListByClassName
         );

        SignalRLib.InvokeJs('SendCommand', encrypt(generatedId), JSON.stringify(studentListUpdate));  

    }

    const getStudents = (rows) => {
        //console.log(rows);
        //console.log('ga');
        const studentsList = rows.map((row) => {
            return {
                id: row.id,
                className: row.class,
                name: row.realName,
                hasAlreadyPlayed: false,
                isActive: false,
                createdDate: row.createdDate,
                languageCode: row.language,
                loggedIn: false
            };
        });
        
        setStudentList(studentsList);
        localStorage.setItem('students', JSON.stringify(studentsList));
  
        window.scrollTo(0, 0);
    }

    const startTest = (rows) => {
        
        let prompt = t("Oletko varma, että haluat aloittaa pelin?");
        const confirmStart = window.confirm(prompt);
        if (!confirmStart) {
            return;
        }

        window.scrollTo(0, 0);

        setInit(true);

        

        viewFirst.current = '';
        viewSecondary.current = '';
        viewThird.current = '';
        viewFourth.current = 'active';
        viewFift.current = '';
        SignalRLib.InvokeJs('SendCommand', encrypt(generatedId), JSON.stringify(startTestSessionEvent()));
        setTestStatus('active');
        setGameState('testView');
        localStorage.setItem('testStatus', 'active');
    }

    /*const createStudentViewModel = (student) => {
        return {
            id: student.id,
            className: student.class,
            name: student.realName,
            hasAlreadyPlayed: false,
            isActive: false,
            createdDate: student.createdDate,
            languageCode: student.language
        };
    }*/


    const goBack = (promptOrNot) => {

        if(promptOrNot !== 'no-prompt') { 
            let confirmMessage = t("Haluatko varmasti palata alkuun ja lopettaa testin?");
            const confirmGoBack = window.confirm(confirmMessage);
            if (!confirmGoBack) {
                return;
            }
        }
        
        cancelTestSessionEvent();
        
        SignalRLib.StopJs();
        triggerRefresh();
        setTableEmpty(true);
        setInit(true);
        setCurrentGrade('');
        setRemoveAllRows(true);
        setTestStatus('');
        clearMessages();

        //SignalRLib.newInit('https://peilivisionsignalrserverdev.azurewebsites.net/MainHub');

        localStorage.removeItem('generatedId');
        localStorage.removeItem('students');
        localStorage.removeItem('testStatus');
        localStorage.removeItem('currentGrade');
        setContinueActive('');
        setGameState('initialView');
        viewFirst.current = 'active';
        viewSecondary.current = '';
        viewThird.current = '';
        viewFourth.current = '';
        viewFift.current = '';

        props.reloadAedu();
    }

    const finishView = () => {
        setInit(true);
        viewFirst.current = '';
        viewSecondary.current = '';
        viewThird.current = '';
        viewFourth.current = '';
        viewFift.current = 'active';
    }

    const handleSearch = (e) => { 
        let value = e.target.value.toLowerCase();
        let result = [];
        result = grades.filter((data) => {
            if( typeof data.name !== 'undefined' && data.name.toLowerCase().search(value) !== -1 ) {
                return data.name.toLowerCase().search(value) !== -1;
            } /*else {
                return data.name.toLowerCase().search(value) !== -1;
            }*/
        });
        setFilteredData(result);
    }
 
    const addEventListeners = () => {
        const liItems = document.querySelectorAll('.li-item');
        liItems.forEach(item => {
            item.addEventListener('click', handleClick);
        });
    }

    function handleClick() {
        if(viewFirst.current !== 'active') {
            goBack();
        }  
    }

    const splitArrayByName = (dataArray) => {
        const uniqueNamesSet = new Set(dataArray.map(item => item.name));
        const uniqueNamesArray = Array.from(uniqueNamesSet);
        const resultString = uniqueNamesArray.join(', ');
        return resultString;
    };

    /*const updatePlayerHasPlayedStatus = async (rows) => {
        let studs = 0;

        for(const row of rows) {

            studs++;

            let patientData = { 'realName': row.realName, 'age': row.age, 'gender': row.gender, 'handedness': row.handedness, 'language': row.language, 'isS2Student': fieldActive === '' ? row.isS2Student : 'false', 'class': gradeName, 'nativeLanguage': 'finnish' }

                try { 
                    const response = await axiosPrivate.put(PUT_GRADE_URL + '/' + props.currentStudent.id + '/PatientReadOnlyData', JSON.stringify({'patientReadOnlyData': JSON.stringify(patientData) })
                    ); 

                } catch(err) {
                    
                    if(!err?.response) {
                        setErrMsg('No server response');
                    } else if(err.response?.status === 400) {
                        setErrMsg(err.response?.data);
                    } else if (err.response?.status === 401) {
                        setErrMsg('401 Unauthorized');
                    } else {
                        setErrMsg('Request failed');
                    }
                    setAjaxLoad('');
                    errRef.current.focus();
                }
        }

    } */

    const getGrades = async () => {
        try {
            const response = await axiosPrivate.get('/Organisations/' + auth.organisationId + '/Patients',  {
                //signal: controller.signal
            });
            //console.log(response.data);
            let classesArray = [];
            let students = {};
            response.data.forEach((row) => {
                //console.log(JSON.parse(row.patientReadOnlyData).class);

                let grade = JSON.parse(row.patientReadOnlyData) !== null ? JSON.parse(row.patientReadOnlyData).class : 'Undefined';
                let id = row.id;
                let createdDate = row.createdDate;
                let modifiedDate = row.modifiedDate;
                var index = classesArray.findIndex(x => x.name===grade); 

                const { class: rowClass, ...rest } = JSON.parse(row.patientReadOnlyData) !== null ? JSON.parse(row.patientReadOnlyData) : 'Undefined';

                if (!students[rowClass]) {
                    students[rowClass] = []; // Initialize the array for the class if it doesn't exist
                }
                
                students[rowClass].push(rest);
                

                if(index === -1){

                    classesArray.push( {'name': grade, 'id': id, 'createdDate': createdDate, 'modifiedDate': modifiedDate, 'studentCount': '0'});
                    //setClassesObject( [...classesObject, {'name': grade, 'createdDate': createdDate, 'modifiedDate': modifiedDate} ] );
                } 
                //classesArray.push();
            });
            //console.log(students);
            //INSERT STUDENTCOUNT

            classesArray.forEach((classObj) => {
                const className = classObj.name;
                if (students.hasOwnProperty(className)) {
                  const studentsInClass = students[className];
                  classObj.studentCount = studentsInClass.length.toString();
                } else {
                  // If there are no students for a particular class, set the studentCount to 0
                  classObj.studentCount = '0';
                }
              });
            //console.log(classesArray);

            //isMounted && 
            setGrades(classesArray);
            setFilteredData(classesArray);

        } catch(err){ 
            //console.error(err);
            window.location.reload();
        }
    }

    function containsDirtyWord(text) {
        if (text === null || text === undefined || dirtyWords === null || dirtyWords === undefined) {
            return false;
        }
    
        // Flatten the array of arrays into a single array of words
        const flatDirtyWords = dirtyWords.RECORDS.flat();
    
        const textLower = text.toLowerCase();
    
        return flatDirtyWords.some(w => textLower.includes(w) || textLower.replace("1", "i").replace("3", "e").replace("4", "a").replace("7", "t").replace("0", "o").includes(w));
    }

    const generateIDWithChecksum = (length) => {
        //console.log(dirtyWords);
        const chars = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
        let id;
        do {
          const random = new Array(length - 1).fill().map(() => chars[Math.floor(Math.random() * chars.length)]);
          let sum = 0;
      
          random.forEach((char) => {
            sum += char.charCodeAt(0);
          });
      
          const checksumValue = sum % 34;
          const checksumChar = chars[checksumValue];
      
          id = random.join('') + checksumChar;
        } while (containsDirtyWord(id)); // Regenerate the ID if it contains a dirty word
      
        return id;
      };

    //VANHA

    /*const generateIDWithChecksum = (length) => {
        console.log(dirtyWords);
        const chars = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
        const random = new Array(length - 1).fill().map(() => chars[Math.floor(Math.random() * chars.length)]);
        let sum = 0;
    
        random.forEach((char) => {
            sum += char.charCodeAt(0);
        });
    
        const checksumValue = sum % 34;
        const checksumChar = chars[checksumValue];
    
        return random.join('') + checksumChar;
    }; */


    
    const validateID = (id) => {
        const chars = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
        let sum = 0;
    
        for (let i = 0; i < id.length - 1; i++) {
            if (!chars.includes(id[i])) {
                return 'Invalid ID';
            }
            sum += id.charCodeAt(i);
        }
    
        const checksumValue = sum % 34;
        return id[id.length - 1] === chars[checksumValue] ? 'Valid ID' : 'Invalid ID';
    };

    const continuePreviousSession = () => {
        
        const previousGeneratedId = localStorage.getItem('generatedId');
        const previousStudents = JSON.parse(localStorage.getItem('students'));
        const previousTestStatus = localStorage.getItem('testStatus');
        const previousGrades = JSON.parse(localStorage.getItem('currentGrade'));
        //console.log(previousStudents);
        if (previousGeneratedId) {
            setContinueSession(true);
            setGeneratedId(previousGeneratedId);
            setStudentList(previousStudents);
            setCurrentGrade(previousGrades);
            if(previousTestStatus === 'active') {

                setGameState('testView');
                setTestStatus('active');
                /*setTimeout(function(){
                    console.log(studentList);
                    console.log(previousGeneratedId);
                }, 2000);*/
                
                viewFirst.current = '';
                viewSecondary.current = '';
                viewThird.current = '';
                viewFourth.current = 'active';
                viewFift.current = '';
                //SignalRLib.InvokeJs('SendCommand', encrypt(previousGeneratedId), JSON.stringify(startTestSessionEvent()));
            } else {

                setGameState('studentView');

                viewFirst.current = '';
                viewSecondary.current = '';
                viewThird.current = 'active';
                viewFourth.current = '';
                viewFift.current = '';
            }

        }
    }

    const helpHover = () => {
        setHelpActive('active');
        setHelpIndicator('');

        const helpContentElement = document.querySelector('.help-content');
        if (helpContentElement) {
            helpContentElement.scrollTop = 0;
        }
    }
    const helpHoverOut = () => {
        setHelpActive('');
    }

    const returnContinueBtn = () => {

        let gradesList = [];

        if(localStorage.getItem('currentGrade') ) {
            JSON.parse(localStorage.getItem('currentGrade')).map((item) =>  
                gradesList.push(item.name)
            )
            return <button className={"continue-previous-session " + continueActive} onClick={() => continuePreviousSession()}>{t('JATKA EDELLISTÄ SESSIOTA') + ': ' + gradesList.join(', ')}</button>
        } else {
            return <></>;
        }
        
    }
    
    return (
        <>
            <div className="peili-views-wrapper views-aedu">
                
                <section useref={viewFirst} className={'peili-content-wrapper aedu-classes-table view-1 ' + viewFirst.current}>
                    <div className="headings">
                        <h1>{t('ALOITA UUSI PELISESSIO')}</h1>
                        <p>{t('Valitse yksi tai useampi luokka pelisessioon')}.</p>
                        
                    </div>
                    <div className="view-content">
                        <div className='tools-wrapper'>
                            <input type="search" placeholder={t('Etsi...')} onChange={(e) =>handleSearch(e)}></input>

                            { 
                            
                            localStorage.getItem('currentGrade') ?
                            
                                returnContinueBtn()
                            :
                            <></>
                            }

                        </div>   
                        {grades?.length 
                            ? (
                                <AeduGradesTable continueActive={continueActive} continuePreviousSession={continuePreviousSession} data={filteredData} currentGrade={localStorage.getItem('currentGrade')} chooseClasses={idView} tableEmpty={tableEmpty} setInitState={setInit}/>
                            ) : ring
                        }
                    </div>
                </section>

                <section ref={viewSecondary} className={'peili-content-wrapper aedu view-2 ' + viewSecondary.current}>
                    <div className="top-bar">
                        <div className='btn-wrapper'>
                            <button className="back-button" onClick={goBack}><span className="peili-icon caret-left-round"></span></button>
                        </div>
                        <div className="text-wrapper">
                            { /* <h2 className="organisation-name">{t('')}{auth.user}</h2> */} 
                            <h2 className="organisation-name">{t('')}{auth.organisation} - {currentGrade.length > 0 ? splitArrayByName(currentGrade) : ''}</h2>
                            { /* <h2 className="grades-names">{' '}{currentGrade.length > 0 ? splitArrayByName(currentGrade) : ''}</h2> */}
                            <p className="info">{t("Ohjeista opiskelijoita / oppilaita kirjautumaan A-EDU-peliin alla näkyvällä tunnuksella osoittessa www.peilivision.fi/aedu")}</p>
                        </div>
                    
                        <div className="session-id-wrapper">
                        </div>
                    </div>

                    <div className="view-content">
                        <section className={"aedu-code-container"}> 
                            <div className="code-wrapper">
                                <p className="heading"> {t('YHDISTÄMISKOODI')}:</p>
                                <div className="session-id-wrapper">
                                    <p className='connection-id'>{generatedId}</p>
                                </div>
                                <p className="content"><b>{t("LUE OPISKELIJOILLE SEURAAVA TEKSTI:")}</b> <br></br><br></br><i>{t("“Pääset nyt tekemään pelinomaisen testin, jossa saat erilaisia tehtäviä. Kuuntele saamasi ohjeet tarkasti ja sen jälkeen tee tehtävät niin hyvin kuin osaat ja muistat. Tämä on testi ja tehdään itsenäisesti, joten tee se parhaasi mukaan ja anna muille työrauha. Seuraa sinulle annettavia ohjeita. Ei haittaa, vaikka et muista kaikkia, testi menee automaattisesti eteenpäin.  Opettaja käynnistää ja lopettaa kaikkien pelit yhtäaikaisesti. Jos sinulla tulee teknisiä ongelmia, nosta kätesi.“")}</i> <br></br><br></br><b>{t("Paina seuraavaksi JATKA")}</b>.</p>
                            </div>
                            <div className="bottom-content">
                                <button onClick={chooseClasses} className={`peili-btn choose-classes active`}>{t('JATKA')}</button>
                            </div>
                        </section>
                    </div>
                    
                </section>
                <section ref={viewThird} className={'peili-content-wrapper aedu view-3 ' + viewThird.current}>
                    <div className="top-bar">
                        <div className='btn-wrapper'>
                            <button className="back-button" onClick={goBack}><span className="peili-icon caret-left-round"></span></button>
                        </div>
                        <div className="text-wrapper">
                            { /* <h2 className="organisation-name">{t('')}{auth.user}</h2> */}
                            <h2 className="organisation-name">{t('')}{auth.organisation} - {currentGrade.length > 0 ? splitArrayByName(currentGrade) : ''}</h2>
                            { /* <h2 className="grades-names">{' '}{currentGrade.length > 0 ? splitArrayByName(currentGrade) : ''}</h2> */}
                            <p className="info"><b>{t('Odota')},</b> {t('että kaikki opiskelijat ovat päässeet kirjautumaan ja näkyvät aktiivisena, eli vihreällä. Sen jälkeen aloita testi painamalla')} <b>"{t('ALOITA TESTI')}".</b></p>
                        </div>
                        <div className="session-id-wrapper">
                            <p className='connection-id'>{t('Yhdistämiskoodi')}: <span className="generated-id">{generatedId}</span></p>
                            { /*<p>{validationResult}</p> */ }
                        </div>
                    </div>

                    <div className="view-content">
                        <AeduStudentsTable setContinueSession={setContinueSession} continueSession={continueSession} startTest={startTest} generatedId={encrypt(generatedId)} getStudents={getStudents} goBack={goBack} currentGrade={currentGrade} removeAllRows={removeAllRows} setInitState={setInit} updateStudentsTable={updateStudentsTable} setUpdateStudentsTable={setUpdateStudentsTable} />
                    </div>
                    
                </section>
                <section ref={viewFourth} className={'peili-content-wrapper aedu view-4 ' + viewFourth.current}>
                    <div className="top-bar">
                        <div className='btn-wrapper'>
                            <button className="back-button" onClick={goBack}><span className="peili-icon caret-left-round"></span></button>
                        </div>
                        <div className="text-wrapper">
                        <h2 className="organisation-name">{t('')}{auth.user}</h2>
                            <h2 className="organisation-name">{t('')}{auth.organisation} - {currentGrade.length > 0 ? splitArrayByName(currentGrade) : ''}</h2>
                            { /* <h2 className="grades-names">{' '}{currentGrade.length > 0 ? splitArrayByName(currentGrade) : ''}</h2> */}
                            <p className="info"><b>{t('Odota')},</b> {t('että kaikki oppilaat ovat pelanneet pelin ja lopeta testi painamalla')} <b>{t('Lopeta testi')}.</b></p>
                        </div>
                        <div className="session-id-wrapper">
                            <p className='connection-id'>{t('Yhdistämiskoodi')}: <span className="generated-id">{generatedId}</span></p>
                            { /*<p>{validationResult}</p> */ }
                        </div>
                    </div>

                    <div className="view-content">
                            
                            <AeduTest studentList={studentList} setContinueSession={setContinueSession} continueSession={continueSession} activePlayers={activePlayers} generatedId={encrypt(generatedId)} sendProgressToPlayerResult={sendProgressToPlayerResult} goBack={goBack} finishView={finishView} currentGrade={currentGrade} removeAllRows={removeAllRows} setInitState={setInit} />
                    </div>
                    
                </section>
                <section ref={viewFift} className={'peili-content-wrapper aedu view-5 ' + viewFift.current}>
                    <div className="top-bar"> 
                        <div className='btn-wrapper'>
                            <button className="back-button" onClick={goBack}><span className="peili-icon caret-left-round"></span></button>
                        </div>
                        <div className="text-wrapper">
                        <h2 className="organisation-name">{t('')}{auth.user}</h2>
                            <h2 className="organisation-name">{t('')}{auth.organisation} - {currentGrade.length > 0 ? splitArrayByName(currentGrade) : ''}</h2>
                            { /* <h2 className="grades-names">{' '}{currentGrade.length > 0 ? splitArrayByName(currentGrade) : ''}</h2> */}
                            <p className="info"><b>{t('Odota')},</b> {t('että kaikki oppilaat ovat kirjautuneet ja näkyvät aktiivisten pelaajien listassa. Käynnistä sitten testi painamalla')} <b>{t('Aloita testi')}.</b></p>
                        </div>
                        <div className="session-id-wrapper">
                            <p className='connection-id'>{t('Yhdistämiskoodi')}: <span className="generated-id">{generatedId}</span></p>
                            { /*<p>{validationResult}</p> */ }
                        </div>
                    </div>

                    <div className="view-content">
                            <AeduTestFinished generatedId={generatedId} goBack={goBack} setStudentList={setStudentList}></AeduTestFinished>
                    </div>
                    
                </section>
            </div>

            { /*<div className={ "help-box " + helpIndicator }  onMouseLeave={() => helpHoverOut()}>
                <p className={ "heading " + helpActive}>{t('Valvojan repliikit')}</p>
                <div className={"help-content " + helpActive}>
                   
                     <p className="text">{t(help)}</p>
                     { helpSecondary ? <p className="text">{t(helpSecondary)}</p> : '' }
                </div>
                <div className={"peili-icon help-icon " + helpActive} onMouseOver={() => helpHover()} ></div>
                    </div> */ }
        </>
    )
}

export default Aedu