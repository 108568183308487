import { useEffect } from 'react';
import {  NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import schoolBg from '../images/school-bg.png';
import gameBg from '../images/game-bg.png';

import playIcon from '../icons/play-btn.svg';
import playIconSecondary from '../icons/play-btn-secondary.svg';
import durationIcon from '../icons/duration.svg';
import useAuth from '../hooks/useAuth';


const Home = () => {

    const { t } = useTranslation();
    const { auth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const tutorialMode = true;

    useEffect(() => {
        // Get the query parameter "lang" from the URL
        const queryParams = new URLSearchParams(window.location.search);

        if(!auth.language || auth.language === null || typeof auth.language === 'undefined'){ 
            return;
        } else {
            const trimmedLanguage = auth.language.substring(0, 2);
            const selectedLanguage = trimmedLanguage;//queryParams.get('lang');
        
            //console.log(trimmedLanguage);
            
            const selectElement = document.querySelector('.peili-lan-nav');
            for (const option of selectElement.options) {
                // Check if the option's value matches the selectedLanguage
                if (option.value === selectedLanguage) {
                  // Set the selected property of the matching option to true
                    option.selected = true;
        
                    const changeEvent = new Event('change', {
                    bubbles: true, // Allow event to bubble up the DOM tree
                    cancelable: false, // Event cannot be canceled
                    // Exit the loop since we found the matching option
                    });
        
                    selectElement.dispatchEvent(changeEvent);
                  break;
                }
              }
        }

        
    }, []);



    return (
        <>
        {/*
            <section className="peili-content-wrapper">
                <h1>{t('KOJELAUTA')}</h1> 
                <p>{t('Ei nähtävää')}...</p>
            </section> */
        }

        <section className="peili-content-wrapper dashboard">
            <div className="dashboard-module tutorial-wrapper first">
                <img alt="school-bg" src={schoolBg} className="bg-image"></img>
                <h2>{t('OHJEISTUS: LISÄÄ OPPILAITA')}</h2>
                <div className="begin-tutorial-btn">

                    <NavLink to={'/classes'} state={{ tutorialMode: true }}>
                        <img src={playIcon} className='peili-icon play'></img>
                    </NavLink> 
                </div>
                <div className="duration-wrapper">
                    <img src={durationIcon} className="peili-icon duration"></img>
                    <p>{t('Kesto noin 10 min')}</p>
                </div>
            </div>
            <div className="dashboard-module empty tutorial-wrapper secondary">
                { /* <img src={gameBg} className="bg-image"></img> */ }
                <h2>{t('OHJEISTUS: PELIN KÄYNNISTYS')}</h2>
                <div className="begin-tutorial-btn">
                    <img src={playIconSecondary} className='peili-icon play secondary'></img>
                </div> 
                <div className="duration-wrapper"> 
                    <img src={durationIcon} className="peili-icon duration"></img>
                    <p>{t('Kesto noin 10 min')}</p> 
                </div>
            </div>
            <div className='dashboard-module empty'></div>
            <div className='dashboard-module empty'></div>
        </section>

        </>
    ) 
}

export default Home