
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const ADD_ORGANISATION_URL = '/Organisations';

const AddOrganisationForm = (props) => {

    const axiosPrivate = useAxiosPrivate();

    const nameRef = useRef();
    const errRef = useRef(); 

    const [name, setName] = useState('');

    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsActive, setSuggestionsActive] = useState('');

    const [activeSuggestions, setActiveSuggestions] = useState('');

    const [addOrganisationBtn, setAddOrganisationBtn] = useState('ADD ORGANISATION');

    const [errMsg, setErrMsg] = useState('');
    const [nameValidation, setNameValidation ] = useState(false);

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    useEffect(() => {

        let timeoutId;

        const checkTyping = () => {
            
            clearTimeout(timeoutId);
           
            timeoutId = setTimeout(() => {
                const typedValue = nameRef.current.value;
                if (typedValue.length > 4 /*&& typedValue.length % 2 === 0*/) {
                    setActiveSuggestions('');
                    setAddOrganisationBtn('ADD ORGANISATION');
                    checkDuplicateOrganisations(typedValue);
                }
            }, 500);
        };


        if (nameRef.current) {
            nameRef.current.addEventListener('input', checkTyping);
        }

        return () => {
            // Check if the ref is available before removing the event listener
            if (nameRef.current) {
                nameRef.current.removeEventListener('input', checkTyping);
            }

            // Check if the timeoutId is defined before clearing the timeout
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        }
    }, []);



    useEffect(() => {
        nameRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [name]);

    function calculateLevenshteinDistance(str1, str2) {
        const len1 = str1.length + 1;
        const len2 = str2.length + 1;
      
        const matrix = Array.from({ length: len1 }, () => Array(len2).fill(0));
      
        for (let i = 0; i < len1; i++) {
            matrix[i][0] = i;
        }
      
        for (let j = 0; j < len2; j++) {
            matrix[0][j] = j;
        }
      
        for (let i = 1; i < len1; i++) {
            for (let j = 1; j < len2; j++) {
                const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
            
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,        // Deletion
                    matrix[i][j - 1] + 1,        // Insertion
                    matrix[i - 1][j - 1] + cost  // Substitution
                );
            }
        }
      
        return matrix[len1 - 1][len2 - 1];
    }
      
    function compareStrings(str1, str2, maxDistance = 10, excludableWords = []) {
        const words1 = str1.split(/\s+/).filter(element => element);
        const words2 = str2.split(/\s+/).filter(element => element);

        let filteredWord1;
        let filteredWord2;

        if (words2.length < 3 && words1.length >= 2) {
            filteredWord1 = words1[0];//words1[0].split('').filter(char => !excludableWords.includes(char)).join('');
            filteredWord2 = words2[0];//.split('').filter(char => !excludableWords.includes(char)).join('');
          
        } else if (words2.length >= 3 && words1.length >= 3 ) {
            maxDistance = 4;
            filteredWord2 = [words2[0],words2[2]].join('');
            /*.map(word => word.split('').filter(char => !excludableWords.includes(char)).join(''))
            .join(' ');*/
            filteredWord1 = [words1[0],words1[2]].join('');
            /*filteredWord1 = [words1[0], words1[2]].map(word => word.split('').filter(char => !excludableWords.includes(char)).join('')).join(' ');*/
        } else if (words1.length < 3 && words2.length >= 3) {
            filteredWord1 = words1[0];//words1[0].split('').filter(char => !excludableWords.includes(char)).join('');
            filteredWord2 = words2[0];//.split('').filter(char => !excludableWords.includes(char)).join('');
        } else {
            filteredWord1 = words1[0];
            filteredWord2 = words2[0];
        }
        
        const distance = calculateLevenshteinDistance(filteredWord1, filteredWord2);
      
        if (distance <= maxDistance) {
            return true;
        } else {
            return false;
        }
    }
    

    const checkDuplicateOrganisations = (e) => {
        if(typeof e !== 'undefined') {
            setAjaxLoad('loading');
    
            let name = e;
            let result = '';

            let organisationsArray = [];
            let resultsArray = [];

            props.organisations.map((org) => {
                organisationsArray.push(org.name);
            });

            for(let i = 0; i < organisationsArray.length; i++){
                if( compareStrings(organisationsArray[i].toLowerCase(), name.toLowerCase(), 2, []) === true ){
                    resultsArray.push(organisationsArray[i]);
                }
            }

            setSuggestions(resultsArray);

            setTimeout(function(){
            
                if(resultsArray.length > 0) {
                    setSuggestionsActive('active');
                } else {
                    setSuggestionsActive('');
                }
                setAjaxLoad('');
                return result;
            },500);
        }
    }

    function validateField(e){
        if(e.value.length === 0) {
            e.classList.add('invalitaded');
            setNameValidation(false);
        } else {
            e.classList.remove('invalitaded');
            setNameValidation(true);
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if(addOrganisationBtn === 'SELECT ORGANISATION'){
            setAjaxLoad('loading');
            props.setNewOrganisationName(name);
            setName('');
            
            props.setPopupState('');
            props.setInitState(true);
            props.updateOrganisations(true);

            setTimeout(() => {
                setAjaxLoad('');
                setErrMsg('');
                return false;
            }, 500);
        }

        validateField(nameRef.current);

        if(nameValidation){
            setErrMsg('');
        } else {
            setErrMsg('Empty name');
            errRef.current.focus();
            return false;
        }

        setAjaxLoad('loading');

        let organisationsArray = [];

        props.organisations.map((org) => {
            organisationsArray.push(org.name.toLowerCase());
        });

        if(organisationsArray.includes(name.toLowerCase())){
            setErrMsg('Duplicate name, organisation already exists');
            errRef.current.focus();
            setAjaxLoad('');
            return false;
        } else {
            setErrMsg('');
            errRef.current.focus();
        }
       
        try { 
            const response = await axiosPrivate.post(ADD_ORGANISATION_URL, 
                JSON.stringify({'name': name})
            );
            props.setNewOrganisationName(name);
            setName('');

            props.setPopupState('');
            props.setInitState(true);
            props.updateOrganisations(true);

            setTimeout(() => {
                setAjaxLoad('');
                setErrMsg('');
            }, 500); 

        } catch(err) {

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg(err.response?.data);
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else {
                setErrMsg('Request failed');
            }
            setAjaxLoad('');
            setErrMsg('');
            errRef.current.focus();
        }

    } 

    const selectSuggestion = (sug, index) => {

        setActiveSuggestions(index);
        setName(sug);
        setAddOrganisationBtn('SELECT ORGANISATION');
        //nameRef.current = name;
    }

    return (
        <section className={"peili-popup-wrapper " + ajaxLoad}>
            <h2>Add organisation</h2>  
            <div className='closer' onClick={() => props.setPopupState('')}> 
                <i className='icon close'></i> 
            </div> 
            <form className='peili-form add-organisation-form' onSubmit={handleSubmit}>
                <div className='item full-width'>
                    <label htmlFor="name">Name</label>
                    <input 
                        type="text"  
                        id="name" 
                        ref={nameRef} 
                        autoComplete="off"  
                        onChange={(e) => { setName(e.target.value); validateField(e.target)}}
                        value={name}
                    />
                </div>

                <div className={"suggestions " + suggestionsActive}>
                    <p>Possible duplicate of:</p>
                    <ul>
                        {
                            suggestions.map((sug, index) => 
                                <li key={"suggestion-" + index}  className={`suggestion ${activeSuggestions === index ? 'active' : ''}`} onClick={() => selectSuggestion(sug, index)}>{sug}</li>
                        )}
                        
                    </ul>
                </div>

                <button type="submit" >{addOrganisationBtn}</button>

            </form>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
} 

export default AddOrganisationForm