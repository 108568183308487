import axios from 'axios';

let dev = false;

const BASE_URL = dev ? 'https://peili-edu-dev.azurewebsites.net/api/' : 'https://peilieduarvowebapi.azurewebsites.net/api/';

//https://peilieduarvowebapi.azurewebsites.net/api/ OIKEA
//https://peili-edu-dev.azurewebsites.net/api/ DEV
export default axios.create({
    baseURL: BASE_URL 
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 
        'Content-Type': 'application/json',
        'accept': 'application/json'
     },
     //withCredentials: true
});