
import { useState, useEffect, useRef } from 'react';
import AddGradeForm from '../components/grades/AddGradeForm';
import EditGradeForm from '../components/grades/EditGradeForm';
import GradesTable from '../components/tables/GradesTable';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';


import "react-datepicker/dist/react-datepicker.css";
import ZipUploader from '../components/organisations/ZipUploader';


import DatePicker, { registerLocale } from "react-datepicker";
import { fi, sv } from "date-fns/locale";
import StepButton from '../components/tutorial/StepButton';

registerLocale("fi", fi);
registerLocale("sv", sv);

const Grades = () => {

    const location = useLocation();

    const { t, i18n } = useTranslation();
    const [ currentLanguage, setCurrentLanguage ] = useState(i18n.language);

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n.language]);

    const [ tutorialMode, setTutorialMode ] = useState(location.state.tutorialMode);

    const [ grades, setGrades ] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const [ currentGrade, setCurrentGrade ] = useState('');
    const [ filteredData, setFilteredData ] = useState(grades);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const viewFirst = useRef('active');
    const viewSecondary = useRef('');
    const viewThird = useRef('');

    const [init, setInit] = useState(false);
    const [removeAllRows, setRemoveAllRows] = useState(false);

    const [ring, setRing] = useState(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>);

    useEffect(() => {
        getGrades();
        setInit(false);
        addEventListeners();
        /*let receivedTutorialMode = location.state.tutorialMode;
        setTutorialMode(receivedTutorialMode);*/
        //console.log(location.state.tutorialMode);
        //setTutorialMode(location.state.tutorialMode);
        console.log('init');
    }, [init]); 

    useEffect(() => {
        setRemoveAllRows(false); 
    }, [removeAllRows]);

    useEffect(() => {
        setTimeout(() => {
            if(grades.length === 0 || grades !== undefined){
                setRing(<p>{t('Ei luokkia näytettävänä')}</p>);
            }
        },1000);

    }, [grades]);

    useEffect(() =>{
        //let isMounted = true;
        //const controller = new AbortController(); 

        //getGrades();

        /*return () => {
            isMounted = false;
            controller.abort();
        }*/
    }, []);

    const openAddGradeForm = () => {
        setInit(true);
        if(viewSecondary.current === 'active' || viewThird.current === 'active'){
            viewFirst.current = 'active';
            viewSecondary.current = '';
            viewThird.current = '';
            setRemoveAllRows(true);
        } else {
            viewFirst.current = '';
            viewSecondary.current = 'active';
            viewThird.current = '';
        }

        if(tutorialMode === true /*&& viewFirst.current === 'active'*/) {
            setCurrentStep(currentStep + 1);
            setStepBtnPrevActiveClass(''); 
        }
        
    }

    const openEditGradeForm = (grade) => {

        if(viewFirst.current === 'active' || viewSecondary.current === 'active'){
            setCurrentGrade(grade);
            viewFirst.current = '';
            viewSecondary.current = '';
            viewThird.current = 'active';
        } else {
            setCurrentGrade('');
            setRemoveAllRows(true);
            viewFirst.current = 'active';
            viewSecondary.current = '';
            viewThird.current = '';
        }
        //setPopup('edit-grade-form');
    }

    const goBack = () => {
        setInit(true);
        setCurrentGrade('');
        setRemoveAllRows(true);
        viewFirst.current = 'active';
        viewSecondary.current = '';
        viewThird.current = '';
    }

    const handleSearch = (e) => { 
        let value = e.target.value.toLowerCase();
        let result = [];
        result = grades.filter((data) => {
            if( data.name.toLowerCase().search(value) !== -1 ) {
                return data.name.toLowerCase().search(value) !== -1;
            } else {
                return data.name.toLowerCase().search(value) !== -1;
            }
            //return data.username.toLowerCase().search(value) != -1;
        });
        setFilteredData(result);
    }

    const addEventListeners = () => {
        const liItems = document.querySelectorAll('.li-item');
        liItems.forEach(item => {
            item.addEventListener('click', handlelClick);
        });
    }

    function handlelClick() {
        if(viewFirst.current !== 'active') {
            openAddGradeForm();
        }  
    }

    function extractRelevantData(input) {
        // Initialize a variable to hold the extracted data
        let extractedData;

        // Check if the input is an object and it is not null
        if (typeof input === 'object' && input !== null) {
            // Check if 'Data' key exists in the object
            
            if (input.hasOwnProperty('Data')) {
                //console.log(input.Data);
                // If 'Data' key exists, use the nested data
                let data = input.Data;
                delete data.$type;
                delete data.additionalInfo;
                delete data.setting_DailyLiving2TaskBlockOrdering;
                extractedData = input.Data;//data.Data;
               
            } else {
                // If 'Data' key does not exist, the object itself contains the data
                extractedData = input;
            }
        } else {
            console.error('Invalid input type. Expected an object.');
            return null;
        }
    
        // Return the extracted data
        return extractedData;
    }
    
 
    const getGrades = async () => {



        setRing(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>);
 
        try {
            const response = await axiosPrivate.get('/Therapists/' + auth.id + '/Patients',  {
                //signal: controller.signal
            });
            //console.log(response.data);
            let classesArray = [];
            let students = {};
            let responseTest = response.data;

            response.data.forEach((row) => {

                //console.log(JSON.parse(row.patientReadOnlyData).class);
                let newRow = JSON.parse(row.patientReadOnlyData);
                if (newRow === null ){
                    return;
                    newRow = {};
                }
                delete newRow.$type;

                let grade = extractRelevantData(newRow).class;
                let teacherContact = extractRelevantData(newRow).email;//extractRelevantData(JSON.parse(row.patientReadOnlyData)).class;
                let createdDate = row.createdDate;
                let modifiedDate = row.modifiedDate;
                var index = classesArray.findIndex(x => x.name==grade);
                

                const { class: rowClass, ...rest } = extractRelevantData(newRow);//extractRelevantData(JSON.parse(row.patientReadOnlyData));
    
                if (!students[rowClass]) {
                    students[rowClass] = []; // Initialize the array for the class if it doesn't exist
                }
                
                students[rowClass].push(rest);
                
                
                if(index === -1){
                    
                    classesArray.push( {'name': grade, 'createdDate': createdDate, 'modifiedDate': modifiedDate, 'studentCount': '0', teacherContact: typeof teacherContact !== 'undefined' ? teacherContact : ''});
                    //setClassesObject( [...classesObject, {'name': grade, 'createdDate': createdDate, 'modifiedDate': modifiedDate} ] );
                    
                }
                
                //classesArray.push();
            });
            //console.log(students);
            //INSERT STUDENTCOUNT
            classesArray.forEach((classObj) => {
                const className = classObj.name;
                if (students.hasOwnProperty(className)) {
                    //console.log(students[className]);
                  const studentsInClass = students[className];
                  classObj.studentCount = studentsInClass.length.toString();
                } else {
                  // If there are no students for a particular class, set the studentCount to 0
                  classObj.studentCount = '0';
                }

              });
            //console.log(classesArray);
            
            //isMounted && 
            setGrades(classesArray);
            setFilteredData(classesArray);
            //console.log(filteredData);


        } catch(err){
            //console.error(err);
        }
    }

    function isDateBetween(startDate, endDate, checkDate) {
        const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        const check = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate());
      
        return (check.getTime() >= start.getTime()) && (check.getTime() <= end.getTime());
    }
      

    const handleDateRangeSelect = (update) => {

        if (update[1] !== null) {
            let startDate = update[0];
            let endDate = update[1];
            let result = [];
            result = grades.filter((data) => {
                let check = isDateBetween(startDate, endDate, new Date(data.createdDate) );      
                if(check) {
                    return data.createdDate.search(data.createdDate) !== -1;
                }
            });
            setFilteredData(result);
        } else {
            setFilteredData(grades);
        }

    }

    //Tutorial step functions

    const [ currentStep, setCurrentStep] = useState(0);
    const [ totalSteps, setTotalSteps ] = useState(5);
    const [ stepBtnNextActiveClass, setStepBtnNextActiveClass ] = useState('');
    const [ stepBtnPrevActiveClass, setStepBtnPrevActiveClass ] = useState('disabled');

    const addClassButtonRef = useRef(null);

    const handleNextStep = () => {
        if(currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
            setStepBtnPrevActiveClass('');
        }

        if(currentStep === 1 && viewFirst.current === 'active' ) {
            addClassButtonRef.current.click();
        }

        if(currentStep === 4 ) {
            /*setTutorialMode(false);
            setCurrentStep(1);*/
            setStepBtnNextActiveClass('disabled');
        }
    };
  
    const handlePreviousStep = () => {
        if(currentStep === 2 || currentStep === 1){
            setStepBtnPrevActiveClass('disabled');
        }
        if(currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }

        if(currentStep === 2 && viewSecondary.current === 'active' ) {
            addClassButtonRef.current.click();
            setCurrentStep(1);
            setStepBtnPrevActiveClass('disabled');
        }

        if(currentStep === 5 ) {
            /*setTutorialMode(false);
            setCurrentStep(1);*/
            setStepBtnNextActiveClass('');
        }
    };

    const shutDownTutorial = () => {
        setTutorialMode(false);
        setCurrentStep(0);
    }

    const returnTutorial = () => {
        if(tutorialMode){
            if(currentStep === 0) {
                setCurrentStep(1);
            }
            return(
            <> 
                <div data-highlight="tutorial-active" className="peili-tutorial-close-btn tutorial-active" onClick={shutDownTutorial}><span className="peili-icon cross"></span></div>
                {
                    <StepButton activeClassPrev={stepBtnPrevActiveClass} activeClassNext={stepBtnNextActiveClass} currentStep={currentStep} totalSteps={totalSteps} onNextStep={handleNextStep} onPreviousStep={handlePreviousStep} ></StepButton>
                }   

                <div className="bg-mask"></div> 
            </>
            )
        }
    }

    //const [ loading, setLoading ] = useState('<div className="lds-ring"><div></div><div></div><div></div><div></div></div>');

    return (
        <>
        <div>
            {returnTutorial()}

            <div className="peili-views-wrapper">
                
                <section useref={viewFirst} className={'peili-content-wrapper view-1 ' + viewFirst.current}>
                    
                    <h1>{t('LUOKAT')} <span className='info'>{t('Lisää uusi luokka tai muokkaa aiemmin luotuja')}</span></h1> 
                    <div className='tools-wrapper'>
                        <input type="search" placeholder={t('Etsi...')} onChange={(e) =>handleSearch(e)}></input>
                        <button ref={addClassButtonRef} data-highlight={currentStep === 1 ? 'tutorial-active' : ''} onClick={() => openAddGradeForm() }>{t('LISÄÄ LUOKKA')}</button>
                        <span data-highlight={currentStep === 1 ? 'tutorial-active' : ''} className="tutorial-tooltip tutorial-add-class">
                            {t('Lisää luokka painamalla nappia')}.
                        </span>
                        { /*<div className='peili-date-wrapper'>
                            <DatePicker
                                locale={currentLanguage}
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    setDateRange(update);
                                    handleDateRangeSelect(update);
                                }}
                                isClearable={true}
                                placeholderText={t('Päivämäärä...')} 
                            />
                            </div> */}
                    </div>   
                    {grades?.length
                        ? (
                            <GradesTable data={filteredData} rowsPerPage={4} openEditGradeForm={openEditGradeForm} setInitState={setInit}/>
                        ) : ring
                    }
                </section>

                <section ref={viewSecondary} className={'peili-content-wrapper view-2 ' + viewSecondary.current}>
                    <div className="top-bar">
                        <h1>{t('LISÄÄ LUOKKA')}</h1> 
                        <h2>{' '}</h2> 
                        <button className="back-button" onClick={openAddGradeForm}>{t('TAKAISIN')}</button>
                    </div>

                    <div className="content">
                        <AddGradeForm currentStep={currentStep} setCurrentStep={setCurrentStep} setTutorialMode={setTutorialMode} goBack={goBack} setInitState={setInit} removeAllRows={removeAllRows}/>
                    </div>
                    
                </section>
                <section ref={viewThird} className={'peili-content-wrapper view-3 ' + viewThird.current}>
                    <div className="top-bar"> 
                        <h1>{t('MUOKKAA LUOKKAA')}</h1>
                        <h2>{currentGrade.name ? currentGrade.name + ' (' + currentGrade.studentCount + ' ' + t('oppilasta') + ')': '-' }</h2>
                        <button className="back-button" onClick={openAddGradeForm}>{t('TAKAISIN')}</button>
                    </div>

                    <div className="content"> 
                        <EditGradeForm goBack={goBack} currentGrade={currentGrade.name} teacherContact={currentGrade.teacherContact} removeAllRows={removeAllRows} setInitState={setInit} />
                    </div>
                    
                </section>
            </div>
        </div>
        </> 
    )
}

export default Grades;