import { useTranslation } from 'react-i18next';
 
function StepButton(props) {

    //const [ activeClass, setActiveClass ] = useState('');

    const { t, i18n } = useTranslation();

    return (
        <>

        <div data-highlight="tutorial-active" className="peili-step-buttons-container tutorial-active"> 
            <div className={"step-btn-left " + props.activeClassPrev }  onClick={props.onPreviousStep}><span className="icon caret-left"></span></div>
            <p className="text">{t('VAIHE')} {props.currentStep}/{props.totalSteps}</p>
            <div className={"step-btn-right " + props.activeClassNext } onClick={props.onNextStep}><span className="icon caret-right"></span></div>
        </div>
        </>
    );
}

export default StepButton;