import React, { useState, useEffect } from "react";

import useTable from "../../hooks/useTable";
import TableFooter from "./TableFooter";
import { useTranslation } from 'react-i18next';

const GradesTable = (props) => {

    const { t } = useTranslation();

    //console.log(props.data);

    //console.log(data);
 
    const [page, setPage] = useState(1);
    const [ rowsPerPage, setRowsPerPage ] = useState(10);
    const { slice, range } = useTable(props.data, page, rowsPerPage);
    const [ sortOrder, setSortOrder] = useState('');
    const [ sortingData, setSortingData ] = useState('createdDate');

    useEffect(() => {
        props.setInitState(true);
    }, [rowsPerPage]);
    
    const sortedData = props.data.sort((a, b) => {

        if (a[sortingData] == null ) {
            a[sortingData] = '';
        }

        if (b[sortingData] == null ) {
            b[sortingData] = '';
        }

        if (sortOrder === "asc") {
            return a[sortingData].localeCompare(b[sortingData]);
        } else {
            return b[sortingData].localeCompare(a[sortingData]);
        }
    });

    const indexOfLastItem = page * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);


    const handleSort = (e, dataset) => {
        let sortOrderVar = sortOrder;
        
        if( !e.target.classList.contains('active')){
            sortOrderVar = '';
            setSortOrder('');
        }

        let tableHeaders = document.querySelectorAll('.table-header');

        for(let i = 0; i < tableHeaders.length; i++){
            tableHeaders[i].classList.remove('active');
        }
        
        e.target.classList.add('active');

        if(dataset !== null) {
            setSortingData(dataset);
        }
        if (sortOrderVar === "") {
            setSortOrder("desc"); 
        } else if(sortOrderVar === "desc") {
            setSortOrder("asc"); 
        } else {
            setSortOrder('');
            setSortingData('createdDate'); // <------- TESTI 
        }
    };

    Date.prototype.addHours = function(h) {
        this.setTime(this.getTime() + (h*60*60*1000));
        return this;
    }
    

    return (
    <>
        <table className="peili-table peili-grades-table">
            <thead>
                <tr>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'name') }}>{t('Luokka')} <i className={`sort-icon ${sortOrder}`}></i> </th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'studentCount') }}>{t('Oppilaiden määrä')} <i className={`sort-icon ${sortOrder}`}></i> </th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'createdDate') }}>{t('Luotu')} <i className={`sort-icon ${sortOrder}`}></i> </th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'modifiedDate') }}>{t('Muokattu')} <i className={`sort-icon ${sortOrder}`}></i> </th>
                </tr>
            </thead>
            <tbody>
            
            { currentItems.map((org, i) =>  
                <tr className={`organisation-table-element`} key={"class-" + i} onClick={() => { props.openEditGradeForm(org) } }>
                    <td >{org?.name}</td>
                    <td >{org?.studentCount}</td>
                    <td key={"class-date-" + i}>{
                        new Date(org?.createdDate).addHours(3).toLocaleString()
                    }</td>
                    <td key={i}>{
                        new Date(org?.modifiedDate).addHours(3).toLocaleString()
                    }</td>
                </tr>
            )}
            </tbody>
        </table>
        <TableFooter range={range} slice={slice} setPage={setPage} page={page} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage}/>
    </>
    );
};

export default GradesTable;