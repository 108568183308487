import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';

const AeduGradesTable = (props) => {

    const { t } = useTranslation();
    const [ activeBtn, setActiveBtn ] = useState('');
    const [ activeSelections, setActiveSelections ] = useState([]);
    const [ selectionsArray, setSelectionsArray ] = useState([]);

    const [ sortOrder, setSortOrder] = useState('');
    const [ sortingData, setSortingData ] = useState('createdDate');

    const sortedData = props.data.sort((a, b) => {

        if (a[sortingData] == null ) {
            a[sortingData] = '';
        }

        if (b[sortingData] == null ) {
            b[sortingData] = '';
        }

        if (sortOrder === "asc") {
            return a[sortingData].localeCompare(b[sortingData]);
        } else {
            return b[sortingData].localeCompare(a[sortingData]);
        }
    });

    //console.log(data);

    useEffect(() => {
        if(props.tableEmpty === true) {
            setActiveSelections([]);
            setSelectionsArray([]);
            setActiveBtn('');
        }

    }, [props.tableEmpty]);

    useEffect(() => {
        if (selectionsArray.length > 0) {
            setActiveBtn('active');
        } else {
            setActiveBtn('');
        }
    }, [selectionsArray]);

    const selectMultipleClasses = (org, i) => {
        
        const isSelected = activeSelections.includes(i);

        if (isSelected) { 
            setActiveSelections((prevSelected) =>
            prevSelected.filter((selected) => selected !== i)
            );

            setSelectionsArray((prevSelected) =>
            prevSelected.filter((selected) => selected !== org)
            );

        } else {

            setActiveSelections((prevSelected) => [...prevSelected, i]);

            setSelectionsArray((prevSelected) => [...prevSelected, org]);
        }
    }

    const returnContinueBtn = () => {

        let gradesList = [];

        if(props.currentGrade ) {
            JSON.parse(props.currentGrade).map((item) =>  
                gradesList.push(item.name)
            )
            return <button className={"continue-previous-session " + props.continueActive} onClick={() => props.continuePreviousSession()}>{t('JATKA EDELLISTÄ SESSIOTA') + ': ' + gradesList.join(', ')}</button>
        } else {
            return <></>;
        }
        
    }

    return (
    <>
    
        <table className="peili-table peili-grades-table">
            
            <thead>
            </thead>
            <tbody>
            {
                props.data.map((org, i) =>  
                org.name ? 
                <tr className={`organisation-table-element ${activeSelections.includes(i) ? 'selected' : ''}`} key={"aedu-class-" + i} onClick={() => { selectMultipleClasses(org, i) } }>
                    <td >{org?.name} <span className="student-count">{org.studentCount + ' ' + t('oppilasta')}</span></td>
                </tr>
                :
                <></>
            )}
            </tbody>
        </table>

        <div className="bottom-content">
            <button className={`peili-btn choose-classes ${activeBtn}`} onClick={ () => props.chooseClasses(selectionsArray) }>{t('ALOITA')}</button>

            { 
                            
                props.currentGrade ?
                
                    returnContinueBtn()
                :
                <></>
            }

        </div>
    </>
    );
};

export default AeduGradesTable;