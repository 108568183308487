
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';

const PUT_GRADE_URL = '/Patients';

const EditStudentPopup = (props) => {


    const { t } = useTranslation();

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const classNameRef = useRef();

    const errRef = useRef();

    const realNameRef = useRef();
    const ageRef = useRef();
    const genderRef = useRef();
    const handednessRef = useRef();
    const isS2StudentRef = useRef();

    const [rows, setRows] = useState([]);

    const addRow = () => {
        setRows([...rows, { realName: '', class: '', age: '', gender: '', handedness: '', language: language, isS2Student: '' }]);
    };

    const [gradeName, setGradeName] = useState('');

    const [errMsg, setErrMsg] = useState('');

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    /*const languageObject = {
        'Suomi': 'fi-FI',
        'Englanti': 'en-US',
        'Ruotsi': 'sv-SE',
        'Ranska': 'fr-FR',
        'Saksa': 'de-DE',
    } */

    const [age, setAge] = useState('');
    const [languageObject, setLanguageObject] = useState({
            'Suomi': 'fi-FI',
            'Englanti': 'en-US',
            'Ruotsi': 'sv-SE',
            'Ranska': 'fr-FR',
            'Saksa': 'de-DE',
        }); // replace with your initial languages
    const [filteredLanguageObject, setFilteredLanguageObject] = useState(languageObject);

    const language = Object.values(languageObject).includes(auth.language) ? auth.language : 'fi-FI';
    
    const [ fieldActive, setFieldActive ] = useState('');
    const userLanguage = auth.language;

    useEffect(() => {
        //setRows([]);
        setRows([{ realName: props.currentStudent.realName, class: props.currentStudent.class, age: props.currentStudent.age, gender: props.currentStudent.gender, handedness: props.currentStudent.handedness, language: props.currentStudent.language, isS2Student: props.currentStudent.isS2Student }]); 
        setGradeName(props.currentStudent.class);
        if(userLanguage === 'fi-FI' || userLanguage === 'sv-SE' || userLanguage === ''){
            setFieldActive('');
        } else {
            setFieldActive('hidden');
        }
        //console.log(props.currentStudent);
    }, [props.removeAllRows]);

    useEffect(() => {

        setTimeout(function(){
            const firstAgeInput = document.querySelector('input.peili-input-field[name="age"]');
        
            if (firstAgeInput && parseInt(firstAgeInput.value) >= 14) {
                //console.log(firstAgeInput.value);
                //console.log('yes');
                setFilteredLanguageObject({
                    'Suomi': languageObject['Suomi'],
                    'Englanti': languageObject['Englanti']
                });
                setFieldHidden('hidden');
            } else {
                setFilteredLanguageObject(languageObject);
                setFieldHidden('');
            } 
        }, 200);
        

    }, [props.currentGrade]); 

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value || ''; // If value is undefined or null, use an empty string instead
        setRows(updatedRows);
    };

   
    const validateAllFields = () => {
        let fields = document.getElementsByClassName('peili-input-field');
        for(let i = 0; i < fields.length; i++){
            if(!fields[i].classList.contains('hidden')) {
                if(fields[i].value.length === 0 ){
                    fields[i].classList.add('invalidated');
                } else {
                    fields[i].classList.remove('invalidated');
                }
            }
        }

        for(let i = 0; i < fields.length; i++){

            if(fields[i].classList.contains('invalidated')) {
                return false;
            }
        }

        return true;
    }

    function checkTableForDuplicateRealNames() {
        const input = document.querySelector('.peili-input-field[name="realName"]');
        const players = document.querySelectorAll('.aedu-players-container .item p');
        const inputValue = input.value.trim().toLowerCase();
        let hasDuplicates = false;

        players.forEach(player => {
            const playerValue = player.textContent.trim().toLowerCase();
            if (playerValue === inputValue && playerValue !== props.currentStudent.realName.toLowerCase()) {
                hasDuplicates = true;
                return;
            }
        });

        if (hasDuplicates) {
            input.classList.add('invalidated');
            setErrMsg(t('Kaksi tai useampaa samannimistä oppilasta. Vaihda nimi esim. lempinimeen.'));
            errRef.current.focus();
        }

        return hasDuplicates;
    }

    const handleSubmit = async (/*realName, age, gender, handedness, isS2Student*/) => {

        //let patientData = { realName, age, gender, handedness, isS2Student, 'class': props.currentGrade };

        const hasDuplicates = checkTableForDuplicateRealNames('editGradeTable');

        if (hasDuplicates) {
            return false;
        }
        
        if( validateAllFields() === false ) {
            setErrMsg(t('Täytä kaikki kentät'));
            errRef.current.focus();
            return false;
        }
        setErrMsg('');
        let studs = 1;
        setAjaxLoad('loading');

        //SAVE EDITED DATA
        
        for(const row of rows) {

            studs++;

            let patientData = { 'realName': row.realName, 'age': row.age, 'gender': row.gender, 'handedness': row.handedness, 'language': row.language, 'isS2Student': fieldActive === '' ? row.isS2Student : 'false', 'class': gradeName, 'nativeLanguage': 'finnish' }

            /*console.log(row.id);

            return false;*/
            
            //if(row.id === undefined) {
                try { 
                    const response = await axiosPrivate.put(PUT_GRADE_URL + '/' + props.currentStudent.id + '/PatientReadOnlyData', JSON.stringify({'patientReadOnlyData': JSON.stringify(patientData) })
                    ); 
                   
                    //props.setInitState(true);

                } catch(err) {
                    
                    if(!err?.response) {
                        setErrMsg('No server response');
                    } else if(err.response?.status === 400) {
                        setErrMsg(err.response?.data);
                    } else if (err.response?.status === 401) {
                        setErrMsg('401 Unauthorized');
                    } else {
                        setErrMsg('Request failed');
                    }
                    setAjaxLoad('');
                    errRef.current.focus();
                }
            /*} else {
                try { 
                    const response = await axiosPrivate.put(PUT_GRADE_URL + '/' + row.id + '/PatientReadOnlyData', JSON.stringify({'patientReadOnlyData': JSON.stringify(patientData) })
                    );

                      
                } catch(err) {

                    if(!err?.response) {
                        setErrMsg('No server response');
                    } else if(err.response?.status === 400) {
                        setErrMsg(err.response?.data);
                    } else if (err.response?.status === 401) {
                        setErrMsg('401 Unauthorized');
                    } else {
                        setErrMsg('Request failed');
                    }

                    errRef.current.focus();
                    setAjaxLoad('');
                } 
            }*/
        } 
 
                    
        if(studs >= rows.length) {
            setTimeout(() => {
                setAjaxLoad('');
                props.setPopupState('');
                props.setInitState(true);
            }, 500);
        }

    }

    const [fieldHidden, setFieldHidden ] = useState('');

    const handleAgeInput = (event) => {
        
        // Check if the event's target is the first age input field
        if (event.target.name === 'age') {
          const age = event.target.value;
         
          if (age.length >= 2 && age >= 14) {
            setFieldHidden('hidden');
            setFilteredLanguageObject({
                'Suomi': languageObject['Suomi'],
                'Englanti': languageObject['Englanti']
            });
          } else {
            setFieldHidden('');
            setFilteredLanguageObject(languageObject);
          }
        }
      };

    /*const [ currentStep, setCurrentStep ] = useState(props.currentState);

    const getHighlightClass = (targetStep) => {
        return props.currentStep === targetStep ? 'tutorial-active' : '';
    }; */

    return (
        <section className={"peili-form-container peili-popup-wrapper edit-class-popup " + ajaxLoad }>
            <h2>{t('MUOKKAA OPPILASTA')}</h2>  
            <div className='closer' onClick={() => props.setPopupState('')}> 
                <i className='icon close'></i> 
            </div> 

            <div className="button-wrapper" data-highlight={props.currentStep === 2 ? 'tutorial-active' : ''}>
                <label className="hidden" htmlFor="class-name">{t('Luokan nimi')} <span className='info'>({t('Esim. 3A')})</span></label> 
                <input className='class-name-input peili-add-input-field hidden' ref={classNameRef} value={gradeName} type="text" name="class-name"
                onChange={(e) => { setGradeName(e.target.value); } }>
                </input>
                <span data-highlight={props.currentStep === 2 ? 'tutorial-active' : ''} className="tutorial-tooltip tutorial-class-name">
                    {t('Lisää luokan nimi')}.
                </span>
            </div>
            <div className='table-wrapper'>
                
                <div id="editGradeTable">
                
                    {rows.map((row, index) => (
                    <div className="input-fields" key={'input-' + index}>
                        <div className="input-item">
                            <label htmlFor="realName" className='name-th'>{t('Nimi')} <span className='info'>({t('Etunimi ja sukunimen 2 ensimmäistä kirjainta, esim. Liisa Po')})</span></label>
                            <input
                            type="text"
                            name="realName"
                            className='peili-input-field'
                            value={row.realName}
                            onChange={(e) => handleInputChange(e, index)}
                            />
                        </div>
                        <div className="input-item">
                            <label htmlFor="age">{t('Ikä')}</label>
                            <input
                            type="number"
                            name="age"
                            className='peili-input-field'
                            value={row.age}
                            onChange={(e) => {
                                handleInputChange(e, index);
                                handleAgeInput(e);
                            }}
                            />
                        </div>
                        <div className={"input-item " + fieldHidden}>
                        <label htmlFor="gender">{t('Sukupuoli')}</label>
                            <select
                             className={'peili-select-field peili-input-field ' + fieldHidden}
                            name="gender"
                            value={row.gender}
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="male">{t('Mies')}</option>
                            <option value="female">{t('Nainen')}</option>
                            <option value="other">{t('Muu')}</option>
                            </select>
                        </div>
                        <div className={"input-item " + fieldHidden}>
                        <label htmlFor="handedness">{t('Kätisyys')}</label>
                            <select
                            className={'peili-select-field peili-input-field ' + fieldHidden}
                            name="handedness"
                            value={row.handedness}
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="right">{t('Oikea')}</option>
                            <option value="left">{t('Vasen')}</option>
                            <option value="unknown">{t('En tiedä')}</option>
                            </select>
                        </div>
                        <div className="input-item">
                        <label htmlFor="language">{t('Kieli')}</label>
                            <select
                            className='peili-select-field peili-input-field'
                            name="language"
                            value={row.language}
                            id="student-lan-selector"
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            {
                                Object.keys(filteredLanguageObject).map((key, i)=>{
                                return (
                                    <option key={"add-lan-" + i} value={languageObject[key]}>{t(key)}</option>
                                    )
                                })
                            }
                            </select>
                        </div>
                        <div className={ "input-item " + fieldActive }>
                        <label htmlFor="isS2Student">{t('S2-oppilas')}</label>
                            <select
                            className='peili-select-field peili-input-field'
                            name="isS2Student"
                            value={fieldActive === '' ? row.isS2Student : 'false' }
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="true">{t('Kyllä')}</option>
                            <option value="false">{t('Ei')}</option>
                            </select>
                        </div>
                    </div>
                    ))}
                </div>
                <div className='buttons-wrapper'>
                    <button className='button save-class' onClick={handleSubmit}>{t('TALLENNA')}</button>
                </div>
                </div>
 
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
}

export default EditStudentPopup