import React, { useState, useEffect } from "react";

import useTable from "../../hooks/useTable";
import TableFooter from "./TableFooter";

const OrganisationsTable = ({ data, openEditOrganisationForm, setInitState }) => {
    const [page, setPage] = useState(1);
    const [ rowsPerPage, setRowsPerPage ] = useState(10);
    const { slice, range } = useTable(data, page, rowsPerPage);
    const [ sortOrder, setSortOrder] = useState('');
    const [ sortingData, setSortingData ] = useState('createdDate');

    /*useEffect(() => {
        setInitState(true);
    }, [data]);*/

    useEffect(() => {
        setInitState(true);
    }, [rowsPerPage]);

    const sortedData = data.sort((a, b) => {

        if (a[sortingData] == null ) {
            a[sortingData] = '';
        }

        if (b[sortingData] == null ) {
            b[sortingData] = '';
        }


        if (sortOrder === "asc") {
            return a[sortingData].localeCompare(b[sortingData]);
        } else {
            return b[sortingData].localeCompare(a[sortingData]);
        }
    });
  
    const indexOfLastItem = page * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    const handleSort = (e, dataset) => {

        let sortOrderVar = sortOrder;
        
        if( !e.target.classList.contains('active')){
            sortOrderVar = '';
            setSortOrder('');
            
        }

        let tableHeaders = document.querySelectorAll('.table-header');

        for(let i = 0; i < tableHeaders.length; i++){
            tableHeaders[i].classList.remove('active');
        }
        
        e.target.classList.add('active');

        if(dataset !== null) {
            setSortingData(dataset);
        }
        if (sortOrderVar === "") {
            setSortOrder("desc"); 
        } else if(sortOrderVar === "desc") {
            setSortOrder("asc"); 
        } else {
            setSortOrder('');
            setSortingData('createdDate'); // <------- TESTI 
        }
    };

    Date.prototype.addHours = function(h) {
        this.setTime(this.getTime() + (h*60*60*1000));
        return this;
    }

    return (
    <>
        <table className="peili-table peili-organisations-table">
            <thead>
                <tr>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'name') }}>Name <i className={`sort-icon ${sortOrder}`}></i> </th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'createdDate') }}>Created <i className={`sort-icon ${sortOrder}`}></i> </th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'modifiedDate') }}>Last modified <i className={`sort-icon ${sortOrder}`}></i> </th>
                </tr> 
            </thead> 
            <tbody>
            {currentItems.map((org, i) =>  
                <tr key={"organisation-" + i} onClick={() => { openEditOrganisationForm(org) } }>
                    <td >{org?.name}</td>

                    <td key={"organisation-date" + i}>{
                        new Date(org?.createdDate).addHours(3).toLocaleString()
                    }</td>
                    <td key={i}>{
                        new Date(org?.modifiedDate).addHours(3).toLocaleString()
                    }</td>
                </tr>
            )}
            </tbody>
        </table>
        <TableFooter range={range} slice={slice} setPage={setPage} page={page} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage}/>
    </>
    );
};

export default OrganisationsTable;