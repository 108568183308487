
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Select from 'react-select';



const ADD_USER_URL = '/Therapists';

const AddUserForm = (props) => {

    const axiosPrivate = useAxiosPrivate();

    const userRef = useRef();
    const emailRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const lanCodeRef = useRef();
    const rolesRef = useRef();
    const organisationRef = useRef();
    const pwdRef = useRef();
    const errRef = useRef(); 

    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [languageCode, setLanCode] = useState('fi-FI');
    const [roles, setRoles] = useState([]);
    const [organisation, setOrganisation] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [formvalidation, setFormValidation] = useState(false);

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    const [pwdValidation, setPwdValidation ] = useState(false);
    const [userValidation, setUserValidation ] = useState(false);
    const [emailValidation, setEmailValidation ] = useState(false);

    const [organisations, setOrganisations] = useState();

    const [passwordType, setPasswordType] = useState("password");

    const previousOrgRef = useRef();

    const togglePassword = () =>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

    const rolesOptions = [
        { value: 'Admin', label: 'Admin' },
        { value: 'UserManager', label: 'UserManager' },
        { value: 'AssetManager', label: 'AssetManager' },
        { value: 'ExerciseManager', label: 'ExerciseManager' },
        { value: 'OrganisationManager', label: 'OrganisationManager' },
        { value: 'CanViewHidden', label: 'CanViewHidden' },
        { value: 'CanFullDeleteOwnPatient', label: 'CanFullDeleteOwnPatient' }
    ]
      

    const languageObject = {
        'fi-FI': 'fi-FI',
        'Swedish': 'sv-SE',
        'Norwegian': 'nb-NO',
        'Danish': 'da-DK',
        'Dutch': 'nl-NL',
        'English': 'en-US'
    }

    useEffect(() => {
        userRef.current.focus();
        setUser('');
        setPwd('');
        //document.getElementById("Add-user-form").reset();
        getOrganisations();
    }, []);
    
    useEffect(() => {
        if(props.newOrganisationName !== previousOrgRef.current && props.newOrganisationName !== '') {
            setOrganisation(props.newOrganisationName);
            props.setNewOrganisationName('');
            
        }
        previousOrgRef.current = props.newOrganisationName;
    }, [props.newOrganisationName]);

    useEffect(() => {     
        getOrganisations();
        props.setUpdateOrganisations(false);
    }, [props.updateOrganisations]);

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);



    function validateField(e){
        if(e.value.length === 0) {
            e.classList.add('invalitaded');
            setUserValidation(false);
        } else {
            e.classList.remove('invalitaded');
            setUserValidation(true);
        }
    }

    function validateEmail(e) {
        var regex = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        if(e.value.match(regex)) {
            e.classList.remove('invalitaded');
            setEmailValidation(true);
        } else {
            e.classList.add('invalitaded');
            setEmailValidation(false);
        }
    }

    function validatePassword(e){
        var regex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
        if(e.value.match(regex)) {
            e.classList.remove('invalitaded');
            setPwdValidation(true);

        } else {
            e.classList.add('invalitaded');
            setPwdValidation(false);
        }
    }

    const getOrganisations = async () => {
        try {
            const response = await axiosPrivate.get('/Organisations', {
                //signal: controller.signal
            });
            //console.log(response.data);

            setOrganisations(response.data); 

        } catch(err){
            //console.error(err);
        }
    }

    const openAddOrganisationForm = () => {
        props.setPopupState('add-organisation-form');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        validateField(userRef.current);
        validateEmail(emailRef.current);
        validatePassword(pwdRef.current);

        if(userValidation && emailValidation && pwdValidation){
            setFormValidation(true);
        } else {
            setFormValidation(false);
            setErrMsg('');
            return false;
        }

        setAjaxLoad('loading');

        try { 
            const response = await axiosPrivate.post(ADD_USER_URL, 
                JSON.stringify({'username': user, 'password': pwd, 'email': email, 'firstName': firstName, 'lastName': lastName, 'languageCode': languageCode, 'additionalRoles': roles, 'organisation': organisation})
            );

            setUser('');
            setPwd('');
            setEmail('');
            setFirstName('');
            setLastName('');
            setLanCode('');
            setRoles([]); 
            setOrganisation('');
            props.setPopupState('');
            props.setInitState(true);

            setTimeout(() => {
                setAjaxLoad('');
                setErrMsg('');
                props.goBack();
            }, 500);

        } catch(err) {

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg(err.response?.data);
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else {
                setErrMsg('Request failed');
            }

            errRef.current.focus();
            setAjaxLoad('');
        }

    } 

    const handleRolesChange = (selected) => {
        let result = selected.map(a => a.value);
        setRoles(result);
    };
 
    return (
        <section className={"peili-form-wrapper add-user " + ajaxLoad}>
            <h2>Add user</h2>  
            <div className='closer' onClick={() => props.setPopupState('')}>
                <i className='icon close'></i> 
            </div> 
            <form id="Add-user-form" className='add-user-form' onSubmit={handleSubmit}>
                <div className='item'>
                    <label htmlFor="username">Username</label>
                    <input 
                        type="text" 
                        id="username" 
                        ref={userRef} 
                        autoComplete="off"  
                        //onChange={(e) => { setUser (e.target.value) ; validateField(e.target)}}
                        onChange={(e) => { setUser(e.target.value); validateField(e.target)}}
                        value={user}
                    />
                </div>
                <div className='item'>
                    <label htmlFor="email">Email</label>
                    <input 
                        type="text" 
                        id="email" 
                        ref={emailRef} 
                        autoComplete="off" 
                        onChange={(e) => { setEmail(e.target.value); validateEmail(e.target) }}
                        value={email}
                    />
                </div>
                <div className='item'>
                    <label htmlFor="firstName">First name</label>
                    <input 
                        type="text" 
                        id="firstName" 
                        ref={firstNameRef} 
                        autoComplete="off" 
                        onChange={(e) => { setFirstName(e.target.value); }}
                        value={firstName}
                    />
                </div>
                <div className='item'>
                    <label htmlFor="lastName">Last name</label>
                    <input 
                        type="text" 
                        id="lastName" 
                        ref={lastNameRef} 
                        autoComplete="off" 
                        onChange={(e) => { setLastName(e.target.value); }}
                        value={lastName}
                    />
                </div>
                <div className='item full-width'>
                    <label htmlFor="languageCode">Language</label>
                    <select 
                        className='peili-select-field'
                        id="languageCode"
                        ref={lanCodeRef}
                        onChange={(e) => { setLanCode(e.target.value); }}
                    >
                        {
                            Object.keys(languageObject).map((key, i)=>{
                            return (
                                <option key={"user-add-lan-" + i} value={languageObject[key]}>{key}</option>
                                )
                            })     
  
                        }
                    </select>
                </div>
                <div className='item with-info'>
                    <p className="info">(Jätä koulukäyttäjälle roolit tyhjäksi)</p>
                    <Select 
                        id="additionalRoles" 
                        ref={rolesRef}
                        options={rolesOptions} 
                        isMulti
                        placeholder="User roles"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleRolesChange}  
                    />

                    { /* <label htmlFor="additionalRoles">Roles</label>
                    <input 
                        type="text" 
                        id="additionalRoles" 
                        ref={rolesRef} 
                        autoComplete="off" 
                        onChange={(e) => { setRoles(e.target.value); }}
                        value={roles}
                    /> */ }
                </div>
                <div className='item'>
                    <label htmlFor="organisation">Organisation</label> 
                    <select 
                        className='peili-select-field'
                        id="organisation" 
                        ref={organisationRef} 
                        value={organisation}
                        onChange={(e) => { setOrganisation(e.target.value); }}
                    >
                         <option value=""></option>
                        {
                        organisations ?
                        organisations.map((org, i) =>  
                            <option key={"user-organisation-" + i} value={org?.name}>{org?.name}</option>
                        ) :
                        <option></option>
                        } 
                    </select>
                    {/*<input 
                        type="text"  
                        id="organisation" 
                        ref={organisationRef} 
                        autoComplete="off"  
                        onChange={(e) => { setOrganisation (e.target.value); }}
                        value={organisation}
                    />*/}
                </div>
                <div className='item'>
                    <button type="button" onClick={openAddOrganisationForm} className='add-organisation peili-btn'>ADD NEW +</button>
                </div>
                <div className='item with-info'>
                    <p className='info'>(min. 8 merkkiä, vähintään yksi iso kirjain ja yksi nro)</p>
                    <label htmlFor="password">Password</label>
                    <input 
                        type={passwordType}
                        className="password"
                        ref={pwdRef}
                        onChange={(e) => { setPwd (e.target.value); validatePassword(e.target) }}
                        value={pwd}
                    />
                    <div className="show-password" onClick={togglePassword}><span className={passwordType === 'password' ? 'icon' : 'icon active' }></span></div>
                </div>

                <button>ADD USER</button>

            </form>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
}

export default AddUserForm