import React, { useEffect, useState, useContext } from 'react';
import * as signalR from '@microsoft/signalr';
import { MessageContext } from '../context/MessageContext';
import { RefreshContext } from '../context/RefreshContext';

function UTF8ToString(s) {
  return unescape(encodeURIComponent(s));
}

function stringToUTF8(s) {
  return decodeURIComponent(escape(s));
}

export const SignalRLib = {

  $vars: {
    connection: null,
    lastConnectionId: '',
    connectedCallback: null,
    disconnectedCallback: null,
    handlerCallback1: null,
    handlerCallback2: null,
    handlerCallback3: null,
    handlerCallback4: null,
    handlerCallback5: null,
    handlerCallback6: null,
    handlerCallback7: null,
    handlerCallback8: null,
    UTF8ToString: function (arg) {
      return UTF8ToString(arg);
    },
    stringToUTF8: function (s) {
      return stringToUTF8(s);
    },
    lengthBytesUTF8: function (str) { // returns the byte length of an utf8 string  
      return new Blob([str]).size;
    },
    // ... (Add other global variables as needed)
  },
  invokeCallback: function (args, callback) {
    var sig = 'v';
    var messages = [];
    for (var i = 0; i < args.length; i++) {
      var message = args[i];
      var buffer = SignalRLib.$vars.stringToUTF8(message);
      messages.push(buffer);
      sig += 'i';
    }
    if (typeof SignalRLib.$vars.Runtime === 'undefined') {
      //SignalRLib.$vars.dynCall(sig, callback, messages);
    } else {
      //SignalRLib.$vars.Runtime.dynCall(sig, callback, messages);
    }
  },
  InitJs: function (url) {

    url = SignalRLib.$vars.UTF8ToString(url);
    SignalRLib.$vars.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        withCredentials: false,
      })
      .build();

      /*SignalRLib.$vars.connection.on('joined', (message) => {
        console.log('Received joined:', message);
      });
      SignalRLib.$vars.connection.on('command', (message) => {
        //console.log(message);
    
        //console.log('Received command:', message);
      }); */
    /*SignalRLib.$vars.connection = new signalR.HubConnectionBuilder()
        .withUrl(url, {
          withCredentials: false,
        })
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();
    try { 
        await SignalRLib.$vars.connection.start();
        console.log('SignalR Connected.');
    } catch (err) {
        console.log(err);
    }*/
  },
  ConnectJs: function (connectedCallback, disconnectedCallback) {
    SignalRLib.$vars.connectedCallback = connectedCallback;
    SignalRLib.$vars.disconnectedCallback = disconnectedCallback;

    function initializeConnection() {

      
     /* SignalRLib.$vars.connection.start()
      
        .then(function () {
          
          SignalRLib.$vars.lastConnectionId = SignalRLib.$vars.connection.connectionId;
          SignalRLib.$vars.connection.onclose(function (err) {
            if (err) {
              console.error('Connection closed due to error: "' + err.toString() + '".');
            }
            SignalRLib.invokeCallback([SignalRLib.$vars.lastConnectionId], SignalRLib.$vars.disconnectedCallback);
          });
          SignalRLib.$vars.connection.onreconnecting(function (err) {
            console.log('Connection lost due to error: "' + err.toString() + '". Reconnecting.');
          });
          SignalRLib.$vars.connection.onreconnected(function (connectionId) {
            console.log('Connection reestablished. Connected with connectionId: "' + connectionId + '".');
            SignalRLib.$vars.lastConnectionId = connectionId;
            SignalRLib.invokeCallback([SignalRLib.$vars.lastConnectionId], SignalRLib.$vars.connectedCallback);
          });
          SignalRLib.invokeCallback([SignalRLib.$vars.lastConnectionId], SignalRLib.$vars.connectedCallback);
        })
        .catch(function (err) {
          if (err.toString().includes('before stop()')) {
            console.log('Calling stop and retrying...');
            SignalRLib.$vars.connection.stop();
            initializeConnection();
          } else {
            return console.error(err.toString());
          }
        }); */
        
        const start = () => {
          
          if (SignalRLib.$vars.connection.state === 'Disconnected') {
            SignalRLib.$vars.connection.start()

            .then(function () {
              
              SignalRLib.$vars.lastConnectionId = SignalRLib.$vars.connection.connectionId;
              SignalRLib.$vars.connection.onclose(function (err) {
                if (err) {
                  console.error('Connection closed due to error: "' + err.toString() + '".');
                }
                SignalRLib.invokeCallback([SignalRLib.$vars.lastConnectionId], SignalRLib.$vars.disconnectedCallback);
                setTimeout(start, 5000); // Try to reconnect after 5 seconds
              });
              SignalRLib.$vars.connection.onreconnecting(function (err) {
                console.log('Connection lost due to error: "' + err.toString() + '". Reconnecting.');
              });
              SignalRLib.$vars.connection.onreconnected(function (connectionId) {
                console.log('Connection reestablished. Connected with connectionId: "' + connectionId + '".');
                SignalRLib.$vars.lastConnectionId = connectionId;
                SignalRLib.invokeCallback([SignalRLib.$vars.lastConnectionId], SignalRLib.$vars.connectedCallback);
              });
            })
            .catch(function (err) {
              console.error('Error while establishing connection: "' + err.toString() + '".');
              setTimeout(start, 5000); // Try to reconnect after 5 seconds
            });
          } else {
            console.log('Connected');
          }
        };
      
        start();
 
    }
    
    initializeConnection();
  },
  StopJs: function () {
    if (SignalRLib.$vars.connection) {
      SignalRLib.$vars.connection.stop()
        .catch(function (err) {
          return console.error(err.toString());
        });
    }
  },
  InvokeJs: function (methodName, ...args) {
    methodName = SignalRLib.$vars.UTF8ToString(methodName);
    const stringArgs = args.map(arg => SignalRLib.$vars.UTF8ToString(arg));
    SignalRLib.$vars.connection.invoke(methodName, ...stringArgs)
      .catch(function (err) {
        return console.error(err.toString());
      });
  },
  OnJs: function (methodName, argCount, callback) {
    methodName = SignalRLib.$vars.UTF8ToString(methodName);
    argCount = Number.parseInt(SignalRLib.$vars.UTF8ToString(argCount));
    const handlerCallback = callback;
    const handlerArgs = [methodName];

    for (let i = 1; i <= argCount; i++) {
      handlerArgs.push(`arg${i}`);
    }

    SignalRLib.$vars.connection.on(methodName, function (...args) {
      const invokeArgs = handlerArgs.map((arg, index) => args[index]);
      SignalRLib.$vars.invokeCallback(invokeArgs, handlerCallback);
    });
  },

};



const SignalRComponent = ({ children }) => {

  const [messages, setMessages] = useState({ joined: null, command: null });
  const { refreshKey } = useContext(RefreshContext);

  useEffect(() => {

    let dev = false;

    setMessages({});
    
    // Initialize SignalR
    if(dev) { 
      SignalRLib.InitJs('https://peilivisionsignalrserverdev.azurewebsites.net/MainHub');
      
    } else {
      SignalRLib.InitJs('https://peilivisionsignalrserver.azurewebsites.net/MainHub'); 
    }
    
    //DEV SignalRLib.InitJs('https://peilivisionsignalrserverdev.azurewebsites.net/MainHub');
    // PROD SignalRLib.InitJs('https://peilivisionsignalrserver.azurewebsites.net/MainHub');

    // Connect to SignalR
    SignalRLib.ConnectJs(
      () => {
        console.log('Connected!');
        // Handle connected callback
      },
      () => {
        console.log('Disconnected!');
        // Handle disconnected callback
      }
    );

    SignalRLib.$vars.connection.on('joined', (message) => {
      //console.log('Received joined:', message);
      setMessages(prevMessages => ({ ...prevMessages, joined: message }));
    });


    SignalRLib.$vars.connection.on('command', (message) => {
      let msg = JSON.parse(message);
      // Check if the message has the eventTypeName and guid keys
      if (msg.EventTypeName && msg.Guid) {
        setMessages(prevMessages => ({
          ...prevMessages,
          [msg.EventTypeName]: {
            ...(prevMessages[msg.EventTypeName] || {}), // Spread the previous messages for this event, or an empty object if there are none
            [msg.Guid]: msg
          }
        }));
      }
    });



    // Cleanup on component unmount
    return () => {
      SignalRLib.StopJs();
    };
    
  }, [refreshKey]); // Empty dependency array ensures this effect runs once after initial render

// Define the removeMessage function
const removeMessage = (eventName, guid) => {
  setMessages(prevMessages => {
    // Copy the previous messages
    const updatedMessages = { ...prevMessages };

    // Check if the event name and GUID exist in the messages
    if (updatedMessages[eventName] && updatedMessages[eventName][guid]) {
      // Delete the message
      delete updatedMessages[eventName][guid];

      // If there are no more messages for this event, delete the event as well
      if (Object.keys(updatedMessages[eventName]).length === 0) {
        delete updatedMessages[eventName];
      }
    }

    // If there are no more events, remove the eventName
    if (Object.keys(updatedMessages[eventName] || {}).length === 0) {
      delete updatedMessages[eventName];
    }

    return updatedMessages;
  });
};

const markMessageConfirmed = (eventName, guid) => {
  //console.log(messages[eventName]);
  //if (messages[eventName] === undefined) return; // If the message does not exist, do nothing
  if( !messages[eventName][guid].messageConfirmed) {
    setMessages(prevMessages => {
      // Copy the previous messages
      const updatedMessages = { ...prevMessages };
  
      // Check if the event name and GUID exist in the messages
      if (updatedMessages[eventName] && updatedMessages[eventName][guid]) {
        // Set messageConfirmed to true
        updatedMessages[eventName][guid].messageConfirmed = true;
      }
      removeMessage(eventName, guid); // Remove the message from the messages
      return updatedMessages;
    });
  } //return; // If the message is already confirmed, do nothing

};


const clearMessages = () => {
  setMessages({}); // This will clear all the messages
};

return (
    <div>
      <MessageContext.Provider value={{messages, removeMessage, clearMessages, markMessageConfirmed}}>
        {children}
      </MessageContext.Provider>
    </div>
  );
};

export default SignalRComponent;
