
import { useState, useEffect, useRef } from 'react';
import UsersTable from '../components/tables/UsersTable';
import AddUserForm from '../components/users/AddUserForm';
import EditUserForm from '../components/users/EditUserForm';
import AddOrganisationForm from '../components/organisations/AddOrganisationForm';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Users = () => {

    const [ users, setUsers ] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [ popup, setPopup ] = useState('');
    const [ currentUser, setCurrentUser ] = useState({'additionalRoles': []});
    const [ filteredData, setFilteredData ] = useState(users);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [ updateOrganisations, setUpdateOrganisations ] = useState(false);
    const [ newOrganisationName, setNewOrganisationName ] = useState('');
    const [ organisations, setOrganisations ] = useState();

    const viewFirst = useRef('active');
    const viewSecondary = useRef('');
    const viewThird = useRef('');

    const [ editForm, setEditForm ] = useState();

    const [init, setInit] = useState(false);

    useEffect(() => {
        getUsers();
        getOrganisations();
        setInit(false);
        console.log('init');
    }, [init]);


    useEffect(() =>{
        //let isMounted = true;
        //const controller = new AbortController();

        //getUsers();

        /*return () => {
            isMounted = false;
            controller.abort();
        }*/  
    }, []);

    const goBack = () => {
        setInit(true);
        setCurrentUser({'additionalRoles': []});
        setEditForm('');
        viewFirst.current = 'active';
        viewSecondary.current = '';
        viewThird.current = '';
    }

    const openAddUserForm = () => {
        setInit(true);
        if(viewSecondary.current === 'active' || viewThird.current === 'active'){
            viewFirst.current = 'active';
            viewSecondary.current = '';
            viewThird.current = '';
        } else {
            viewFirst.current = '';
            viewSecondary.current = '';
            viewThird.current = 'active';
        }
        
    }
    const openEditUserForm = (user) => {
        //setCurrentUser(user);
        //setPopup('edit-user-form');
        window.scrollTo(0, 0);
        if(viewFirst.current === 'active'){
            setCurrentUser(user);
            setEditForm('edit');
            viewFirst.current = '';
            viewSecondary.current = 'active'; 
            viewThird.current = '';
        } else {
            setCurrentUser({'additionalRoles': []} );
            setEditForm('');
            viewFirst.current = 'active';
            viewSecondary.current = '';
            viewThird.current = '';
        }
    }

    const getOrganisations = async () => {
        try {
            const response = await axiosPrivate.get('/Organisations', {});

            setOrganisations(response.data);

        } catch(err){
            //console.error(err);
        }
    }

    const handleSearch = (e) => {
        let value = e.target.value.toLowerCase();
        let result = [];

        result = users.filter((data) => {
            if( data.username.toLowerCase().search(value) !== -1 ) {
                return data.username.toLowerCase().search(value) !== -1;
            } else if ( data.firstName !== null && data.firstName.toLowerCase().search(value) !== -1 ) {
                return data.firstName.toLowerCase().search(value) !== -1;
            } else if ( data.lastName !== null && data.lastName.toLowerCase().search(value) !== -1 ) {
                return data.lastName.toLowerCase().search(value) !== -1;
            } else if ( data.fullName !== null && data.fullName.toLowerCase().search(value) !== -1 ) {
                return data.fullName.toLowerCase().search(value) !== -1;
            }  else if ( data.email !== null && data.email.toLowerCase().search(value) != -1 ) {
                return data.email.toLowerCase().search(value) !== -1;
            } else if ( data.organisation !== null && data.organisation.toLowerCase().search(value) !== -1 ) {
                return data.organisation.toLowerCase().search(value) !== -1;
            } else {
                return data.username.toLowerCase().search(value) !== -1;
            }
            //return data.username.toLowerCase().search(value) != -1;
        });
        setFilteredData(result);
    }

    const getUsers = async () => {
        try {
            const response = await axiosPrivate.get('/Therapists', {
                //signal: controller.signal
            });
            //console.log(response.data);

            //isMounted && 

            response.data.forEach(function (element) {
                element.fullName = element.firstName + ' ' + element.lastName;
            });
              
            setUsers(response.data); 
            setFilteredData(response.data);

        } catch(err){
            //console.error(err);
        }
    }


    function isDateBetween(startDate, endDate, checkDate) {
        const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        const check = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate());
      
        return (check.getTime() >= start.getTime()) && (check.getTime() <= end.getTime());
    }
      

    const handleDateRangeSelect = (update) => {

        if (update[1] !== null) {
            let startDate = update[0];
            let endDate = update[1];
            let result = [];
            result = users.filter((data) => {
                let check = isDateBetween(startDate, endDate, new Date(data.createdDate) );      
                if(check) {
                    return data.createdDate.search(data.createdDate) !== -1;
                }
            });
            setFilteredData(result);
        } else {
            setFilteredData(users);
        }

    }


    return ( 
        <div className="peili-views-wrapper">

            <section useref={viewFirst} className={'peili-content-wrapper view-1 ' + viewFirst.current}>
                
                <h1>USERS</h1>
                <div className='tools-wrapper'>
                    <input type="search" placeholder='Search...' onChange={(e) => handleSearch(e)}></input>
                    <button onClick={() => openAddUserForm() }>Add user</button>
                    <div className='peili-date-wrapper'>
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                                handleDateRangeSelect(update);
                            }}
                            isClearable={true}
                            placeholderText='Filter by dates'
                        />
                    </div>

                </div> 
                {users?.length
                    ? (
                        <UsersTable data={filteredData} openEditUserForm={openEditUserForm} setInitState={setInit}/>
                    ) : <div className="lds-ring"><div></div><div></div><div></div><div></div></div> 
                }
            </section>

            <section ref={viewSecondary} className={'peili-content-wrapper view-2 ' + viewSecondary.current}>
                { 
                    ( popup === 'add-organisation-form' ) ?
                        <AddOrganisationForm organisations={organisations} updateOrganisations={setUpdateOrganisations} setPopupState={setPopup} setInitState={setInit} setNewOrganisationName={setNewOrganisationName}></AddOrganisationForm>
                    :
                    <></>
                }
                <div className="top-bar">
                    <h1>EDIT USER</h1> 
                    <h2>{currentUser.username ? currentUser.username : '-'}</h2> 
                    <button onClick={openEditUserForm}>Back</button>
                </div>


                <div className="content">
                    {
                     ( editForm === 'edit' ) ?
                     <EditUserForm organisations={organisations} goBack={goBack} setPopupState={setPopup} setInitState={setInit} currentUser={currentUser} updateOrganisations={updateOrganisations} setUpdateOrganisations={setUpdateOrganisations} newOrganisationName={newOrganisationName} setNewOrganisationName={setNewOrganisationName}></EditUserForm> 
                     :
                     <></>
                    }
                </div>

            </section>
            <section ref={viewThird} className={'peili-content-wrapper view-3 ' + viewThird.current}>
                { 
                    ( popup === 'add-organisation-form' ) ?
                        <AddOrganisationForm organisations={organisations} updateOrganisations={setUpdateOrganisations} setPopupState={setPopup} setInitState={setInit} setNewOrganisationName={setNewOrganisationName}></AddOrganisationForm>
                    :
                    <></>
                }
                <div className="top-bar">
                    <h1>ADD USER</h1> 
                    <button onClick={openEditUserForm}>Back</button>
                </div>

                <div className="content">  
                    <AddUserForm goBack={goBack} setPopupState={setPopup} setInitState={setInit} updateOrganisations={updateOrganisations} setUpdateOrganisations={setUpdateOrganisations} newOrganisationName={newOrganisationName} setNewOrganisationName={setNewOrganisationName}></AddUserForm>
                </div>

            </section>
        </div>
    ) 
}

export default Users;