
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useTranslation } from 'react-i18next';
import useAuth from '../../hooks/useAuth';
import SignalRComponent, { SignalRLib } from '../../api/signalRComponent';
import { sendStudentListUpdate } from '../../api/signalRClasses';

const ADD_GRADE_URL = '/Patients';

const AddStudentPopup = (props) => {


    const { t } = useTranslation();

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const classNameRef = useRef();

    const errRef = useRef(); 

    const [rows, setRows] = useState([]);

    

    const addRow = () => {
        setRows([...rows, { realName: '', class: '', age: '', gender: '', handedness: '', language: language, isS2Student: '' }]);
    };

    const removeRow = (index) => {
        var answer = window.confirm(t('Haluatko varmasti poistaa rivin?'));

        if (!answer) {
            return false;
        }
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const [gradeName, setGradeName] = useState('');

    const [errMsg, setErrMsg] = useState('');

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    

    /*const languageObject = {
        'Suomi': 'fi-FI',
        'Englanti': 'en-US',
        'Ruotsi': 'sv-SE',
        'Ranska': 'fr-FR',
        'Saksa': 'de-DE',
    }*/


    const [age, setAge] = useState('');
    const [languageObject, setLanguageObject] = useState({
            'Suomi': 'fi-FI',
            'Englanti': 'en-US',
            'Ruotsi': 'sv-SE',
            'Ranska': 'fr-FR',
            'Saksa': 'de-DE', 
        }); // replace with your initial languages
    const [filteredLanguageObject, setFilteredLanguageObject] = useState(languageObject);

    const language = Object.values(languageObject).includes(auth.language) ? auth.language : 'fi-FI';

    const currentGrade = props.currentGrade[0]?.name;
    
    const [ fieldActive, setFieldActive ] = useState('');
    const userLanguage = auth.language;
     
    useEffect(() => {
        //setRows([]);
        setRows([{ realName: '', class: '', age: '', gender: '', handedness: '', language: language, isS2Student: '' }]); 
        setLanguageObject(languageObject);
        setGradeName('');
        if(userLanguage === 'fi-FI' || userLanguage === 'sv-SE' || userLanguage === ''){
            setFieldActive('');
        } else {
            setFieldActive('hidden');
        }
    }, [props.removeAllRows]);

    useEffect(() => {
        setGradeName(props.currentGrade[0]?.name);
    }, [props.currentGrade]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value || ''; // If value is undefined or null, use an empty string instead
        setRows(updatedRows);
    };


    const updateStudentList = (rows, newGrade, newId) => {
       return;
        let originalRows = props.rows;
        originalRows = [...props.rows, ...rows]; // Add the content of rows parameter inside props.rows object

        const studentsList = originalRows.map((row) => {
            return {
                id: row.id,
                className: row.class,
                name: row.realName,
                hasAlreadyPlayed: false,
                isActive: false,
                createdDate: row.createdDate,
                languageCode: row.language,
                loggedIn: false,
            };
        });

        //return;

        //console.log(originalRows);
        //let className =  studentsList.length > 0 ? studentsList[0].className : '';

        const studentListByClassName = studentsList.reduce((result, student, index) => {
            const className = student.className || gradeName;
            if (!result[className]) {
                result[className] = [];
            }

            if (!student.id && index === studentsList.length - 1) {
                student.id = newId;
                student.className = gradeName;
                //student.newlyCreated = true;
            }

            result[className].push(student);

            
            
            return result;
        }, {});


        let studentListUpdate = sendStudentListUpdate(
            //className,
            studentListByClassName
        );
        //console.log(studentsList);
        //console.log(studentListUpdate);

        return studentListUpdate;
        //SignalRLib.InvokeJs('SendCommand', props.generatedId, JSON.stringify(studentListUpdate));
        /*Object.keys(messages['Peili.Aedu.StudentListRequestEvent']).forEach((key) => {

        });*/
    }

    const saveStudentsData = () => {
        const data = [];
        rows.forEach((row) => {
          const { realName, age, gender, handedness, language, isS2Student } = row;
          data.push({ 'realName': realName, 'age': age, 'gender': gender, 'handedness': handedness, 'language': language, 'isS2Student': isS2Student });
          
          //handleSubmit(realName, age, gender, handedness, isS2Student);
        });
        setRows(data);
        return;
      };


      const validateAllFields = () => {
        let fields = document.getElementsByClassName('peili-add-input-field');
        for(let i = 0; i < fields.length; i++){
            if(!fields[i].classList.contains('hidden')) {
                if(fields[i].value.length === 0 ){
                    fields[i].classList.add('invalidated');
                } else {
                    fields[i].classList.remove('invalidated');
                }
            }
        }

        for(let i = 0; i < fields.length; i++){

            if(fields[i].classList.contains('invalidated')) {
                return false;
            }
        }

        return true;
    }

    function checkTableForDuplicateRealNames() {
        const input = document.querySelector('.peili-add-input-field[name="realName"]');
        const players = document.querySelectorAll('.aedu-players-container .item p');
        const inputValue = input.value.trim().toLowerCase();
        let hasDuplicates = false;

        players.forEach(player => {
            const playerValue = player.textContent.trim().toLowerCase();
            if (playerValue === inputValue) {
                hasDuplicates = true;
                return;
            }
        });

        if (hasDuplicates) {
            input.classList.add('invalidated');
            setErrMsg(t('Kaksi tai useampaa samannimistä oppilasta. Vaihda nimi esim. lempinimeen.'));
            errRef.current.focus();
        }

        return hasDuplicates;
    } 

    const handleSubmit = async (/*realName, age, gender, handedness, isS2Student*/) => {
        
        saveStudentsData();

        const hasDuplicates = checkTableForDuplicateRealNames('addGradeTable');

        if (hasDuplicates) {
            return false;
        }

        if( validateAllFields() === false ) {
            setErrMsg(t('Täytä kaikki kentät'));
            return false;
        }
        setErrMsg('');

        let studs = 1;
        setAjaxLoad('loading');

        //let patientData = { realName, age, gender, handedness, isS2Student, 'class': gradeName, 'nativeLanguage': 'finnish' };
        for(const row of rows) {
            let patientData = { 'realName': row.realName, 'age': row.age, 'gender': row.gender, 'handedness': row.handedness, 'language': row.language, 'isS2Student': fieldActive === '' ? row.isS2Student : 'false', 'class': gradeName, 'nativeLanguage': 'finnish', 'newlyCreated': true }

            try { 
                const response = await axiosPrivate.post(ADD_GRADE_URL, JSON.stringify({'patientReadOnlyData': JSON.stringify(patientData) })
                );
                
                studs++;
                if(studs >= rows.length) { 
                    setTimeout(() => {
                        setAjaxLoad('');
                        setErrMsg('');
                        props.setPopupState('');
                        props.setInitState(true);
                        //updateStudentList(rows, gradeName, response.data.id);
                    }, 500);
                }

    
            } catch(err) {
    
                if(!err?.response) {
                    setErrMsg('No server response');
                } else if(err.response?.status === 400) {
                    setErrMsg(err.response?.data);
                } else if (err.response?.status === 401) {
                    setErrMsg('401 Unauthorized');
                } else {
                    setErrMsg('Request failed');
                }
    
                errRef.current.focus();
                setAjaxLoad('');
            }
        }

    }

    const [fieldHidden, setFieldHidden ] = useState('');

    const handleAgeInput = (event) => {
        
        // Check if the event's target is the first age input field
        if (event.target.name === 'age') {
          const age = event.target.value;
          
          if (age.length >= 2 && age >= 14) {
            setFieldHidden('hidden');
            setFilteredLanguageObject({
                'Suomi': languageObject['Suomi'],
                'Englanti': languageObject['Englanti']
            });
          } else {
            setFieldHidden('');
            setFilteredLanguageObject(languageObject);
          }
        }
      };

    /*const [ currentStep, setCurrentStep ] = useState(props.currentState);

    const getHighlightClass = (targetStep) => {
        return props.currentStep === targetStep ? 'tutorial-active' : '';
    }; */

    return (
        <section className={"peili-form-container peili-popup-wrapper add-class-popup " + ajaxLoad }>
            <h2>{t('LISÄÄ OPPILAS')}</h2>  
            <div className='closer' onClick={() => props.setPopupState('')}> 
                <i className='icon close'></i> 
            </div> 

            <div className="button-wrapper" data-highlight={props.currentStep === 2 ? 'tutorial-active' : ''}>
                <label className='' htmlFor="class-name">{t('Luokan nimi')} <span className='info'>({t('Esim. 3A')})</span></label>  
                <input className='class-name-input peili-add-input-field' ref={classNameRef} value={gradeName} type="text" name="class-name"
                onChange={(e) => { setGradeName(e.target.value); } }>
                </input>
                <span data-highlight={props.currentStep === 2 ? 'tutorial-active' : ''} className="tutorial-tooltip tutorial-class-name">
                    {t('Lisää luokan nimi')}.
                </span>
            </div>
            <div className='table-wrapper'>
                
                <div id="addGradeTable">

                    {rows.map((row, index) => (
                        <div className="input-fields" key={'input-' + index}>
                            <div className="input-item">
                                <label htmlFor="realName" className='name-th'>{t('Nimi')} <span className='info'>({t('Etunimi ja sukunimen 2 ensimmäistä kirjainta, esim. Liisa Po')})</span></label>
                                <input
                                type="text"
                                name="realName"
                                className='peili-add-input-field'
                                value={row.realName}
                                onChange={(e) => handleInputChange(e, index)}
                                />
                            </div>
                            <div className="input-item">
                                <label htmlFor="age">{t('Ikä')}</label>
                                <input
                                type="number"
                                name="age"
                                className='peili-add-input-field'
                                value={row.age}
                                onChange={(e) => {
                                    handleInputChange(e, index);
                                    handleAgeInput(e);
                                }}
                                />
                            </div>
                            <div className={"input-item " + fieldHidden}>
                            <label htmlFor="gender">{t('Sukupuoli')}</label>
                                <select
                                className={'peili-select-field peili-add-input-field ' + fieldHidden}
                                name="gender"
                                value={row.gender}
                                onChange={(e) => handleInputChange(e, index)}
                                >
                                <option value="">-- {t('Valitse')} --</option>
                                <option value="male">{t('Mies')}</option>
                                <option value="female">{t('Nainen')}</option>
                                <option value="other">{t('Muu')}</option>
                                </select>
                            </div>
                            <div className={"input-item " + fieldHidden}>
                            <label htmlFor="handedness">{t('Kätisyys')}</label>
                                <select
                                className={'peili-select-field peili-add-input-field ' + fieldHidden}
                                name="handedness"
                                value={row.handedness}
                                onChange={(e) => handleInputChange(e, index)}
                                >
                                <option value="">-- {t('Valitse')} --</option>
                                <option value="right">{t('Oikea')}</option>
                                <option value="left">{t('Vasen')}</option>
                                <option value="unknown">{t('En tiedä')}</option>
                                </select>
                            </div>
                            <div className="input-item">
                            <label htmlFor="language">{t('Kieli')}</label>
                                <select
                                className='peili-select-field peili-add-input-field'
                                name="language"
                                value={row.language}
                                id="student-lan-selector"
                                onChange={(e) => handleInputChange(e, index)}
                                >
                                <option value="">-- {t('Valitse')} --</option>
                                {
                                    Object.keys(filteredLanguageObject).map((key, i)=>{
                                    return (
                                        <option key={"add-lan-" + i} value={languageObject[key]}>{t(key)}</option>
                                        )
                                    })
                                }
                                </select>
                            </div>
                            <div className={ "input-item " + fieldActive }>
                            <label htmlFor="isS2Student">{t('S2-oppilas')}</label>
                                <select
                                className='peili-select-field peili-add-input-field'
                                name="isS2Student"
                                value={fieldActive === '' ? row.isS2Student : 'false' }
                                onChange={(e) => handleInputChange(e, index)}
                                >
                                <option value="">-- {t('Valitse')} --</option>
                                <option value="true">{t('Kyllä')}</option>
                                <option value="false">{t('Ei')}</option>
                                </select>
                            </div>
                        </div>
                    ))} 
                </div>
                <div className='buttons-wrapper'>
                    <button className='button save-class' onClick={handleSubmit}>{t('TALLENNA')}</button>
                </div>
                </div>
 
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
}

export default AddStudentPopup