
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import {  NavLink, useLocation, useNavigate } from 'react-router-dom';

const PUT_GRADE_URL = '/Patients';

const EditGradeForm = (props) => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/aedu";

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const realNameRef = useRef();
    const ageRef = useRef();
    const genderRef = useRef();
    const handednessRef = useRef();
    const isS2StudentRef = useRef();

    const classNameRef = useRef();

    const errRef = useRef();
  
    const [rows, setRows] = useState([]);
    const [removedRows, setRemovedRows] = useState([]);

    const [ fieldActive, setFieldActive ] = useState('');

    /*const languageObject = {
        'Suomi': 'fi-FI',
        'Englanti': 'en-US',
        'Ruotsi': 'sv-SE',
        'Ranska': 'fr-FR',
        'Saksa': 'de-DE',
    }*/


    const [age, setAge] = useState('');
    const [languageObject, setLanguageObject] = useState({
            'Suomi': 'fi-FI',
            'Englanti': 'en-US',
            'Ruotsi': 'sv-SE',
            'Ranska': 'fr-FR',
            'Saksa': 'de-DE',
        }); // replace with your initial languages
    const [filteredLanguageObject, setFilteredLanguageObject] = useState(languageObject);

    const language = Object.values(languageObject).includes(auth.language) ? auth.language : 'fi-FI';

    const addRow = () => {
        setRows([...rows, { realName: '', class: '', age: '', gender: '', handedness: '', language: language, isS2Student: '' }]);
    };

    const removeRow = (index) => {
        var answer = window.confirm(t('Haluatko varmasti poistaa rivin?'));

        if (!answer) {
            return false;
        }
        const removedRow = rows[index];
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
        setRemovedRows([...removedRows, removedRow]);
    };
    

    const [gradeName, setGradeName] = useState('');
    const [teacherContact, setTeacherContact] = useState('');
    //const [patientData, setPatientData ] = useState('');

    const [errMsg, setErrMsg] = useState('');

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    const userLanguage = auth.language;

    useEffect(() => { 
        setErrMsg('');
        getGrades();
 
        setGradeName(props.currentGrade);
        setTeacherContact(props.teacherContact);
        if(userLanguage === 'fi-FI' || userLanguage === 'sv-SE' || userLanguage === ''){
            setFieldActive('');
        } else {
            setFieldActive('hidden');
        }
        

    }, [props.currentGrade]);

    useEffect(() => {

        setTimeout(function(){
            const firstAgeInput = document.querySelector('input.peili-input-field[name="age"]');
        
            if (firstAgeInput && parseInt(firstAgeInput.value) >= 14) {
                //console.log(firstAgeInput.value);
                //console.log('yes');
                setFieldHidden('hidden');
                setFilteredLanguageObject({
                    'Suomi': languageObject['Suomi'],
                    'Englanti': languageObject['Englanti']
                });
            } else {
                setFieldHidden('');
                setFilteredLanguageObject(languageObject);
            } 
        }, 200);
        

    }, [props.currentGrade]); 

    useEffect(() => {
        setRows([]);
        setRemovedRows([]);
        getGrades();
    }, [props.removeAllRows]); 

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value || ''; // If value is undefined or null, use an empty string instead
        setRows(updatedRows);
    };

    function extractRelevantData(input) {
        // Initialize a variable to hold the extracted data
        let extractedData;

        // Check if the input is an object and it is not null
        if (typeof input === 'object' && input !== null) {
            // Check if 'Data' key exists in the object
            
            if (input.hasOwnProperty('Data')) {
                //console.log(input.Data);
                // If 'Data' key exists, use the nested data
                let data = input.Data;
                delete data.$type;
                delete data.additionalInfo;
                delete data.setting_DailyLiving2TaskBlockOrdering;
                extractedData = input.Data;//data.Data;
               
            } else {
                // If 'Data' key does not exist, the object itself contains the data
                extractedData = input;
            }
        } else {
            console.error('Invalid input type. Expected an object.');
            return null;
        }
    
        // Return the extracted data
        return extractedData;
    }

    const getGrades = async () => {
        
        try {
            const response = await axiosPrivate.get('/Therapists/' + auth.id + '/Patients',  {
                //signal: controller.signal
            });
            //console.log(response.data); 

            let studentsArray = [];
            response.data.forEach((row) => {

                let newRow = JSON.parse(row.patientReadOnlyData);
                if (newRow === null ){
                    return;
                    //newRow = {};
                }
                //delete newRow.$type;

                let rowData = extractRelevantData(newRow);//JSON.parse(row.patientReadOnlyData);
               
                if ( rowData.class === props.currentGrade ) {

                    studentsArray.push( {'realName': rowData.realName, 'class': rowData.class, 'age': rowData.age, 'gender': rowData.gender, 'handedness': rowData.handedness, 'language': rowData.language, 'isS2Student': rowData.isS2Student, 'id': row.id, 'createdDate': row.createdDate });
                }

            });

            studentsArray.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));

            //console.log("Before sorting:", studentsArray);
            setRows(studentsArray);
            //console.log("After sorting:", studentsArray);

            //isMounted && 
            //setGrades(classesArray);
            //setFilteredData(classesArray);

        } catch(err){
            //console.error(err);
        }
    }


    const handleRemove = () => {

        var answer = window.confirm(t('Haluatko varmasti poistaa luokan'));

        if (!answer) {
            return false;
        }

        setAjaxLoad('loading');

            rows.forEach(async (row) => {
            
                if(row.id !== undefined) { 
                    try { 
                        const response = await axiosPrivate.delete(PUT_GRADE_URL + '/' + row.id );
                        setRemovedRows([]);

                        setTimeout(() => {
                            setAjaxLoad('');
                            setErrMsg('');
                            props.goBack();
                        }, 500);


                    } catch(err) {
                        
                        if(!err?.response) {
                            setErrMsg('No server response');
                        } else if(err.response?.status === 400) {
                            setErrMsg(err.response?.data);
                        } else if (err.response?.status === 401) {
                            setErrMsg('401 Unauthorized');
                        } else {
                            setErrMsg('Request failed');
                        }
                        setAjaxLoad('');
                        errRef.current.focus();
                    }
                }
            });
    }

    const validateAllFields = () => {

        let fields = document.getElementsByClassName('peili-input-field');
        for(let i = 0; i < fields.length; i++){
            if(!fields[i].classList.contains('hidden')) {
                if(fields[i].value.length === 0 ){
                    fields[i].classList.add('invalidated');
                } else {
                    fields[i].classList.remove('invalidated');
                }
            }
        }

        for(let i = 0; i < fields.length; i++){

            if(fields[i].classList.contains('invalidated')) {
                return false;
            }
        }

        return true;
    }

    function checkTableForDuplicateRealNames(tableId) {
        const table = document.getElementById(tableId);
        const inputs = table.querySelectorAll('td input[name="realName"]');
        const valueMap = new Map();
      
        let hasDuplicates = false;
      
        inputs.forEach(input => {
            const value = input.value.trim().toLowerCase();
            if (value) {
                if (valueMap.has(value)) {
                    input.classList.add('invalidated');
                    setErrMsg(t('Kaksi tai useampaa samannimistä oppilasta. Vaihda nimi esim. lempinimeen.'));
                    errRef.current.focus();
                    hasDuplicates = true;
                } else {
                    valueMap.set(value, true);
                    input.classList.remove('invalidated');
                }
            }
        });
      
        return hasDuplicates;
    }

    const handleSubmit = async (navToGame = false) => {

        //let patientData = { realName, age, gender, handedness, isS2Student, 'class': props.currentGrade };

        const hasDuplicates = checkTableForDuplicateRealNames('editGradeTable');

        if (hasDuplicates) {
            return false;
        }
        
        if( validateAllFields() === false ) {
            setErrMsg(t('Täytä kaikki kentät'));
            errRef.current.focus();
            return false;
        }
        setErrMsg('');
        let studs = 1;
        setAjaxLoad('loading');

        //REMOVE IF TO REMOVE

        if(removedRows.length > 0) {
            
            removedRows.forEach(async (row) => {
               
                if(row.id !== undefined) { 
                    try { 
                        const response = await axiosPrivate.delete(PUT_GRADE_URL + '/' + row.id );
                        setRemovedRows([]);
                        //props.setInitState(true);

                    } catch(err) {
                        
                        if(!err?.response) {
                            setErrMsg('No server response');
                        } else if(err.response?.status === 400) {
                            setErrMsg(err.response?.data);
                        } else if (err.response?.status === 401) {
                            setErrMsg('401 Unauthorized');
                        } else {
                            setErrMsg('Request failed');
                        }
                        setAjaxLoad('');
                        errRef.current.focus();
                    }
                }
            });
        }

        //SAVE EDITED DATA
        
        for(const row of rows) {

            studs++;

            let patientData = { 'realName': row.realName, 'age': row.age, 'gender': row.gender, 'handedness': row.handedness, 'language': row.language, 'isS2Student': fieldActive === '' ? row.isS2Student : 'false', 'class': gradeName, 'nativeLanguage': 'finnish', 'email': teacherContact }
            
            if(row.id === undefined) {
                try { 
                    const response = await axiosPrivate.post(PUT_GRADE_URL, JSON.stringify({'patientReadOnlyData': JSON.stringify(patientData) })
                    ); 
                   
                    //props.setInitState(true);

                } catch(err) {
                    
                    if(!err?.response) {
                        setErrMsg('No server response');
                    } else if(err.response?.status === 400) {
                        setErrMsg(err.response?.data);
                    } else if (err.response?.status === 401) {
                        setErrMsg('401 Unauthorized');
                    } else {
                        setErrMsg('Request failed');
                    }
                    setAjaxLoad('');
                    errRef.current.focus();
                }
            } else {
                try { 
                    const response = await axiosPrivate.put(PUT_GRADE_URL + '/' + row.id + '/PatientReadOnlyData', JSON.stringify({'patientReadOnlyData': JSON.stringify(patientData) })
                    );

                      
                } catch(err) {

                    if(!err?.response) {
                        setErrMsg('No server response');
                    } else if(err.response?.status === 400) {
                        setErrMsg(err.response?.data);
                    } else if (err.response?.status === 401) {
                        setErrMsg('401 Unauthorized');
                    } else {
                        setErrMsg('Request failed');
                    }

                    errRef.current.focus();
                    setAjaxLoad('');
                } 
            }
        } 
 
                    
        if(studs >= rows.length) {
            setTimeout(() => {
                setAjaxLoad('');
                if(navToGame === true) {
                    navigate(from, { replace: true });
                }
                props.goBack();
                props.setInitState(true);
            }, 500);
        }

    } 

    const [fieldHidden, setFieldHidden ] = useState('');

    const handleAgeInput = (event) => {
        // Check if the event's target is the first age input field
       
        if (event.target.name === 'age' && event.target.dataset.index === '0') {
          const age = event.target.value;
          if (age.length >= 2 && age >= 14) {
            setFieldHidden('hidden');
            setFilteredLanguageObject({
                'Suomi': languageObject['Suomi'],
                'Englanti': languageObject['Englanti']
            });
          } else {
            setFieldHidden('');
            setFilteredLanguageObject(languageObject);
          }
        }
      }; 

    return (
        <section className={"peili-form-container edit-class " + ajaxLoad}> 
            <div className="button-wrapper">

                <label htmlFor="class-name">{t('Luokan nimi')} <span className='info'>({t('Esim. 3A')})</span></label> 
                <input className='class-name-input peili-input-field' ref={classNameRef} value={gradeName} type="text" name="class-name"
                placeHolder={t('Esim.') + ' 3A'}
                onChange={(e) => { setGradeName(e.target.value); } }>
                </input>

                <label htmlFor="teacher-contact">{t('Opettajan sähköposti')} <span className='info'>({t('Tulosraporttien toimittamista varten')})</span></label> 
                <input className='teacher-contact-input peili-input-field' value={teacherContact} type="text" name="teacher-contact"
                placeHolder={'email'}
                onChange={(e) => { setTeacherContact(e.target.value); } }>
                </input>
            </div> 
            <div>
                <table id="editGradeTable">
                    <thead>
                    <tr>
                        <th></th>
                        <th className='name-th'>{t('Nimi')} <span className='info'>({t('Etunimi ja sukunimen 2 ensimmäistä kirjainta, esim. Liisa Po')})</span></th>
                        <th>{t('Ikä')}</th>
                        <th className={fieldHidden}>{t('Sukupuoli')}</th>
                        <th className={fieldHidden} >{t('Kätisyys')}</th>
                        <th>{t('Kieli')}<span className='info'>({t('Kieli, jolla oppilas pelaa pelin')})</span></th>
                        <th className={fieldActive}>{t('S2-oppilas')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={"edit-" + index}>
                        <td>{index + 1}.</td>
                        <td>
                            <input
                            className='peili-input-field'
                            type="text"
                            name="realName"
                            ref={realNameRef}
                            value={row.realName}
                            placeHolder={t('Esim.') + ' Liisa Po'}
                            onChange={(e) => handleInputChange(e, index)}
                            />
                        </td>
                        <td>
                            <input
                            className='peili-input-field'
                            type="number"
                            name="age"
                            ref={ageRef}
                            value={row.age}
                            placeHolder={t("Ikä")}
                            data-index={index}
                            onChange={(e) => {
                                handleInputChange(e, index);
                                handleAgeInput(e);
                            }}
                            />
                        </td>
                        <td className={fieldHidden}>
                            <select
                            className={'peili-select-field peili-input-field ' + fieldHidden}
                            name="gender"
                            ref={genderRef}
                            value={row.gender}
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="male">{t('Mies')}</option>
                            <option value="female">{t('Nainen')}</option>
                            <option value="other">{t('Muu')}</option>
                            </select>
                        </td>
                        <td className={fieldHidden}>
                            <select
                            className={'peili-select-field peili-input-field ' + fieldHidden}
                            name="handedness"
                            ref={handednessRef}
                            value={row.handedness}
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="right">{t('Oikea')}</option>
                            <option value="left">{t('Vasen')}</option>
                            <option value="unknown">{t('En tiedä')}</option>
                            </select>
                        </td>
                        <td>
                            <select
                            className='peili-select-field peili-input-field'
                            name="language"
                            value={row.language}
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            {
                                Object.keys(filteredLanguageObject).map((key, i)=>{
                                return (
                                    <option key={"edit-lan-" + i} value={languageObject[key]}>{t(key)}</option>
                                    )
                                })
                            }
                            </select>
                        </td>
                        <td>
                            <select
                            className={ 'peili-select-field peili-input-field ' + fieldActive}
                            name="isS2Student"
                            ref={isS2StudentRef}
                            value={fieldActive === '' ? row.isS2Student : 'false' }
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="true">{t('Kyllä')}</option>
                            <option value="false">{t('Ei')}</option>
                            </select>
                        </td> 
                        <td>
                            <button className='button remove-row' onClick={() => removeRow(index)}>{t('Poista rivi')}</button>
                        </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button className='button add-row' onClick={addRow}>{t('LISÄÄ RIVI')} +</button>

                <div className="buttons-wrapper">   
                    <button className='button green save-class' onClick={handleSubmit}>{t('TALLENNA')}</button> 
                    <button className='button secondary purple save-class' onClick={() => handleSubmit(true)}>{t('SIIRRY PELIIN')}</button>
                    <button className='button remove-class secondary' onClick={handleRemove}>{t('POISTA LUOKKA')}</button>
                </div>
                </div> 

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
}

export default EditGradeForm