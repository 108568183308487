import { Route, Routes, NavLink, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import RequireAuth from './RequireAuth';
import Layout from './Layout';
import Login from './Login';
import Missing from './Missing';
import Home from './pages/Home';
import Organisations from './pages/Organisations';
import Users from './pages/Users';
import Unauthorized from './pages/Unauthorized';
import Logout from './Logout';
import useAuth from './hooks/useAuth';
import Grades from './pages/Grades';
import LoginOrRedirect from './LoginOrRedirect';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Aedu from './pages/Aedu';
import SignalRComponent from './api/signalRComponent';
import { RefreshProvider } from './context/RefreshContext';
import homeIcon from './icons/home.svg';
import personIcon from './icons/users.svg';
import networkIcon from './icons/network.svg';
import personsIcon from './icons/persons.svg';
import playIcon from './icons/play.svg';
import loginIcon from './icons/login.svg';
import IdleTimeoutLogout from './IdleTimeoutLogout';
import Kbase from './pages/Kbase';

const ROLES = {
  'User': 'UserManager', 
  'Organisation': 'OrganisationManager'
}

function App() {  
  
  const { t } = useTranslation();
  useEffect(() => {
    // Get the query parameter "lang" from the URL
    const queryParams = new URLSearchParams(window.location.search);
    //const trimmedLanguage = auth.language.substring(0, 2);
    const selectedLanguage = queryParams.get('lang');

    //console.log(auth.language);
    
    const selectElement = document.querySelector('.peili-lan-nav');
    for (const option of selectElement.options) {
        // Check if the option's value matches the selectedLanguage
        if (option.value === selectedLanguage) {
          // Set the selected property of the matching option to true
            option.selected = true;

            const changeEvent = new Event('change', {
            bubbles: true, // Allow event to bubble up the DOM tree
            cancelable: false, // Event cannot be canceled
            // Exit the loop since we found the matching option
            });

            selectElement.dispatchEvent(changeEvent);
          break;
        }
      }
  }, []);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0; 
  }

  function putNav() {

    if (auth.roles === null ) {
      return <>
        <li className={location.pathname === "/" ? "current" : ""}><NavLink to="/"><img src={homeIcon} ></img>{t('TYÖPÖYTÄ')}</NavLink></li>
        <li className={location.pathname === "/classes" ? "current" : ""}><NavLink to="/classes" state={{ tutorialMode: false }}><img src={personsIcon} ></img>{t('LUOKAT')}</NavLink></li> 
        <li className={location.pathname === "/aedu" ? "current" : ""}><NavLink to="/aedu"><img src={playIcon} ></img>{t('PELAA')}</NavLink></li>
        <li><Logout minifiedNav={minifiedNav} /></li>
      </>
    } else {
      return <> 
        <li className={location.pathname === "/" ? "current" : ""}><NavLink to="/"><img src={homeIcon} ></img>{t('TYÖPÖYTÄ')}</NavLink></li>
        <li className={location.pathname === "/user" ? "current" : ""}><NavLink to="/user"><img src={personIcon} ></img>{t('KÄYTTÄJÄT')}</NavLink></li>
        <li className={location.pathname === "/organisation" ? "current list-item" : "list-item"}><NavLink to="/organisation"><img src={networkIcon} ></img>{t('ORGANISAATIOT')}</NavLink></li>
        <li className={location.pathname === "/classes" ? "current" : ""}><NavLink to="/classes" state={{ tutorialMode: false }}><img src={personsIcon} ></img>{t('LUOKAT')}</NavLink></li> 
        <li className={location.pathname === "/aedu" ? "current" : ""}><NavLink to="/aedu"><img src={playIcon} ></img>{t('PELAA')}</NavLink></li>
        { /*<li className={location.pathname === "/kbase" ? "current" : ""}><NavLink to="/kbase"><img src={homeIcon} ></img>{t('Kbase')}</NavLink></li>*/ }
        <li><Logout minifiedNav={minifiedNav} setMinifiedNav={setMinifiedNav} /></li>
      </>
    }
  }


  function handleClick(lang) {
    i18next.changeLanguage(lang)
  }


  const { auth } = useAuth();
  const location = useLocation();

  const [reloadAedu, setReloadAedu] = useState(Date.now());

  const reloadComponent = () => {
    //console.log('reloaded');
    setReloadAedu(Date.now());
  };

  const [ minifiedNav, setMinifiedNav ] = useState('minified');
  const toggleNavSize = () => {
    if(minifiedNav === '') {
      setMinifiedNav('minified'); 
    } else {
      setMinifiedNav('');
    }
  }

  //console.log(isEmpty(auth));
  //console.log(auth);
  return (
    /*<div className="App"> 
      <Login/> 
    </div>*/

    <>

      <select onChange={(e)=>handleClick(e.target.value)} className={"peili-lan-nav " + minifiedNav}>
        <option value="fi">
          Fi
        </option>
        <option value="sv"> 
          Sv 
        </option>
        <option value="en"> 
          En  
        </option>
      </select>

    <nav className={"peili-main-nav " + minifiedNav}>
      <p className='logged-organisation'>{auth.organisation}</p>
      {
        isEmpty(auth) ?
        <></>
        :
        <div className="peili-icon minify" onClick={() => toggleNavSize()}></div>
      }
     
      <ul>
        
        { isEmpty(auth)
          ? <li><NavLink to="/login">{t('KIRJAUDU')}</NavLink></li> 
          : putNav()
        }

      </ul>
    </nav>
    <p className={"peili-portal-version " + minifiedNav}>version 1.3.3</p> 
    <section className="peili-section-wrapper">
        <IdleTimeoutLogout idleTimeoutInSeconds={1800} setMinifiedNav={setMinifiedNav} > {/* 1200 for 20 minutes */}
          <Routes>
            <Route path='/' element={<Layout />}>

              {/* public routes */}

              <Route path='login' element={<LoginOrRedirect location={location} setMinifiedNav={setMinifiedNav} />} />
    
              {/* protected routes */}
              
              <Route element={<RequireAuth isLoggedIn={auth.accessToken}/>}>
                <Route path="classes" element={<Grades />} />
                <Route path="aedu" element={
                  <RefreshProvider>
                    <SignalRComponent>
                      <Aedu reloadAedu={reloadComponent} key={reloadAedu} />
                    </SignalRComponent> 
                  </RefreshProvider>
                  } 
                  />
                </Route>
                { /* <Route path="kbase" element={<Kbase />} /> */}

              {/* AllManagers routes */}

              <Route element={<RequireAuth allowedRoles={[ROLES.Organisation, ROLES.User]}/>}>
                <Route path="/" element={<Home />} />
                
                
              </Route>
    
              {/* 
              <Route element={<RequireAuth allowedRoles={[]}/>}>
                <Route path="/" element={<Home />} />
              </Route>
              */}

              {/* UserManager routes */}

              <Route element={<RequireAuth allowedRoles={[ROLES.User]}/>}>
                <Route path="user" element={<Users />} />
              </Route>
    
              {/* OrganisationManger routes */}

              <Route element={<RequireAuth allowedRoles={[ROLES.Organisation]}/>}>
                <Route path="organisation" element={<Organisations />} />
              </Route>
        
              {/* catch all */}

              <Route path="*" element={<Missing />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
    
            </Route>
          {/* <Route path="/" element={<Login/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
          */} 
          </Routes>
      </IdleTimeoutLogout>
    </section>
    </> 
  );
}

export default App; 
