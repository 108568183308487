
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Select from 'react-select';


const EditUserForm = (props) => {
    
    const EDIT_USER_URL = `/Therapists/${props.currentUser.id}`;

    const axiosPrivate = useAxiosPrivate();

    const userRef = useRef();
    const emailRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const lanCodeRef = useRef();
    const rolesRef = useRef();
    const organisationRef = useRef();
    const pwdRef = useRef();
    const errRef = useRef(); 

    const [organisations, setOrganisations] = useState();
    //(props.currentUser);

    const [user, setUser] = useState(props.currentUser.username);
    const [email, setEmail] = useState(props.currentUser.email);
    const [firstName, setFirstName] = useState(props.currentUser.firstName);
    const [lastName, setLastName] = useState(props.currentUser.lastName);
    const [languageCode, setLanCode] = useState(props.currentUser.languageCode);
    const [roles, setRoles] = useState(props.currentUser.roles);
    const [organisation, setOrganisation] = useState(props.currentUser.organisation);
    const [pwd, setPwd] = useState(props.currentUser.pwd);
    const [errMsg, setErrMsg] = useState('');
    const [formvalidation, setFormValidation] = useState(false);

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    const [pwdValidation, setPwdValidation ] = useState(false);
    const [userValidation, setUserValidation ] = useState(false);
    const [emailValidation, setEmailValidation ] = useState(false);

    const previousOrgRef = useRef();

    useEffect(() => {     
        getOrganisations();
        props.setUpdateOrganisations(false);
    }, [props.updateOrganisations]);

    useEffect(() => {
        if(props.newOrganisationName !== previousOrgRef.current && props.newOrganisationName !== '') {
            setOrganisation(props.newOrganisationName);
            props.setNewOrganisationName('');
            
        }
        previousOrgRef.current = props.newOrganisationName;
    }, [props.newOrganisationName]);

    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () =>{ 
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }

    const languageObject = {
        'Finnish': 'fi-FI',
        'Swedish': 'sv-SE',
        'Norwegian': 'nb-NO',
        'Danish': 'da-DK',
        'Dutch': 'nl-NL',
        'English': 'en-US'
    }


    const rolesOptions = [
        { value: 'Admin', label: 'Admin' },
        { value: 'UserManager', label: 'UserManager' },
        { value: 'AssetManager', label: 'AssetManager' },
        { value: 'ExerciseManager', label: 'ExerciseManager' },
        { value: 'OrganisationManager', label: 'OrganisationManager' },
        { value: 'CanViewHidden', label: 'CanViewHidden' },
        { value: 'CanFullDeleteOwnPatient', label: 'CanFullDeleteOwnPatient' }
    ]

    const defaultRoles = () => {

        let defaultRoles = []; 

        for(let i = 0; i < props.currentUser.additionalRoles.length; i++){
            defaultRoles.push({ value: props.currentUser.additionalRoles[i], label: props.currentUser.additionalRoles[i]});
        }
        handleRolesChange(defaultRoles);
        return defaultRoles;

    }


    const openAddOrganisationForm = () => { 
        props.setPopupState('add-organisation-form');
    }

    useEffect(() => {
        userRef.current.focus();
        getOrganisations(); 
    }, []);
    

    /*useEffect(() => {
        setUser(props.currentUser.username);
        setEmail(props.currentUser.email); 
        setFirstName(props.currentUser.firstName);
        setLastName(props.currentUser.lastName);
        setLanCode(props.currentUser.languageCode); 
        setRoles(props.currentUser.additionalRoles);
        setOrganisation(props.currentUser.organisation);
        
    }, [props.currentUser]); */

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd]);



    function validateField(e){
        if(e.value.length === 0) {
            e.classList.add('invalitaded');
            setUserValidation(false);
        } else {
            e.classList.remove('invalitaded');
            setUserValidation(true);
        } 
    }

    function validateEmail(e) {
        var regex = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        if(e.value.match(regex)) {
            e.classList.remove('invalitaded');
            setEmailValidation(true);
        } else { 
            e.classList.add('invalitaded');
            setEmailValidation(false);
        } 
    }

    function validatePassword(e){ 
        var regex =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
        if(e.value.match(regex)) {
            e.classList.remove('invalitaded');
            setPwdValidation(true); 

        } else {
            e.classList.add('invalitaded');
            setPwdValidation(false);
        }
    }

    const getOrganisations = async () => {
        try {
            const response = await axiosPrivate.get('/Organisations', {
                //signal: controller.signal
            });
            //console.log(response.data);

            setOrganisations(response.data); 

        } catch(err){
            //console.error(err); 
        }
    }


    const deleteUser = async () => {

        var answer = window.confirm("Are you sure you want to delete this user?");

        if (!answer) {
            return false;
        }

        setAjaxLoad('loading');

        try {
            const response = await axiosPrivate.delete(EDIT_USER_URL, {
                //signal: controller.signal
            });
            //console.log(response.data);
            //console.log('user ' + props.currentUser.name + ' deleted');
            props.setPopupState('');
            props.setInitState(true);

            setTimeout(() => {
                setAjaxLoad('');
                setErrMsg('');
                props.goBack();
            }, 500);

        } catch(err){
            //console.error(err); 
            setAjaxLoad('');
        } 
    }

    const handleSubmit = async (e) => {
        e.preventDefault();


        /*if(!formvalidation) {
            setErrMsg('Password must contain 8 letters, one uppercase, lowercase and at least one number');
            errRef.current.focus();
            return false;
        } */

        validateField(userRef.current);
        validateEmail(emailRef.current);
        validatePassword(pwdRef.current);

        if(userValidation && emailValidation && pwdValidation){
            setFormValidation(true);
        } else {
            setFormValidation(false);
            //console.log('form not validated');
            //setErrMsg('Check all fields');
            //errRef.current.focus();
            return false;
        } 

        setErrMsg('');
        setAjaxLoad('loading');

        try { 
            const response = await axiosPrivate.put(EDIT_USER_URL, 
                JSON.stringify({'username': user, 'password': pwd, 'email': email, 'firstName': firstName, 'lastName': lastName, 'languageCode': languageCode, 'additionalRoles': roles, 'organisation': organisation})
            );

            setUser('');
            setPwd('');
            setEmail(''); 
            setFirstName('');
            setLastName('');
            setLanCode('');
            setRoles([]); 
            setOrganisation('');
            props.setPopupState('');
            props.setInitState(true);

            setTimeout(() => {
                setAjaxLoad('');
                setErrMsg('');
                props.goBack();
            }, 500);

        } catch(err) {
            
            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg(err.response?.data);
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else {
                setErrMsg('Request failed');
            }

            errRef.current.focus();
            setAjaxLoad('');
        }

    } 

    const handleRolesChange = (selected) => { 
        let result = selected.map(a => a.value);
        setRoles(result);
    };

    return (
        
        <section className={"peili-form-wrapper edit-user " + ajaxLoad}>
            
            { /*<div className='closer' onClick={() => props.setPopupState('')}>
                <i className='icon close'></i>  
                 </div>  */}
            <form className='add-user-form' onSubmit={handleSubmit}>
                <div className='item'>
                    <label htmlFor="username">Username</label>
                    <input 
                        type="text" 
                        id="username" 
                        ref={userRef} 
                        autoComplete="off"  
                        onChange={(e) => { setUser(e.target.value); validateField(e.target)}}
                        value={user}
                    />
                </div>
                <div className='item'>
                    <label htmlFor="email">Email</label>
                    <input 
                        type="text" 
                        id="email" 
                        ref={emailRef} 
                        autoComplete="off" 
                        onChange={(e) => { setEmail(e.target.value); validateEmail(e.target) }}
                        value={email}
                    />
                </div>
                <div className='item'>
                    <label htmlFor="firstName">First name</label>
                    <input 
                        type="text" 
                        id="firstName" 
                        ref={firstNameRef} 
                        autoComplete="off" 
                        onChange={(e) => { setFirstName(e.target.value); }}
                        value={firstName}
                    />
                </div>
                <div className='item'>
                    <label htmlFor="lastName">Last name</label>
                    <input 
                        type="text" 
                        id="lastName" 
                        ref={lastNameRef} 
                        autoComplete="off" 
                        onChange={(e) => { setLastName(e.target.value); }}
                        value={lastName}
                    />
                </div>
                <div className='item full-width'>
                    <label htmlFor="languageCode">Language</label>
                    <select 
                        className='peili-select-field'
                        id="languageCode"
                        ref={lanCodeRef}
                        value={languageCode}
                        onChange={(e) => { setLanCode(e.target.value); }}
                    >
                        {
                            Object.keys(languageObject).map((key, i)=>{
                            return (
                                <option key={"user-edit-" + i} value={languageObject[key]}>{key}</option>
                                )
                            })     
  
                        }
                    </select>
                </div>
                <div className='item with-info'>
                    <p className="info">(Jätä koulukäyttäjälle roolit tyhjäksi)</p>
                    
                    <Select
                        id="additionalRoles" 
                        ref={rolesRef}
                        options={rolesOptions} 
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleRolesChange}
                        defaultValue={defaultRoles}
                    />
                </div>
                <div className='item'>
                    <label htmlFor="organisation">Organisation</label>
                    <select 
                        className='peili-select-field'
                        id="organisation" 
                        ref={organisationRef} 
                        value={organisation}
                        onChange={(e) => { setOrganisation(e.target.value); }}
                    >
                         <option value=""></option>
                        {
                        organisations ?
                        organisations.map((org, i) =>  
                            <option key={"user-edit-organisation-" + i} value={org?.name}>{org?.name}</option>
                        ) :
                        <option></option>
                        }  
                    </select>
                </div>
                <div className='item'>
                    <button type="button" onClick={openAddOrganisationForm} className='add-organisation peili-btn'>ADD NEW +</button>
                </div> 
                <div className='item with-info'>
                    <p className='info'>(min. 8 merkkiä, vähintään yksi iso kirjain ja yksi nro)</p>
                    <label htmlFor="password">Password</label>
                    <input  
                        type={passwordType}
                        className="password"
                        ref={pwdRef}
                        onChange={(e) => { setPwd (e.target.value); validatePassword(e.target) }}
                        value={pwd} 
                    />
                    <div className="show-password" onClick={togglePassword}><span className={passwordType === 'password' ? 'icon' : 'icon active' }></span></div>
                </div> 
                <div className='btns-wrapper'>
                    <button type="submit">EDIT USER</button>
                    <button type="button" className="delete-btn" onClick={() => {deleteUser()}}>DELETE USER</button>
                </div>
 
            </form>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
}

export default EditUserForm