import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationFI from './locales/fi/translation.json';
import translationEN from './locales/en/translation.json';
import translationSV from './locales/sv/translation.json';


const resources = { 
    en: {
      translation: translationEN
    },
    fi: {
      translation: translationFI
    },
    sv: {
        translation: translationSV
    }
};

const fallbackLng = ['fi'];
const availableLanguages = ['fi', 'sv', 'en'];


const options = {
  // order and from where user language should be detected
  order: [ 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng', 
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  //caches: ['localStorage', 'cookie'],
  //excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  //cookieMinutes: 10,
  //cookieDomain: 'https://peiliportaldev.azurewebsites.net',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // only detect languages that are in the whitelist
  checkWhitelist: true
}

i18n
  .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step

  .use(LanguageDetector) // detect user language

  .use(initReactI18next) // pass the i18n instance to react-i18next.

  .init({
    fallbackLng, // if user computer language is not on the list of available languages, than we will be using the fallback language specified earlier
    debug: false, 
    whitelist: availableLanguages,
    detection: options,
 
    interpolation: {
      escapeValue: false
    },
    resources,
    lng: 'fi'
    /*backend: { 
        loadPath: '/locales/{{lng}}/translation.json',//'/locales/{{lng}}/translation.json', // Public URL path
    },*/
  });


  

export default i18n;