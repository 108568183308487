
import { useRef, useState, useEffect } from 'react';
//import { axiosPrivate } from '../../api/axios';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import {  NavLink, useLocation, useNavigate } from 'react-router-dom';


const ADD_GRADE_URL = '/Patients';

const AddGradeForm = (props) => {

    
    const { t } = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/aedu";

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const classNameRef = useRef();

    const errRef = useRef(); 

    const [rows, setRows] = useState([]);

    

    const addRow = () => {
        setRows([...rows, { realName: '', class: '', age: '', gender: '', handedness: '', language: language, isS2Student: '' }]);
    };

    const removeRow = (index) => {
        var answer = window.confirm(t('Haluatko varmasti poistaa rivin?'));

        if (!answer) {
            return false;
        }
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const [gradeName, setGradeName] = useState('');
    const [teacherContact, setTeacherContact] = useState('');

    const [errMsg, setErrMsg] = useState('');

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    const [ fieldActive, setFieldActive ] = useState('');

    /*const languageObject = {
        'Suomi': 'fi-FI',
        'Englanti': 'en-US',
        'Ruotsi': 'sv-SE',
        'Ranska': 'fr-FR',
        'Saksa': 'de-DE',
    }*/


    const [age, setAge] = useState('');
    const [languageObject, setLanguageObject] = useState({
            'Suomi': 'fi-FI',
            'Englanti': 'en-US',
            'Ruotsi': 'sv-SE',
            'Ranska': 'fr-FR',
            'Saksa': 'de-DE',
        }); // replace with your initial languages
    const [filteredLanguageObject, setFilteredLanguageObject] = useState(languageObject);

    const language = Object.values(languageObject).includes(auth.language) ? auth.language : 'fi-FI';

    const userLanguage = auth.language;
    //console.log(userLanguage);

    useEffect(() => {
        //setRows([]);
        setFieldHidden('');
        
        setRows([{ realName: '', class: '', age: '', gender: '', handedness: '', language: language, isS2Student: '' }]); 
        setGradeName('');
        if(userLanguage === 'fi-FI' || userLanguage === 'sv-SE' || userLanguage === ''){
            setFieldActive('');
        } else {
            setFieldActive('hidden');
        }
    }, [props.removeAllRows]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedRows = [...rows];
        updatedRows[index][name] = value || ''; // If value is undefined or null, use an empty string instead
        setRows(updatedRows);
    };

    const saveStudentsData = () => {
        const data = [];
        rows.forEach((row) => {
          const { realName, age, gender, handedness, language, isS2Student } = row;
          data.push({ 'realName': realName, 'age': age, 'gender': gender, 'handedness': handedness, 'language': language, 'isS2Student': isS2Student });
          
          //handleSubmit(realName, age, gender, handedness, isS2Student);
        });
        setRows(data);
        return;
      };


    const validateAllFields = () => {
        let fields = document.getElementsByClassName('peili-add-input-field');
        for(let i = 0; i < fields.length; i++){
            if(!fields[i].classList.contains('hidden')) {
                if(fields[i].value.length === 0 ){
                    fields[i].classList.add('invalidated');
                } else {
                    fields[i].classList.remove('invalidated');
                }
            }
        }

        for(let i = 0; i < fields.length; i++){

            if(fields[i].classList.contains('invalidated')) {
                return false;
            }
        }

        return true;
    }

    function checkTableForDuplicateRealNames(tableId) {
        const table = document.getElementById(tableId);
        const inputs = table.querySelectorAll('td input[name="realName"]');
        const valueMap = new Map();
      
        let hasDuplicates = false;
      
        inputs.forEach(input => {
            const value = input.value.trim().toLowerCase();
            if (value) { 
                if (valueMap.has(value)) { 
                    input.classList.add('invalidated');
                    setErrMsg(t('Kaksi tai useampaa samannimistä oppilasta. Vaihda nimi esim. lempinimeen.'));
                    errRef.current.focus();
                    hasDuplicates = true; 
                } else {
                    valueMap.set(value, true);
                }
            }
        });
      
        return hasDuplicates;
    }

    const handleSubmit = async (navToGame = false) => {

        saveStudentsData();

        const hasDuplicates = checkTableForDuplicateRealNames('addGradeTable');

        if (hasDuplicates) {
            return false;
        }

        if( validateAllFields() === false ) {
            setErrMsg(t('Täytä kaikki kentät'));
            return false;
        }

        setErrMsg('');

        let studs = 1;
        setAjaxLoad('loading');

        //let patientData = { realName, age, gender, handedness, isS2Student, 'class': gradeName, 'nativeLanguage': 'finnish' };
        for(const row of rows) {
            let patientData = { 'realName': row.realName, 'age': row.age, 'gender': row.gender, 'handedness': row.handedness, 'language': row.language, 'isS2Student': fieldActive === '' ? row.isS2Student : 'false', 'class': gradeName, 'nativeLanguage': 'finnish', 'email': teacherContact }

            try { 
                const response = await axiosPrivate.post(ADD_GRADE_URL, JSON.stringify({'patientReadOnlyData': JSON.stringify(patientData) })
                );

                studs++;
                if(studs >= rows.length) { 
                    setTimeout(() => {
                        setAjaxLoad('');
                        setErrMsg('');
                        if(navToGame === true) {
                            navigate(from, { replace: true });
                        }
                        props.goBack();
                        props.setInitState(true);

                        if(props.currentStep === 5 ) {
                            props.setTutorialMode(false);
                            props.setCurrentStep(0);
                        } 

                    }, 500);
                }

    
            } catch(err) {
    
                if(!err?.response) {
                    setErrMsg('No server response');
                } else if(err.response?.status === 400) {
                    setErrMsg(err.response?.data);
                } else if (err.response?.status === 401) {
                    setErrMsg('401 Unauthorized');
                } else {
                    setErrMsg('Request failed');
                }
    
                errRef.current.focus();
                setAjaxLoad('');
            }
        }

    }  

    /*const [ currentStep, setCurrentStep ] = useState(props.currentState);

    const getHighlightClass = (targetStep) => {
        return props.currentStep === targetStep ? 'tutorial-active' : '';
    }; */

    const [fieldHidden, setFieldHidden ] = useState('');

    const handleAgeInput = (event) => {
        // Check if the event's target is the first age input field
        if (event.target.name === 'age' && event.target.dataset.index === '0') {
          const age = event.target.value;
          if (age.length >= 2 && age >= 14) {
            setFieldHidden('hidden');
            setFilteredLanguageObject({
                'Suomi': languageObject['Suomi'],
                'Englanti': languageObject['Englanti']
            });
          } else {
            setFieldHidden('');
            setFilteredLanguageObject(languageObject);
          }
        }
      };
      
    

    return (
        <section className={"peili-form-container add-class " + ajaxLoad }>

            <div className="button-wrapper" data-highlight={props.currentStep === 2 ? 'tutorial-active' : ''}>
                <label htmlFor="class-name">{t('Luokka')} <span className='info'>({t('Esim. 3A')})</span></label> 

                <input className='class-name-input peili-add-input-field' ref={classNameRef} value={gradeName} type="text" name="class-name"
                placeHolder={t('Esim.') + ' 3A'}
                onChange={(e) => { setGradeName(e.target.value); } }>
                </input>

                <label htmlFor="teacher-contact">{t('Opettajan sähköposti')} <span className='info'>({t('Tulosraporttien toimittamista varten')})</span></label> 

                <input className='teacher-contact-input peili-add-input-field' value={teacherContact} type="text" name="teacher-contact"
                placeHolder={'email'}
                onChange={(e) => { setTeacherContact(e.target.value); } }>
                    
                </input>
                <span data-highlight={props.currentStep === 2 ? 'tutorial-active' : ''} className="tutorial-tooltip tutorial-class-name">
                    {t('Lisää luokan nimi')}.
                </span>
            </div>
            <div className='table-wrapper'>
                <span data-highlight={props.currentStep === 3 ? 'tutorial-active' : ''} className="tutorial-tooltip tutorial-add-student">
                    {t('Lisää oppilas luokkaan nimeämällä oppilas, antamalla hänen ikänsä sekä valitsemalla oikeat lisätiedot (sukupuoli, kätisyys, kieli ja puhuuko oppilas suomea äidinkielenään)')}.
                </span>
                <table data-highlight={props.currentStep === 3 || props.currentStep === 4 ? 'tutorial-active' : ''} id="addGradeTable">
                    <thead> 
                    <tr>
                        <th></th>
                        <th className='name-th'>{t('Nimi')} <span className='info'>({t('Etunimi ja sukunimen 2 ensimmäistä kirjainta, esim. Liisa Po')})</span></th>
                        <th>{t('Ikä')}</th>
                        <th className={fieldHidden}>{t('Sukupuoli')}</th> 
                        <th className={fieldHidden}>{t('Kätisyys')}</th>
                        <th>{t('Kieli')} <span className='info'>({t('Kieli, jolla oppilas pelaa pelin')})</span></th>
                        <th className={fieldActive}>{t('S2-oppilas')}</th>
                    </tr> 
                    </thead>
                    <tbody>
                    {rows.map((row, index) => (
                        <tr key={"add-" + index}>
                        <td>{index + 1}.</td>
                        <td>
                            <input
                            type="text"
                            name="realName"
                            className='peili-add-input-field'
                            value={row.realName}
                            placeHolder={t('Esim.') + ' Liisa Po'}
                            onChange={(e) => handleInputChange(e, index)}
                            />
                        </td>
                        <td>
                            <input
                            type="number"
                            name="age"
                            className='peili-add-input-field'
                            value={row.age}
                            placeHolder={t("Ikä")}
                            data-index={index}
                            onChange={(e) => {
                                handleInputChange(e, index);
                                handleAgeInput(e);
                            }}
                            />
                        </td>
                        <td className={fieldHidden}>
                            <select
                            className={'peili-select-field peili-add-input-field ' + fieldHidden}
                            name="gender"
                            value={row.gender}
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="male">{t('Mies')}</option>
                            <option value="female">{t('Nainen')}</option>
                            <option value="other">{t('Muu')}</option>
                            </select>
                        </td>
                        <td className={fieldHidden}>
                            <select
                            className={'peili-select-field peili-add-input-field' + fieldHidden}
                            name="handedness"
                            value={row.handedness}
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="right">{t('Oikea')}</option>
                            <option value="left">{t('Vasen')}</option>
                            <option value="unknown">{t('En tiedä')}</option>
                            </select>
                        </td>
                        <td>
                            <select
                            className='peili-select-field peili-add-input-field'
                            name="language"
                            value={row.language}
                            id="student-lan-selector"
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            {
                                Object.keys(filteredLanguageObject).map((key, i)=>{
                                return (
                                    <option key={"add-lan-" + i} value={languageObject[key]}>{t(key)}</option>
                                    )
                                })
                            }
                            </select>
                        </td>
                        <td>
                            <select
                            className={'peili-select-field peili-add-input-field ' + fieldActive}
                            name="isS2Student"
                            value={fieldActive === '' ? row.isS2Student : 'false' }
                            onChange={(e) => handleInputChange(e, index)}
                            >
                            <option value="">-- {t('Valitse')} --</option>
                            <option value="true">{t('Kyllä')}</option>
                            <option value="false">{t('Ei')}</option>
                            </select>
                        </td>
                        <td>
                            <button className='button remove-row' onClick={() => removeRow(index)}>{t('Poista rivi')}</button> 
                        </td>
                        </tr> 
                    ))} 
                    </tbody>
                </table>
                <button data-highlight={props.currentStep === 4 ? 'tutorial-active' : ''} className='button add-row' onClick={addRow}>{t('LISÄÄ RIVI')} +</button>
                <span data-highlight={props.currentStep === 4 ? 'tutorial-active' : ''} className="tutorial-tooltip tutorial-add-row">
                    {t('Voit lisätä uuden oppilaan painamalla Lisää Rivi -painiketta')}.
                </span>
                <div className='buttons-wrapper' data-highlight={props.currentStep === 5 ? 'tutorial-active' : ''}>
                    <button className='button green save-class' onClick={handleSubmit}>{t('TALLENNA')}</button>
                    <button className='button secondary purple save-class' onClick={() => handleSubmit(true)}>{t('SIIRRY PELIIN')}</button>
                </div>
                <span data-highlight={props.currentStep === 5 ? 'tutorial-active' : ''} className="tutorial-tooltip tutorial-save-class">
                    {t('Kun olet valmis, tallenna luokan muutokset')}.
                </span> 
                </div>
 
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
}

export default AddGradeForm