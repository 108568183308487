import React, { useState, useEffect } from "react";

import useTable from "../../hooks/useTable";
import TableFooter from "./TableFooter";

const UsersTable = ({ data, openEditUserForm, setInitState }) => { 
    //console.log(data);
    const [ page, setPage] = useState(1);
    const [ rowsPerPage, setRowsPerPage ] = useState(10); 
    const { slice, range } = useTable(data, page, rowsPerPage);
    const [ sortOrder, setSortOrder] = useState('');
    const [ sortingData, setSortingData ] = useState('createdDate');
 
    useEffect(() => {
        setInitState(true);
    }, [rowsPerPage]);


    const sortedData = data.sort((a, b) => {

        if (a[sortingData] == null ) {
            a[sortingData] = '';
        }

        if (b[sortingData] == null ) {
            b[sortingData] = '';
        }


        if (sortOrder === "asc") {
            return a[sortingData].localeCompare(b[sortingData]);
        } else {
            return b[sortingData].localeCompare(a[sortingData]);
        }
    });
  
    const indexOfLastItem = page * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);
  
    const paginate = (pageNumber) => setPage(pageNumber);
  
    const handleSort = (e, dataset) => {

        let sortOrderVar = sortOrder;
        
        if( !e.target.classList.contains('active')){
            sortOrderVar = '';
            setSortOrder('');
            
        }

        let tableHeaders = document.querySelectorAll('.table-header');

        for(let i = 0; i < tableHeaders.length; i++){
            tableHeaders[i].classList.remove('active');
        }
        
        e.target.classList.add('active');

        if(dataset !== null) {
            setSortingData(dataset);
        }
        if (sortOrderVar === "") {
            setSortOrder("desc"); 
        } else if(sortOrderVar === "desc") {
            setSortOrder("asc"); 
        } else {
            setSortOrder('');
            setSortingData('createdDate'); // <------- TESTI 
        }
    };

    Date.prototype.addHours = function(h) {
        this.setTime(this.getTime() + (h*60*60*1000));
        return this;
    }
      

    return (
    <>
        <table className="peili-table peili-users-table">
            <thead>
                <tr>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'username') }}>Username <i className={`sort-icon ${sortOrder}`}></i> </th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'firstName') }}>Name <i className={`sort-icon ${sortOrder}`}></i></th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'email') }}>Email <i className={`sort-icon ${sortOrder}`}></i></th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'organisation') }}>Organisation <i className={`sort-icon ${sortOrder}`}></i></th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'createdDate') }}>Created <i className={`sort-icon ${sortOrder}`}></i></th>
                    <th className='table-header' onClick={(e) => { handleSort(e, 'modifiedDate') }}>Last modified <i className={`sort-icon ${sortOrder}`}></i></th>
                </tr>  
            </thead> 
            <tbody> 
            {currentItems.map((el, i) =>  
                <tr key={"user-" + i} onClick={() => { openEditUserForm(el) } }>
                    <td>{el?.username}</td>
                    <td>{el?.firstName + ' ' + el?.lastName}</td>
                    <td>{el?.email}</td>
                    <td>{el?.organisation}</td>

                    <td>{
                        new Date(el?.createdDate).addHours(3).toLocaleString()
                    }</td>
                    <td>{
                        
                        new Date(el?.modifiedDate).addHours(3).toLocaleString()
                    }</td>
                </tr>
            )}
            </tbody>
        </table>
        <TableFooter range={range} slice={slice} setPage={setPage} page={page} setRowsPerPage={setRowsPerPage} rowsPerPage={rowsPerPage}/>
    </>
    );
};

export default UsersTable;