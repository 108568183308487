
import { useState } from 'react';
import useAuth from './hooks/useAuth';
import axios from './api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import logoutIcon from './icons/logout.svg';

import { useTranslation } from 'react-i18next';

const LOGOUT_URL = '/Auth/Logout';

const Logout = (props) => {
    
    const { t } = useTranslation();

    const { auth } = useAuth();
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/login";

    const [errMsg, setErrMsg] = useState('');

    //console.log('token: ' + auth?.accessToken);
    //console.log('refToken: ' + auth?.refreshToken);

    const LogoutFunction = async () => {
 
        try { 
            const response = await axios.post(LOGOUT_URL, 
                JSON.stringify({'token': auth?.accessToken, 'refreshToken': auth?.refreshToken}), 
                {
                    headers: { 
                        'Content-Type': 'application/json',
                        'accept': 'application/json'
                     },
                }
            );
            localStorage.removeItem('auth');
            setAuth( {} );
            //setTimeout(function(){
                navigate(from, { replace: true });
            //}, 500);
            props.setMinifiedNav('');
            return response;
        } catch(err) {

            if(!err?.response) {
                setErrMsg('No server response');
            } else if(err.response?.status === 400) {
                setErrMsg('400');
            } else if (err.response?.status === 401) {
                setErrMsg('401 Unauthorized');
            } else {
                setErrMsg('Logout failed');
            }

            console.log(errMsg);

        }

    }


    return (
        <a /*href="/login"*/ onClick={() => { LogoutFunction() }}><img style={{left: '35px', width:"36px", height:"36px"}} src={logoutIcon} ></img>{t('KIRJAUDU ULOS')}</a>
    )
} 

export default Logout