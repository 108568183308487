import axios from '../api/axios';
import useAuth from "./useAuth";
import { useNavigate, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

const useRefreshToken = () => {
    const { auth } = useAuth();

    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/home";
    
    const refresh = async (retryCount = 0) => {
        try {
            const response = await axios.post('/Auth/RefreshToken',
            JSON.stringify({'token': auth?.accessToken, 'refreshToken': auth?.refreshToken}), 
            {
                headers: { 
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                 }
            });


            localStorage.setItem('auth', JSON.stringify({ id: auth.id, user: auth.user, pwd: auth.pwd, roles: auth.roles, language: auth.language, organisation: auth.organisation, organisationId: auth.organisationId, accessToken: response.data.token, refreshToken: response.data.refreshToken }));

            setAuth(prev => {
                //console.log(JSON.stringify(prev)); 
                //console.log(response.data.token);
                return { ...prev, accessToken: response.data.token, refreshToken: response.data.refreshToken }
            });
            //<Navigate to={location.pathname} />;
            console.log('REFRESHED');
       
            return response.data.token;
        } catch(err) {
            /*localStorage.removeItem('auth');
            setAuth( {} );
            navigate(from, { replace: true });*/

            /*if (retryCount >= 5) {
                console.log('Failed to refresh token after 5 attempts.');
                return null;
            }
        
            await new Promise(resolve => setTimeout(resolve, 5000));
            return refresh(retryCount + 1);*/
            //<Navigate to={location.pathname} />;

            //console.log('failed to refresh');
            //location.reload();
            //console.log(err.response);

            if (retryCount >= 5) {
                console.log('Failed to refresh token after 5 attempts.');
                //location.reload();
                return null;
              }
          
              await new Promise(resolve => setTimeout(resolve, 5000));
              return refresh(retryCount + 1);
              
        }
  
    }




    return refresh; 
   
    /*const refresh = () => {
        console.log(auth?.accessToken);
    }

    return refresh; */
    
}

export default useRefreshToken;