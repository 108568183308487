
import { useState, useEffect, useRef } from 'react';
import AddOrganisationForm from '../components/organisations/AddOrganisationForm';
import EditOrganisationForm from '../components/organisations/EditOrganisationForm';
import OrganisationsTable from '../components/tables/OrganisationsTable';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ZipUploader from '../components/organisations/ZipUploader';

const Organisations = () => {

    const [ organisations, setOrganisations ] = useState();
    const axiosPrivate = useAxiosPrivate();
    const [ popup, setPopup ] = useState('');
    const [ currentOrganisation, setCurrentOrganisation ] = useState('');
    const [ filteredData, setFilteredData ] = useState(organisations);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [updateOrganisations, setUpdateOrganisations] = useState(false);
    const [newOrganisationName, setNewOrganisationName] = useState('');

    const viewFirst = useRef('active');
    const viewSecondary = useRef('');

    const [init, setInit] = useState(false);

    useEffect(() => {
        getOrganisations();
        setPopup('');
        setInit(false);
        console.log('init');
    }, [init]); 


    useEffect(() => {
        setUpdateOrganisations(false);
    }, [updateOrganisations]); 

    const goBack = () => {
        setInit(true);
        setCurrentOrganisation([]);
        viewFirst.current = 'active';
        viewSecondary.current = '';
        //viewThird.current = '';
    }

    useEffect(() =>{
        //let isMounted = true;
        //const controller = new AbortController();

        getOrganisations();

        /*return () => {
            isMounted = false;
            controller.abort();
        }*/
    }, []);

    const openAddOrganisationForm = () => {
        setPopup('add-organisation-form'); 
        
    }
    const openEditOrganisationForm = (organisation) => {
        window.scrollTo(0, 0);
        if(viewFirst.current === 'active'){
            setCurrentOrganisation(organisation);
            viewFirst.current = '';
            viewSecondary.current = 'active';
        } else {
            setCurrentOrganisation(' ');
            viewFirst.current = 'active';
            viewSecondary.current = '';
        }
        //setPopup('edit-organisation-form');
    }

    const handleSearch = (e) => { 
        let value = e.target.value.toLowerCase();
        let result = [];
        result = organisations.filter((data) => {
            if( data.name.toLowerCase().search(value) !== -1 ) {
                return data.name.toLowerCase().search(value) !== -1;
            } else {
                return data.name.toLowerCase().search(value) !== -1;
            }
            //return data.username.toLowerCase().search(value) != -1;
        });
        setFilteredData(result);
    }

    const getOrganisations = async () => {
        try {
            const response = await axiosPrivate.get('/Organisations', {
                //signal: controller.signal
            });
            //console.log(response.data);

            //isMounted && 
            setOrganisations(response.data);
            setFilteredData(response.data);

        } catch(err){
            //console.error(err);
        }
    }

    function isDateBetween(startDate, endDate, checkDate) {
        const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
        const check = new Date(checkDate.getFullYear(), checkDate.getMonth(), checkDate.getDate());
      
        return (check.getTime() >= start.getTime()) && (check.getTime() <= end.getTime());
    }
      

    const handleDateRangeSelect = (update) => {

        if (update[1] !== null) {
            let startDate = update[0];
            let endDate = update[1];
            let result = [];
            result = organisations.filter((data) => {
                let check = isDateBetween(startDate, endDate, new Date(data.createdDate) );      
                if(check) {
                    return data.createdDate.search(data.createdDate) !== -1;
                }
            });
            setFilteredData(result);
        } else {
            setFilteredData(organisations);
        }

    }


    return (
        <>
            <div className="peili-views-wrapper">
                <section useref={viewFirst} className={'peili-content-wrapper view-1 ' + viewFirst.current}>
                    {
                        ( popup === 'add-organisation-form' ) ?
                        <AddOrganisationForm setNewOrganisationName={setNewOrganisationName} updateOrganisations={setUpdateOrganisations} setPopupState={setPopup} setInitState={setInit} organisations={organisations}></AddOrganisationForm>
                        :
                        <></>
                    }
                    
                    <h1>ORGANISATIONS</h1>
                    <div className='tools-wrapper'>
                        <input type="search" placeholder='Search...' onChange={(e) =>handleSearch(e)}></input>
                        <button onClick={() => openAddOrganisationForm() }>Add organisation</button>
                        <div className='peili-date-wrapper'>
                            <DatePicker
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                    setDateRange(update);
                                    handleDateRangeSelect(update);
                                }}
                                isClearable={true}
                                placeholderText='Filter by dates'
                            />
                         </div>
                    </div>   
                    {organisations?.length
                        ? (
                            <OrganisationsTable data={filteredData} rowsPerPage={4} openEditOrganisationForm={openEditOrganisationForm} setInitState={setInit}/>
                        ) : <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    }
                </section>

                <section ref={viewSecondary} className={'peili-content-wrapper view-2 ' + viewSecondary.current}>
                    <div className="top-bar">
                        <h1>EDIT ORGANISATION</h1> 
                        <h2>{currentOrganisation.name ? currentOrganisation.name : '-'}</h2> 
                        <button onClick={openEditOrganisationForm}>Back</button>
                    </div>

                    <div className="content">  
                        <ZipUploader goBack={goBack} organisations={organisations} openEditOrganisationForm={openEditOrganisationForm} currentOrganisation={currentOrganisation} setPopupState={setPopup} setInitState={setInit} ></ZipUploader>
                    </div>
                    
                </section>
            </div>
        </> 
    )
}

export default Organisations;