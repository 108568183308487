
import { useRef, useState, useEffect, useContext } from 'react';
//import { axiosPrivate } from '../../api/axios';
import { useTranslation } from 'react-i18next';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import SignalRComponent, { SignalRLib } from '../../api/signalRComponent';
import { finishTestSessionEvent } from '../../api/signalRClasses';
import successIcon from '../../icons/icon-success.png';

const AeduTestFinished = (props) => {

    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();

    const { t, i18n } = useTranslation();
    const [ currentLanguage, setCurrentLanguage ] = useState(i18n.language);

    const errRef = useRef();

    const [errMsg, setErrMsg] = useState('');

    const [ ajaxLoad, setAjaxLoad ] = useState('');

    const goBack = () => {
        //let finishTestSession = finishTestSessionEvent();
        //SignalRLib.InvokeJs('SendCommand', props.generatedId, JSON.stringify(finishTestSession));
        //SignalRLib.InvokeJs("LeaveTherapy", props.generatedId);
        SignalRLib.StopJs();

        const items = document.querySelectorAll('.view-3 .item');
        items.forEach(item => {
            item.removeAttribute('data-client-id');
            item.removeAttribute('data-status');
            if(item.querySelector('.statusmessage')){
                item.querySelector('.statusmessage').innerHtml = '';
            }
        });
        const gameItems = document.querySelectorAll('.view-4 .item');
        gameItems.forEach(gameItem => {
            gameItem.removeAttribute('data-client-id');
            gameItem.removeAttribute('data-status');
            if(gameItem.querySelector('.statusmessage')){
                gameItem.querySelector('.statusmessage').innerHtml = '';
            }
        });
        props.goBack('no-prompt');

    }


    return (
        <section className={"aedu-thank-you-container " + ajaxLoad}> 
            <p className='heading'>{t('Kiitos')}</p>
            <p className='subheading'>{auth.organisation}</p>
            <div className="success-icon">
                <img src={successIcon} alt="success-icon" />
            </div>
            <p className="text-content">{t('Kaikki oppilaat ovat suorittaneet pelin!')}</p>
            <p className="subtext-content">{t('Voit poistua tästä näkymästä painamalla alla olevaa painiketta tai sulkemalla selaimesi.')}</p>
            <button onClick={() => goBack()} className={`peili-btn finish-test active`}>{t('VALMIS')}</button>

            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
            
        </section>
    )
}

export default AeduTestFinished